<template>
  <span v-if="menu.length > 0">
    <v-menu offset-y left min-width="150">
      <template #activator="{ on, attrs }">
        <app-button
          type="icon"
          v-bind="attrs"
          v-on="on"
        >
          <app-icon icon-name="ellipsis-vertical" icon-weight="far" />
        </app-button>
      </template>
      <v-list>
        <template v-for="(item, i) in menu">
          <v-list-item
            v-if="item.text !== 'divider'"
            :key="`itemMenu-${i}`"
            @click.self="handleMenuAction(item.action)"
          >
            {{ item.text }}
          </v-list-item>
          <v-divider v-if="item.text === 'divider'" :key="`divider-${i}`" />
        </template>
        <PolyOfficeListItemExport
          v-if="polyOfficeEnabled"
          :fileSize="file.size"
          @polyOfficeOpenDialog="polyOfficeOpenDialogHandler(file)"
        />
        <ArchiveAddAccessListItem :routeOptions="archiveAddRouteOptions" />
      </v-list>
    </v-menu>
    <DocumentsVersionHistoryDialog :mnemo="mnemo"
                                   :is-open="versionHistoryDialogIsOpen"
                                   :document="file"
                                   no-share
                                   no-delete
                                   @onCancel="versionHistoryDialogIsOpen = false"
                                   @addVersionClicked="onAddVersionClicked"
    />
    <DocumentsNumberingDialog v-if="numberingDialogIsOpen"
                              :document="file"
                              @close="numberingDialogIsOpen = false"
                              @onSuccess="updateDocumentsNumbering"
    />
  </span>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'

import ArchiveAddAccessListItem from '@/common/archives/ArchiveAddAccessListItem.vue'
import { DocumentsExport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeListItemExport from '@/common/polyOffice/PolyOfficeListItemExport'
import { isFileSignable } from '@/common/utils/files'
import DocumentsNumberingDialog from '@/project/documents/dialogs/DocumentsNumberingDialog.vue'
import DocumentsVersionHistoryDialog from '@/project/documents/dialogs/DocumentsVersionHistoryDialog'
import { SIGNING_CHECKLIST_ADD_ROUTE_NAME } from '@/router'
import {
  SET_DELETE_ATTACHED_FILES_DIALOG,
  SET_TABLE_TASK_PANE,
  SET_RENAME_ATTACHED_FILE_DIALOG,
} from '@/store/modules/checklist/mutation_types'
import { INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS } from '@/store/modules/document-share-picker/action_types'
import { GET_EXPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'ChecklistTableTaskAttachedFilesItemMenu',
  components: {
    DocumentsNumberingDialog,
    DocumentsVersionHistoryDialog,
    ArchiveAddAccessListItem,
    PolyOfficeListItemExport,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      versionHistoryDialogIsOpen: false,
      numberingDialogIsOpen: false,
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm', 'maxSignableSize']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    menu () {
      const menu = []
      if (this.isCurrentUserPm) {
        menu.push(
          {
            text: this.$t('common.share'),
            action: 'share',
          },
        )
      }
      if (this.file.nbVersions > 1) {
        menu.push({
          text: this.$t('project.checklist.task.ChecklistTableTaskAttachedFilesItemMenu.showVersions', { nbVersions: this.file.nbVersions }),
          action: 'showVersions',
        })
      }
      if (this.isCurrentUserPm) {
        menu.push(
          {
            text: this.$t('project.checklist.task.ChecklistTableTaskAttachedFilesItemMenu.versions'),
            action: 'versions',
          },
        )
        if (isFileSignable(this.file, this.maxSignableSize)) {
          menu.push(
            {
              text: this.$t('project.checklist.task.ChecklistTableTaskAttachedFilesItemMenu.signingChecklistAdd'),
              action: 'signingChecklistAdd',
            },
          )
        }
        if (this.file.numbering) {
          menu.push({
            text: this.$t('project.checklist.task.ChecklistTableTaskAttachedFilesItemMenu.numbering'),
            action: 'numbering',
          })
        }
      }
      if (this.file.canWrite) {
        menu.push(
          {
            text: 'divider',
          },
          {
            text: this.$t('project.checklist.task.ChecklistTableTaskAttachedFilesItemMenu.move'),
            action: 'move',
          },
          {
            text: this.$t('project.checklist.task.ChecklistTableTaskAttachedFilesItemMenu.duplicate'),
            action: 'duplicate',
          },
          {
            text: this.$t('project.checklist.task.ChecklistTableTaskAttachedFilesItemMenu.rename'),
            action: 'rename',
          },
          {
            text: this.$t('project.checklist.task.ChecklistTableTaskAttachedFilesItemMenu.delete'),
            action: 'delete',
          },
        )
      }
      return menu
    },
    archiveAddRouteOptions () {
      return {
        params: {
          files: [this.file],
        },
        query: {
          fileIds: this.file.id,
          fromMnemo: this.mnemo,
        },
      }
    },
  },
  methods: {
    ...mapActions('documentSharePicker', [INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS]),
    ...mapActions('polyOffice', [GET_EXPORT_IFRAME]),
    handleMenuAction (action) {
      switch (action) {
        case 'share':
          this.prepareSharePane()
          break
        case 'move':
          this.prepareMovePane()
          break
        case 'duplicate':
          this.prepareDuplicatePane()
          break
        case 'versions':
          this.prepareVersionsPane()
          break
        case 'showVersions':
          this.versionHistoryDialogIsOpen = true
          break
        case 'signingChecklistAdd':
          this.$router.push({
            name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
            params: {
              files: [this.file],
            },
            query: {
              fileIds: this.file.id,
            },
          })
          break
        case 'delete':
          this.openDeleteAttachedFileDialog()
          break
        case 'rename':
          this.openRenameAttachedFileDialog()
          break
        case 'numbering':
          this.numberingDialogIsOpen = true
          break
      }
    },
    prepareSharePane () {
      this.INIT_SELECTED_GROUPS(cloneDeep(this.file.rights.read.groups))
      this.INIT_SELECTED_MEMBERS(cloneDeep(this.file.rights.read.users))
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: true,
        tab: 'share',
        documents: [this.file],
        parent: this.file.parentFolderId,
      })
    },
    prepareMovePane () {
      this.INIT_SELECTED_GROUPS(cloneDeep(this.file.rights.read.groups))
      this.INIT_SELECTED_MEMBERS(cloneDeep(this.file.rights.read.users))
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: true,
        tab: 'move',
        documents: [this.file],
        parent: this.file.parentFolderId,
      })
    },
    prepareDuplicatePane () {
      this.INIT_SELECTED_GROUPS(cloneDeep(this.file.rights.read.groups))
      this.INIT_SELECTED_MEMBERS(cloneDeep(this.file.rights.read.users))
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: true,
        tab: 'duplicate',
        documents: [this.file],
        parent: this.file.parentFolderId,
      })
    },
    prepareVersionsPane () {
      this.INIT_SELECTED_GROUPS(cloneDeep(this.file.rights.read.groups))
      this.INIT_SELECTED_MEMBERS(cloneDeep(this.file.rights.read.users))
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: true,
        tab: 'versions',
        documents: [this.file],
        parent: this.file.parentFolderId,
      })
    },
    openDeleteAttachedFileDialog () {
      this.$store.commit(`checklist/${SET_DELETE_ATTACHED_FILES_DIALOG}`, {
        isOpen: true,
        filesToDelete: [this.file],
      })
    },
    openRenameAttachedFileDialog () {
      this.$store.commit(`checklist/${SET_RENAME_ATTACHED_FILE_DIALOG}`, {
        isOpen: true,
        fileToRename: this.file,
      })
    },
    onAddVersionClicked () {
      this.prepareVersionsPane()
    },
    updateDocumentsNumbering (file) {
      this.file.numbering = file.numbering
    },
    async polyOfficeOpenDialogHandler (file) {
      const payload = new DocumentsExport(this.mnemo, [file.id])
      try {
        await this.GET_EXPORT_IFRAME({
          payload,
          successMessage: this.$t('project.checklist.task.ChecklistTableTaskAttachedFilesItemMenu.polyOffice.exportSuccessful'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1),
        })
      }
    },
  },
}
</script>
