<template>
  <div>
    <AppTooltip v-if="officeDocumentType" top>
      <template #activator="{ attrs, on }">
        <app-button
          ref="documentEditButton"
          type="icon"
          :disabled="isEditionDisabled.value"
          v-bind="attrs"
          @click="onClickOfficeButton"
          v-on="on"
        >
          <OfficeIcon
            :document-type="officeDocumentType"
            icon-type="product"
            :disabled="isEditionDisabled.value"
          />
        </app-button>
      </template>
      <span>
        {{ isEditionDisabled.value ? isEditionDisabled.reason : tooltip }}
      </span>
    </AppTooltip>
    <OfficeConversionDialog v-if="isOpenConversionDialog"
                            :document="document"
                            @close="closeConversionDialog"
                            @validate-conversion-dialog="validateConversionDialog"
                            />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { type TranslateResult } from 'vue-i18n'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip.vue'
import { track } from '@/common/pendo/agent'
import { getFileExtension, isFileLowerThanMaxSignableSize } from '@/common/utils/files'
import {
  getOfficeDocumentType,
  OFFICE_EDITION_MAX_FILE_SIZE,
  type SupportedOfficeDocumentTypes,
} from '@/common/utils/office'
import { getUserSetting, setUserSetting, removeUserSetting } from '@/common/utils/userSettings'
import AppButton from '@/design-system/buttons/AppButton.vue'
import { ROOM_CHECKLIST_ROUTE_NAME, WOPI_PAGE_ROUTE_NAME } from '@/router'
import { GET_GLOBAL_TASK_FOLDER, GET_TASK_FOLDER } from '@/store/modules/checklist/action_types'
import { GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'

import OfficeConversionDialog from './OfficeConversionDialog.vue'
import OfficeIcon from './OfficeIcon.vue'

const OFFICE_CONVERSION_AUTH_USER_SETTINGS_KEY = 'office-conversion-auth'

export default defineComponent({
  name: 'OfficeOnlineEditionButton',
  components: { AppTooltip, OfficeIcon, OfficeConversionDialog },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isOpenConversionDialog: false,
    }
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    ...mapGetters('user', ['currentUserId']),
    ...mapState('checklist', ['globalAttachedFilesDialogIsOpen']),

    isDocumentSizeUnderOfficeEditionLimit (): boolean {
      if (!this.officeDocumentType) {
        return true
      }

      const editionMaxFileSize = OFFICE_EDITION_MAX_FILE_SIZE[this.officeDocumentType]
      return isFileLowerThanMaxSignableSize(this.document, editionMaxFileSize)
    },
    isEditionDisabled (): { value: true, reason: TranslateResult } | { value: false } {
      if (!this.document.isUserFromSub) {
        return {
          value: true,
          reason: this.$t('project.documents.table.DocumentsTableItemOptions.cantEditGuestTooltip'),
        }
      }

      if (this.officeDocumentType && !this.isDocumentSizeUnderOfficeEditionLimit) {
        return {
          value: true,
          reason: this.$t(
            'project.documents.table.DocumentsTableItemOptions.cantEditBigFileTooltip',
            { size: OFFICE_EDITION_MAX_FILE_SIZE[this.officeDocumentType] },
          ),
        }
      }

      return { value: false }
    },
    officeDocumentType (): SupportedOfficeDocumentTypes | null {
      const fileExtension = getFileExtension(this.document)

      if (!fileExtension) {
        return null
      }

      return getOfficeDocumentType(fileExtension)
    },
    tooltip (): TranslateResult | null {
      let applicationName = ''

      switch (this.officeDocumentType) {
        case 'word':
          applicationName = 'Word'
          break
        case 'excel':
          applicationName = 'Excel'
          break
        case 'powerpoint':
          applicationName = 'Powerpoint'
          break
        default:
          return null
      }

      return this.$t(
        'common.office.OfficeOnlineEditionButton.editInOnlineApp',
        { application: applicationName },
      )
    },
    isFileOutdated (): boolean {
      return !getFileExtension(this.document)?.endsWith('x')
    },
  },
  methods: {
    ...mapActions('documents', [GET_DOCUMENTS_BY_ID]),
    ...mapActions('checklist', [GET_TASK_FOLDER, GET_GLOBAL_TASK_FOLDER]),
    userOfficeConversionAuth () {
      return getUserSetting(this.currentUserId, OFFICE_CONVERSION_AUTH_USER_SETTINGS_KEY)
    },
    closeConversionDialog () {
      this.isOpenConversionDialog = false
      removeUserSetting(this.currentUserId, OFFICE_CONVERSION_AUTH_USER_SETTINGS_KEY)
    },
    validateConversionDialog (conversionAuthValueFromDialog : boolean) {
      this.isOpenConversionDialog = false
      if (conversionAuthValueFromDialog) {
        setUserSetting(this.currentUserId, OFFICE_CONVERSION_AUTH_USER_SETTINGS_KEY, conversionAuthValueFromDialog)
      }
      this.openInOfficeOnline()
    },
    onClickOfficeButton () {
      this.removeTooltipAfterClickOfficeButton()
      if (this.isFileOutdated && !this.userOfficeConversionAuth()) {
        this.isOpenConversionDialog = true
      } else {
      this.openInOfficeOnline()
      }
    },
    openInOfficeOnline () {
      this.isOpenConversionDialog = false
      track('open_M365_document', { type: this.officeDocumentType })
      const mode = this.isFileOutdated ? 'convert' : 'edit'
      const routeData = this.$router.resolve({
        name: WOPI_PAGE_ROUTE_NAME,
        params: {
          mnemo: this.roomMnemo,
          fileId: this.document.id,
          mode,
        },
      })
      window.open(routeData.href, '_blank')
      if (mode === 'convert') {
      /**
       * We allow 3 seconds for the creation of the new converted file and refresh,
       * because we don't have an API response here to know if the file is already created,
       * so we first wait for 3 seconds.
       */
        setTimeout(() => {
          this.refreshAfterConversion()
        }, 3000)
      }
    },
    refreshAfterConversion () {
      if (this.$route.name === ROOM_CHECKLIST_ROUTE_NAME) {
        if (this.globalAttachedFilesDialogIsOpen) {
          this.GET_GLOBAL_TASK_FOLDER(this.roomMnemo)
          } else {
            this.GET_TASK_FOLDER({
            mnemo: this.roomMnemo,
            folderId: this.document.parentFolderId || 0,
          })
        }
      } else {
        this.GET_DOCUMENTS_BY_ID({
            mnemo: this.roomMnemo,
            id: this.document.parentFolderId || 0,
            queryObject: { markRecent: true },
          })
      }
    },
    removeTooltipAfterClickOfficeButton () {
      /**
       * Remove element's focus in order to remove tooltip
       */
       const refDocumentEditButton = this.$refs.documentEditButton as InstanceType<typeof AppButton>
      if (refDocumentEditButton?.$el) {
        (refDocumentEditButton.$el as HTMLElement).blur()
      }
    },
  },
})
</script>
