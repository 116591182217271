<template>
  <div class="home h-100">
    <v-container fluid
                 class="h-100"
                 :class="isViewWithoutYPadding ? 'px-1 py-0' : 'px-3 py-6'"
    >
      <template v-if="processing">
        <div class="d-flex align-center justify-center h-100\">
          <v-icon style="font-size: 100px;" color="primary" class="fa-spin">fas fa-spinner-third</v-icon>
        </div>
      </template>
      <template v-else-if="!disabled && !error">
        <transition name="app-fade" tag="div">
          <router-view></router-view>
        </transition>
      </template>
      <template v-else-if="disabled">
        <v-row class="h-100" align-content="center" justify="center">
          <AppCard>
            <h2>
              {{$t('project.views.Project.subscriptionDisabledReasons.' + subDisabledReason + '.title')}}
            </h2>
            <p v-html="$t('project.views.Project.subscriptionDisabledReasons.' + subDisabledReason + '.text')"></p>
            <div class="text-center">
              <AppButton text to="/">
                {{$t('project.views.Project.backToHome')}}
              </AppButton>
            </div>
          </AppCard>
        </v-row>
      </template>
      <template v-else-if="error">
        <v-row class="flex-column h-100 text-center" align-content="center" justify="center" >
          <p>{{$t('project.views.Project.errorIntro')}}</p>
          <p v-html="$t('project.views.Project.errorFollowUp')"></p>
          <AppButton color="primary" @click="initRoom"> {{ $t('project.views.Project.errorBtnText') }} </AppButton>
        </v-row>
      </template>
      <IManageAuthDialog v-if="iManageAuthDialogIsOpened" @close="iManageAuthDialogIsOpened = false"/>
      <WatermarkedViewerDialog v-if="watermarkedDialogData.isOpen"
                               :mnemo="mnemo"
                               @close="closeWatermarkedDialog"
      />
    </v-container>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppCard from '@/common/AppCard.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import IManageAuthDialog, { IMANAGE_REFUSAL_USER_SETTINGS_KEY } from '@/common/imanage/IManageAuthDialog'
import { getRoomRouteType } from '@/common/utils/rooms'
import { getUserSetting } from '@/common/utils/userSettings'
import WatermarkedViewerDialog from '@/project/documents/watermarked-viewer/WatermarkedViewerDialog.vue'
import {
  ENVELOPE_TEMPLATE_ADD_ROUTE_NAME,
  NOT_FOUND_ROUTE_NAME,
  ROOM_DOCUMENTS_ROUTE_NAME,
  ROOM_MEMBERS_ROUTE_NAME,
  ROOM_ROUTE_NAME,
  ROOM_ROUTE_TYPES,
  SIGNING_CHECKLIST_ADD_ROUTE_NAME,
  SIGNING_CHECKLIST_ROUTE_NAME,
} from '@/router'
import { UPDATE_ACCESS_TIME as UPDATE_DOCUMENTS_ACCESS_TIME } from '@/store/modules/documents/action_types'
import { RESET_WATERMARKED_DIALOG_DATA } from '@/store/modules/files/mutation_types'
import { START_AUTH_TIMER, GET_AUTH_STATUS } from '@/store/modules/imanage/action_types'
import { UPDATE_ACCESS_TIME as UPDATE_ROOM_ACCESS_TIME } from '@/store/modules/room/action_types'

export default {
  name: 'Project',
  components: {
    AppButton,
    AppCard,
    IManageAuthDialog,
    WatermarkedViewerDialog,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      SIGNING_CHECKLIST_ADD_ROUTE_NAME,
      disabled: false,
      getRoomPromise: null,
      iManageAuthDialogIsOpened: false,
    }
  },
  metaInfo () {
    return {
      titleTemplate: this.$store.getters['room/roomName'] ? '%s - ' + this.$store.getters['room/roomName'] + ' - Closd' : 'Closd',
    }
  },
  computed: {
    ...mapState(['projectDrawerPinned', 'selectedProject']),
    ...mapState('room', ['currentRoom', 'processing', 'error']),
    ...mapState('imanage', ['authCode', 'authStatus', 'userWantIManage', 'currentTimeTimeout']),
    ...mapGetters('user', ['currentUserId', 'iManageEnabled']),
    ...mapGetters('login', ['isFullAccessLogin']),
    ...mapGetters('imanage', ['iManageLogged', 'iManageLogExpired']),
    ...mapGetters('room', ['roomName', 'isDataRoom']),
    ...mapState('files', ['watermarkedDialogData']),
    subDisabledReason () {
      return this.disabled && (this.currentRoom.subscriptionDisabledFor || 'disabled')
    },
    isViewWithoutYPadding () {
      const pagesWithoutPadding = [
        ENVELOPE_TEMPLATE_ADD_ROUTE_NAME,
        ROOM_DOCUMENTS_ROUTE_NAME,
        SIGNING_CHECKLIST_ADD_ROUTE_NAME,
      ]

      return pagesWithoutPadding.includes(this.$route.name)
    },
    shouldAuthenticateOnIManage () {
      if (this.iManageLogged || !this.userWantIManage) {
        return false
      }

      let userAlreadyRefusedIManageAuth = false
      const iManageRefusalDate = getUserSetting(this.currentUserId, IMANAGE_REFUSAL_USER_SETTINGS_KEY)

      if (iManageRefusalDate) {
        const iManageRefusalDateAsTimestamp = parseInt(iManageRefusalDate)
        if (!isNaN(iManageRefusalDateAsTimestamp)) {
          userAlreadyRefusedIManageAuth = dayjs().isBefore(dayjs(iManageRefusalDateAsTimestamp).add(1, 'day'))
        }
      }

      return !userAlreadyRefusedIManageAuth
    },
  },
  watch: {
    '$route.params.mnemo': {
      handler: async function (val) {
        if (val && this.isFullAccessLogin) {
          this.initRoom()
        }
      },
      immediate: true,
    },
    $route: {
      async handler (to) {
        if (this.isFullAccessLogin) {
          const mnemo = to.params?.mnemo
          // $room.params.mnemo handler is always called first.
          // It stores a promise so we can wait for it and this call is always after getRoom
          await this.getRoomPromise
          if (!this.error) {
            this.$store.dispatch(`room/${UPDATE_ROOM_ACCESS_TIME}`, mnemo)
            if (to.name === ROOM_DOCUMENTS_ROUTE_NAME) {
              this.$store.dispatch(`documents/${UPDATE_DOCUMENTS_ACCESS_TIME}`, mnemo)
            }
          }
        }
      },
      immediate: true,
    },
    iManageLogExpired (value) {
      if (value) {
        this.getImanageAuthStatus()
      }
    },
  },
  mounted () {
    this.setProjectLayout(true)
    if (this.isFullAccessLogin && this.iManageEnabled) {
      if (this.shouldAuthenticateOnIManage) {
        this.getImanageAuthStatus()
      }

      if (this.iManageLogged && !this.currentTimeTimeout) {
        this.START_AUTH_TIMER()
      }
    }
  },
  beforeDestroy () {
    this.killRoom()
    //  applySubscriptionBrandingColor has to be called after the room is killed to reassign the user's sub color, or the default color.
    this.applySubscriptionBrandingColor()
    this.setProjectLayout(false)
  },
  methods: {
    ...mapActions(['setProjectLayout']),
    ...mapActions('room', ['getRoomByMnemo', 'killRoom']),
    ...mapActions('imanage', [GET_AUTH_STATUS, START_AUTH_TIMER]),
    closeWatermarkedDialog () {
      this.$store.commit(`files/${RESET_WATERMARKED_DIALOG_DATA}`)
    },
    applySubscriptionBrandingColor () {
      const colorSub = this.$store.getters['subscription/getSubscriptionBrandingColor']

      if (this.currentRoom?.subscription?.customizationEnabled) {
        const color = this.currentRoom?.subscription?.color
        if (color) {
          this.$vuetify.theme.themes.light.primary = color
        }
      } else if (colorSub) {
        this.$vuetify.theme.themes.light.primary = colorSub
      } else {
        this.$vuetify.theme.themes.light.primary = this.$vuetify.theme.themes.light.brand
      }
    },
    async getImanageAuthStatus () {
      try {
        await this.GET_AUTH_STATUS(this.authCode.imConfig)
      } catch (error) {
        if (error.response?.data?.errorCode === 'ERR_REQUIRE_IMANAGE_AUTH' || !this.authCode?.imConfig) {
        this.iManageAuthDialogIsOpened = true
        } else {
          console.error(error)
        }
      }
    },
    async initRoom () {
      this.killRoom()
      this.getRoomPromise = this.getRoomByMnemo({
        mnemo: this.mnemo,
        params: {
          hasDocumentToSign: true,
        },
      })
      await this.getRoomPromise
      if (!this.error && this.currentRoom) {
        this.applySubscriptionBrandingColor()
        if (this.currentRoom.subscriptionDisabledFor) {
          this.disabled = true
        }
        if (this.$route.name === ROOM_ROUTE_NAME) {
          if (this.isDataRoom) {
            this.$router.replace({ name: ROOM_DOCUMENTS_ROUTE_NAME, params: { roomType: ROOM_ROUTE_TYPES.DATAROOM } })
          } else if (this.currentRoom.hasDocumentToSign) {
            this.$router.replace({ name: SIGNING_CHECKLIST_ROUTE_NAME, params: { roomType: ROOM_ROUTE_TYPES.PROJECT } })
          } else {
            this.$router.replace({ name: ROOM_MEMBERS_ROUTE_NAME, params: { roomType: getRoomRouteType(this.currentRoom) } })
          }
        }
        // Legacy route !
        if (this.$route.params.roomType === ROOM_ROUTE_TYPES.UNDEFINED) {
          this.$router.replace({ name: this.$route.name, params: { roomType: getRoomRouteType(this.currentRoom) }, query: this.$route.query })
        }
      } else if (this.error?.request.status === 404) {
        this.$router.replace({ name: NOT_FOUND_ROUTE_NAME })
      }
    },
  },
}
</script>
