<template>
  <div>
    <template v-if="file.displayWatermarked">
      <div class="d-flex align-center clickable"
           @click="openWatermarkedDialog"
      >
        <ClosdFildersIcon :document="file"/>
        <template v-if="file.numbering">
          <span class="mr-1">{{`${file.numbering}` }}</span>
        </template>
        <div class="filderName">{{file.basename || file.name}}</div>
        <v-chip v-if="file.version > 1"
                color="secondary"
                small
                class="caption mx-2"
                label
        >
          {{ `v.${file.version}` }}
        </v-chip>
      </div>
    </template>
    <template v-else-if="file.canDownload">
      <AppDownloadFile :link="fileLink" :forceReader="fileExt === 'pdf'">
        <template #default="{ submitForm }">
          <div class="d-flex align-center clickable" @click="submitForm">
            <ClosdFildersIcon :document="file"/>
            <template v-if="file.numbering">
              <span class="mr-1">{{`${file.numbering}` }}</span>
            </template>
            <div class="filderName">{{file.basename || file.name}}</div>
            <v-chip v-if="file.version > 1"
                    color="secondary"
                    small
                    class="caption mx-2"
                    label
            >
              {{ `v.${file.version}` }}
            </v-chip>
          </div>
        </template>
      </AppDownloadFile>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile.vue'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon.vue'
import { roomFileDownloadUrl } from '@/common/utils/files'
import { SET_WATERMARKED_DIALOG_DATA } from '@/store/modules/files/mutation_types'

export default {
  name: 'DocumentsTableItemFileName',
  components: { AppDownloadFile, ClosdFildersIcon },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    fileExt () {
      if (this.file instanceof File) {
        return this.file.name.split('.').pop()
      } else if (this.file.ext) {
        return this.file.ext
      } else if (this.file.docExt) {
        return this.file.docExt
      } else if (this.file.extension) {
        return this.file.extension
      }
      return null
    },
    fileLink () {
      return roomFileDownloadUrl(this.file.id, this.roomMnemo)
    },
  },
  methods: {
    ...mapMutations('files', [SET_WATERMARKED_DIALOG_DATA]),
    openWatermarkedDialog () {
      this.SET_WATERMARKED_DIALOG_DATA({
        file: this.file,
        isOpen: true,
      })
    },
  },
}
</script>
