export const ADD_CHASE_TIMER_ENVELOPE = 'ADD_CHASE_TIMER_ENVELOPE'
export const ADD_CHASE_TIMER_GLOBAL = 'ADD_CHASE_TIMER_GLOBAL'
export const ADD_CHASE_TIMER_SIGNATURE = 'ADD_CHASE_TIMER_SIGNATURE'
export const ADD_CHASE_TIMER_VALIDATION = 'ADD_CHASE_TIMER_VALIDATION'
export const ADD_TO_SIGNATURES = 'ADD_TO_SIGNATURES'
export const REMOVE_CHASE_VALIDATOR_PENDING = 'REMOVE_CHASE_VALIDATOR_PENDING'
export const RESET_CLOSING_DATA = 'RESET_CLOSING_DATA'
export const RESET_ROOM = 'RESET_ROOM'
export const RESET_ROOM_RIGHTS = 'RESET_ROOM_RIGHTS'
export const SET_CHASE_USERS_ERROR = 'SET_CHASE_USERS_ERROR'
export const SET_CHASE_USERS_PENDING = 'SET_CHASE_USERS_PENDING'
export const SET_CHASE_VALIDATOR_ERROR = 'SET_CHASE_VALIDATOR_ERROR'
export const SET_CHASE_VALIDATOR_PENDING = 'SET_CHASE_VALIDATOR_PENDING'
export const SET_CLOSE_ROOM_ERROR = 'SET_CLOSE_ROOM_ERROR'
export const SET_CLOSE_ROOM_PENDING = 'SET_CLOSE_ROOM_PENDING'
export const SET_CLOSING_SCHEDULED_AT = 'SET_CLOSING_SCHEDULED_AT'
export const SET_CLOSING_SCHEDULED_END_AT = 'SET_CLOSING_SCHEDULED_END_AT'
export const SET_CLOSING_STARTED_AT = 'SET_CLOSING_STARTED_AT'
export const SET_CURRENT_ROOM = 'SET_CURRENT_ROOM'
export const SET_CURRENT_ROOM_PROVIDERS = 'SET_CURRENT_ROOM_PROVIDERS'
export const SET_ENABLE_DOCUMENTS_LOGS = 'SET_ENABLE_DOCUMENTS_LOGS'
export const SET_IN_CLOSING = 'SET_IN_CLOSING'
export const SET_LAST_ACCESS_TIMESTAMP = 'SET_LAST_ACCESS_TIMESTAMP'
export const SET_LAST_MNEMO_ACCESSED = 'SET_LAST_MNEMO_ACCESSED'
export const SET_MORE_SIGNATURES_ERROR = 'SET_MORE_SIGNATURES_ERROR'
export const SET_MORE_SIGNATURES_PENDING = 'SET_MORE_SIGNATURES_PENDING'
export const SET_PATCH_ROOM_ERROR = 'SET_PATCH_ROOM_ERROR'
export const SET_PATCH_ROOM_GLOBAL_RIGHTS_ERROR = 'SET_PATCH_ROOM_GLOBAL_RIGHTS_ERROR'
export const SET_PATCH_ROOM_GLOBAL_RIGHTS_PENDING = 'SET_PATCH_ROOM_GLOBAL_RIGHTS_PENDING'
export const SET_PATCH_ROOM_GROUP_RIGHTS_ERROR = 'SET_PATCH_ROOM_GROUP_RIGHTS_ERROR'
export const SET_PATCH_ROOM_GROUP_RIGHTS_PENDING = 'SET_PATCH_ROOM_GROUP_RIGHTS_PENDING'
export const SET_PATCH_ROOM_PENDING = 'SET_PATCH_ROOM_PENDING'
export const SET_PATCH_ROOM_USER_RIGHTS_ERROR = 'SET_PATCH_ROOM_USER_RIGHTS_ERROR'
export const SET_PATCH_ROOM_USER_RIGHTS_PENDING = 'SET_PATCH_ROOM_USER_RIGHTS_PENDING'
export const SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT = 'SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT'
export const SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT_PENDING = 'SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT_PENDING'
export const SET_ROOM_PROVIDERS_ERROR = 'SET_ROOM_PROVIDERS_ERROR'
export const SET_ROOM_PROVIDERS_PENDING = 'SET_ROOM_PROVIDERS_PENDING'
export const SET_ROOM_RIGHTS = 'SET_ROOM_RIGHTS'
export const SET_ROOM_RIGHTS_ERROR = 'SET_ROOM_RIGHTS_ERROR'
export const SET_ROOM_RIGHTS_PENDING = 'SET_ROOM_RIGHTS_PENDING'
export const SET_ROOM_STATS = 'SET_ROOM_STATS'
export const SET_ROOM_STATS_ERROR = 'SET_ROOM_STATS_ERROR'
export const SET_ROOM_STATS_PENDING = 'SET_ROOM_STATS_PENDING'
export const SET_ROOM_STORAGE_ADDON_PENDING = 'SET_ROOM_STORAGE_ADDON_PENDING'
export const SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN = 'SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN'
export const SET_SIGNATURES = 'SET_SIGNATURES'
export const SET_SIGNATURES_ERROR = 'SET_SIGNATURES_ERROR'
export const SET_SIGNATURES_PENDING = 'SET_SIGNATURES_PENDING'
export const SET_ROOM_NOTIFICATIONS_PENDING = 'SET_ROOM_NOTIFICATIONS_PENDING'
export const SET_ROOM_NOTIFICATIONS = 'SET_ROOM_NOTIFICATIONS'
export const SET_ROOM_NOTIFICATIONS_SEARCH_QUERY = 'SET_ROOM_NOTIFICATIONS_SEARCH_QUERY'
export const SET_ROOM_NOTIFICATIONS_SORT_BY = 'SET_ROOM_NOTIFICATIONS_SORT_BY'
export const SET_ROOM_NOTIFICATIONS_SORT_DESC = 'SET_ROOM_NOTIFICATIONS_SORT_DESC'
export const RESET_ROOM_NOTIFICATIONS_SORT = 'RESET_ROOM_NOTIFICATIONS_SORT'
export const SET_ROOM_NOTIFICATIONS_ERROR = 'SET_ROOM_NOTIFICATIONS_ERROR'
export const SET_UPDATE_ACCESS_TIME_ERROR = 'SET_UPDATE_ACCESS_TIME_ERROR'
export const SET_UPDATE_ACCESS_TIME_PENDING = 'SET_UPDATE_ACCESS_TIME_PENDING'
export const SET_NOTIFY_PM_NO_MORE_SPACE_PENDING = 'SET_NOTIFY_PM_NO_MORE_SPACE_PENDING'
export const SET_MOVE_ROOM_DIALOG_IS_OPEN = 'SET_MOVE_ROOM_DIALOG_IS_OPEN'
export const SET_MOVE_ROOM_PENDING = 'SET_MOVE_ROOM_PENDING'
export const SET_ROOM_TO_MOVE = 'SET_ROOM_TO_MOVE'
