<template>
  <div>
    <v-row
      v-if="profile.subscription"
      class="mt-4 mb-2"
    >
      <v-col
        v-if="profile.subscription &&
        !profile.subscription?.hideProjectOption &&
        profile.isSubUser"
        cols="12"
        sm="6"
        md="3"
      >
        <ProjectTypeProject />
      </v-col>
      <v-col
        v-if="profile.subscription &&
         !profile.subscription?.hideDataroomOption &&
         profile.isSubUser"
        cols="12"
        sm="6"
        md="3"
      >
        <ProjectTypeDataroom />
      </v-col>
      <v-col
        v-if="profile.subscription &&
        !profile.subscription?.hideArchiveOption"
        cols="12"
        sm="6"
        md="3"
      >
        <ProjectTypeArchive />
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col cols="12" class="align-center d-flex">
        <SearchTextField v-model="search" :tooltipText="$t('projects.ProjectsHeader.searchPlaceholder')" :loading="roomSearchResultsPending" class="mr-4" />

        <AppButton
          class="mr-auto"
          color="white"
          :disabled="!rooms"
          @click="$store.commit('rooms/OPEN_FILTER_PANE')"
        >
          <v-icon :left="!$vuetify.breakpoint.xs" size="14">fa fa-sliders</v-icon>
          <span v-if="!$vuetify.breakpoint.xs">{{$t('projects.ProjectsHeader.filters')}}</span>
        </AppButton>
        <AppButton v-if="(!currentProjectsFolder.id || currentProjectsFolder.depth < FOLDER_MAX_DEPTH) && !hasRecentRooms && !searchModeEnabled"
                   color="primary"
                   @click="openProjectsCreateNewFolder"
        >
          <app-icon icon-name="plus" size="medium" class="mr-1"></app-icon>
          {{ $t('project.documents.dialogs.DocumentsNewFolderDialog.createFolder') }}
        </AppButton>

        <div v-if="!$vuetify.breakpoint.mobile" class="ml-4">
          <AppTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <AppButton icon
                         v-bind="attrs"
                         v-on="on"
                         @click="emitDisplayChoice(DISPLAY.grid)"
              >
                <font-awesome-icon :icon="['far', 'border-all']"
                                   :class="{'text--primary': display === DISPLAY.grid, 'text--primary': display !== DISPLAY.grid}"
                                   style="font-size: 24px;"
                ></font-awesome-icon>
              </AppButton>
            </template>
            <span>{{$t('projects.ProjectsHeader.gridDisplay')}}</span>
          </AppTooltip>
          <AppTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <AppButton icon
                         v-bind="attrs"
                         class="ml-2"
                         v-on="on"
                         @click="emitDisplayChoice(DISPLAY.table)"
              >
                <font-awesome-icon :icon="['far', 'list']"
                                   :class="{'text--primary': display === DISPLAY.table, 'text--primary': display !== DISPLAY.table}"
                                   style="font-size: 24px;"
                ></font-awesome-icon>
              </AppButton>
            </template>
            <span>{{$t('projects.ProjectsHeader.tableDisplay')}}</span>
          </AppTooltip>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import { FOLDER_MAX_DEPTH } from '@/common/constants'

import { DISPLAY } from './display'
import ProjectTypeArchive from './project-types/ProjectTypeArchive.vue'
import ProjectTypeDataroom from './project-types/ProjectTypeDataroom.vue'
import ProjectTypeProject from './project-types/ProjectTypeProject.vue'
import AppTooltip from '../common/AppTooltip'
import AppButton from '../common/buttons/AppButton'
import SearchTextField from '../common/SearchTextField'
import { SET_IS_OPEN } from '../store/modules/projects-create-new-folder/mutation_types'
import { SET_DISPLAY_TYPE } from '../store/modules/rooms/mutation_types'

export default {
  name: 'ProjectsHeader',
  components: {
    AppButton,
    AppTooltip,
    SearchTextField,
    ProjectTypeProject,
    ProjectTypeDataroom,
    ProjectTypeArchive,
  },
  props: {
    display: {
      required: true,
      validator: value => { return value in DISPLAY },
    },
    searchQuery: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      DISPLAY,
      FOLDER_MAX_DEPTH,
    }
  },
  computed: {
    ...mapState('projectsFolders', ['currentProjectsFolder']),
    ...mapState('rooms', ['sort', 'displayType', 'rooms', 'searchModeEnabled', 'roomSearchResultsPending']),
    ...mapState('user', ['profile']),
    ...mapGetters('rooms', ['hasRecentRooms']),
    search: {
      get () {
        return this.searchQuery
      },
      set (val) {
        this.$emit('update:searchQuery', val)
      },
    },
  },
  methods: {
    ...mapMutations('rooms', [SET_DISPLAY_TYPE]),
    ...mapMutations('projectsCreateNewFolder', [SET_IS_OPEN]),
    openProjectsCreateNewFolder () {
      this.SET_IS_OPEN(true)
    },
    emitDisplayChoice (displayType) {
      this.SET_DISPLAY_TYPE(displayType)
      this.$emit('display', displayType)
    },
  },
}
</script>
