<template>
  <li class="folder-item">
    <template v-if="homeLayout">
      <ProjectNavigationSection :key="`${folder.id}${folder.parentId}`"
                                :value="`${folder.id}${folder.parentId}`"
                                :title="folder.name"
                                :icon="folder.icon ? folder.icon : ''"
                                @click.native="onFolderClick"
      >
      <template #content>
          <DocumentsSidebarFolderItemContent :folder="folder"
                                             :depth="depth"
                                             :isCurrentlyViewedFolder="isCurrentlyViewedFolder"
                                             :areChildrenExpanded="areChildrenExpanded"
                                             :loadingChildren="loadingChildren"
                                             :disabled="false"
                                             @load-children="loadChildren"
          >
          </DocumentsSidebarFolderItemContent>
        </template>
      </ProjectNavigationSection>

      <template v-if="areChildrenExpanded">
        <DocumentsSidebarFolderItem v-for="childFolder in sortedChildren"
                                    :key="childFolder.id"
                                    :folder="childFolder"
                                    :depth="depth + 1"
                                    :children-sort="childrenSort"
                                    :disabled="false"
        >
        </DocumentsSidebarFolderItem>
      </template>
    </template>
    <ul v-else>
      <AppDroppableArea :disabled="disabled"
                        :draggable="true"
                        @dragenter="dragenter(folder)"
                        @dragleave="dragleave(folder)"
                        @drop="drop($event, folder)"
                        @dragstart="dragstart($event, folder)"
      >
      <app-treeview-item :selected="isCurrentlyViewedFolder"
                         @click.native="onFolderClick"
      >
        <div class="folder-item__inner-content"
             :style="{ paddingLeft: leftOffset }"
        >
          <app-icon v-if="hasChildren"
                    size="regular"
                    icon-weight="fas"
                    :icon-name="areChildrenExpanded ? 'caret-down' : 'caret-right'"
                    class="expand-icon"
                    @click.native.stop="areChildrenExpanded = !areChildrenExpanded"
          />

          <app-icon size="regular"
                    icon-weight="fas"
                    icon-name="folder"
                    class="folder-icon"
          />

          <AppTooltip top :open-delay="500" :disabled="!hasTooltip">
            <template #activator="{ attrs, on }">
              <div v-bind="attrs"
                   ref="folderName"
                   class="folder-name text-truncate"
                   v-on="on"
              >
                <app-text as="span"
                          :variant="isCurrentlyViewedFolder ? 'small-bold' : 'small-regular'"
                >
                  {{ folderName }}
                </app-text>
              </div>
            </template>
            {{ folderName }}
          </AppTooltip>
        </div>
      </app-treeview-item>
      </AppDroppableArea>
      <template v-if="areChildrenExpanded">
        <DocumentsSidebarFolderItem v-for="childFolder in sortedChildren"
                                    :key="childFolder.id"
                                    :folder="childFolder"
                                    :depth="depth + 1"
                                    :children-sort="childrenSort"
                                    :disabled="disabled"
                                    :draggable="true"
                                    @drag-enter-folder="dragenter"
                                    @drag-leave-folder="dragleave"
                                    @drop-documents-in-folder="drop"
                                    @dragstart="dragstart"
        />
      </template>
    </ul>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { mapActions, mapState, mapMutations } from 'vuex'

import AppDroppableArea from '@/common/AppDroppableArea.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import { FOLDER_ICON_COLOR } from '@/common/constants'
import projectsFoldersMixin from '@/mixins/projectsFoldersMixin'
import { RECENT_FOLDER, ROOT_FOLDER } from '@/project/documents/constants/special-folders'
import ProjectNavigationSection from '@/project/navigation-drawer/ProjectNavigationSection.vue'
import { ROOMS_ROUTE_NAME, FOLDER_ROUTE_NAME } from '@/router/index'
import { GET_FOLDER_PATH, GO_TO_FOLDER } from '@/store/modules/documents/action_types'
import { GET_PROJECTS_FOLDER_BY_ID } from '@/store/modules/projects-folders/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

import DocumentsSidebarFolderItemContent, { type DocumentsSidebarFolder } from './DocumentsSidebarFolderItemContent.vue'

export default defineComponent({
  name: 'DocumentsSidebarFolderItem',
  components: {
    ProjectNavigationSection,
    DocumentsSidebarFolderItemContent,
    AppDroppableArea,
    AppTooltip,
  },
  mixins: [projectsFoldersMixin],
  props: {
    folder: {
      type: Object as PropType<DocumentsSidebarFolder>,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
    childrenSort: {
      type: Function as PropType<(folderA: DocumentsSidebarFolder, folderB: DocumentsSidebarFolder) => number>,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      areChildrenExpanded: false,
      hasTooltip: false,
      loadingChildren: false,
      FOLDER_ICON_COLOR,
    }
  },
  computed: {
    ...mapState(['homeLayout']),
    ...mapState('documents', ['documents']),
    ...mapState('projectsFolders', ['currentProjectsFolder']),
    ...mapState('documentsBreadcrumb', ['breadcrumb']),
    findAndExpandCurrentProjectsFolder () {
      let expand = false
      if (this.folder.id === this.currentProjectsFolder.id) {
        expand = true
      } else if (!expand && this.folder.children && this.folder.children.length > 0) {
        if (this.folder.children.find(item => item.id === this.currentProjectsFolder.id)) {
          expand = true
        }
      }
      return expand
    },
    isCurrentlyViewedFolder (): boolean {
      if (this.homeLayout) {
        return this.currentProjectsFolder.id === this.folder.id
      }
      return this.documents?.id === this.folder.id
      },
    leftOffset (): string {
      const LEVEL_OFFSET = 8
      return `${this.depth * LEVEL_OFFSET}px`
    },
    hasChildren (): boolean {
      return !!this.folder.children && this.folder.children.length > 0
    },
    folderName (): string {
      if (this.folder.numbering) {
        return `${this.folder.numbering} ${this.folder.name}`
      }

      return this.folder.name
    },
    sortedChildren (): Array<DocumentsSidebarFolder> {
      if (!this.folder.children) {
        return []
      }

      if (this.homeLayout) {
        return this.folder.children
      }
      return [...this.folder.children].sort(this.childrenSort)
    },
  },
  watch: {
    breadcrumb: {
      handler (breadcrumbFolders) {
        const isFolderInBreadcrumb = breadcrumbFolders.some(
          breadcrumbFolder => breadcrumbFolder.id === this.folder.id,
        )

        if (isFolderInBreadcrumb && !this.areChildrenExpanded) {
          this.areChildrenExpanded = true
        }
      },
      immediate: true,
    },
    // If the current folder changes, then open the corresponding folder in the sidebar
    // For example, by clicking on the grid of folder or subfolder to open it
    currentProjectsFolder () {
      if (this.homeLayout && this.currentProjectsFolder.id === this.folder.id) {
        this.areChildrenExpanded = true
      }
    },
  },
  created () {
    if (this.homeLayout && this.currentProjectsFolder.id) {
      if (this.findAndExpandCurrentProjectsFolder) {
        this.areChildrenExpanded = true
      }
    }
  },
  mounted () {
    (this.$refs.folderName as HTMLDivElement)?.addEventListener(
      'mouseenter',
      (event) => {
        const folderNameDivElement = event.target as HTMLDivElement
        this.hasTooltip = folderNameDivElement.offsetWidth < folderNameDivElement.scrollWidth
      },
    )
  },
  methods: {
    ...mapActions('projectsFolders', [GET_PROJECTS_FOLDER_BY_ID]),
    ...mapActions('documents', [GET_FOLDER_PATH, GO_TO_FOLDER]),
    ...mapActions('documentsBreadcrumb', ['setBreadcrumbItems']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async loadChildren () {
      if (this.homeLayout) {
        if (!this.areChildrenExpanded) {
          if (this.currentProjectsFolder.id === this.folder.id) {
            this.areChildrenExpanded = true
          } else {
            this.loadingChildren = true
            try {
              await this.GET_PROJECTS_FOLDER_BY_ID({
                folderId: this.folder.id,
                navigate: false,
              })
              this.areChildrenExpanded = true
            } catch (error) {
              this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsNewFolderDialog.getFolderError', { name: this.folder.name }))
            } finally {
              this.loadingChildren = false
            }
          }
        } else {
          this.areChildrenExpanded = false
        }
      } else {
        this.areChildrenExpanded = !this.areChildrenExpanded
      }
    },
    async onFolderClick () {
      if (this.homeLayout) {
        this.handleHomeLayoutFolderClick()
      } else {
        this.areChildrenExpanded = true

        this.GO_TO_FOLDER(this.folder.id)

        const folderPath = await this.GET_FOLDER_PATH(this.folder.id)
        this.setBreadcrumbItems([ROOT_FOLDER].concat(folderPath))
      }
    },
    handleHomeLayoutFolderClick () {
      if (this.currentProjectsFolder.id === this.folder.id) return

      if (this.folder.id === RECENT_FOLDER.id) {
        this.handleRecentFolderClick()
      } else if (this.folder.id === ROOT_FOLDER.id) {
        this.handleRootFolderClick()
      } else {
        this.navigateToProjectsFolder()
        this.areChildrenExpanded = true
      }
    },
    handleRecentFolderClick () {
      if (this.$route.path !== '/folder/recent') {
        this.$router.push({
          name: FOLDER_ROUTE_NAME,
          params: {
            folderId: 'recent',
          },
        })
      }
    },
    handleRootFolderClick () {
      if (this.$route.path !== '/') {
        this.$router.push({ name: ROOMS_ROUTE_NAME })
      }
    },
    dragenter (folder) {
      this.$emit('drag-enter-folder', folder)
    },
    dragleave (folder) {
      this.$emit('drag-leave-folder', folder)
    },
    dragstart ($event, folder) {
      this.$emit('dragstart', $event, folder)
    },
    drop ($event, folder) {
      this.$emit('drop-documents-in-folder', $event, folder)
    },
  },
})
</script>

<style lang="scss" scoped>
.folder-item {
  cursor: pointer;
  list-style-type: none;

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__inner-content {
    display: grid;
    align-items: end;
    grid-template-columns: 24px auto 1fr;
    grid-template-areas: "expand-icon folder-icon folder-name";
    column-gap: 8px;
    cursor: pointer;
  }

  .expand-icon {
    grid-area: expand-icon;
    color: var(--v-grey-darken1);
    border-radius: 4px;
    width: 24px;

    &:hover {
      background: var(--v-grey-lighten4);
    }
  }

  .folder-icon {
    grid-area: folder-icon;
    color: #7BA9F9
  }

  .folder-name {
    grid-area: folder-name;
  }
}
</style>
