import {
  faAlarmClock,
  faArrowDownToLine,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRightLong,
  faArrowUpRightFromSquare,
  faArrowsAlt,
  faAsterisk,
  faBan,
  faBold,
  faBolt,
  faBorderAll,
  faBuilding,
  faCalendarAlt,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faClock,
  faClone,
  faCommentsAlt,
  faCrown,
  faDatabase,
  faEdit,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpenText,
  faExchange,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faFileAlt,
  faFileExcel,
  faFileInvoice,
  faFileLines,
  faRectangleHistory,
  faFilePdf,
  faFileSignature,
  faFileUpload,
  faFileWord,
  faFolder,
  faFolderOpen,
  faGavel,
  faHeading,
  faHourglassStart,
  faICursor,
  faIdBadge,
  faIdCard,
  faIndent,
  faItalic,
  faLevelUp,
  faLink,
  faList,
  faListAlt,
  faListOl,
  faLockAlt,
  faMagnifyingGlass,
  faOutdent,
  faPaperclip,
  faPen,
  faPlay,
  faPlus,
  faQuestionCircle,
  faRedo,
  faRemoveFormat,
  faSave,
  faSdCard,
  faSearch,
  faShare,
  faShieldCheck,
  faSignature,
  faSlidersH,
  faSpinnerThird,
  faSquare,
  faStrikethrough,
  faTasks,
  faTextSize,
  faTimes,
  faTrash,
  faTrashAlt,
  faTrashCan,
  faUnderline,
  faUser,
  faUserFriends,
  faUserLock,
  faUsers,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons'

export const regularLibrary = {
  faAlarmClock,
  faArrowDownToLine,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRightLong,
  faArrowUpRightFromSquare,
  faArrowsAlt,
  faAsterisk,
  faBan,
  faBold,
  faBolt,
  faBorderAll,
  faBuilding,
  faCalendarAlt,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faClock,
  faClone,
  faCommentsAlt,
  faCrown,
  faDatabase,
  faEdit,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpenText,
  faExchange,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faFileAlt,
  faFileExcel,
  faFileInvoice,
  faFileLines,
  faRectangleHistory,
  faFilePdf,
  faFileSignature,
  faFileUpload,
  faFileWord,
  faFolder,
  faFolderOpen,
  faGavel,
  faHeading,
  faHourglassStart,
  faICursor,
  faIdBadge,
  faIdCard,
  faIndent,
  faItalic,
  faLevelUp,
  faLink,
  faList,
  faListAlt,
  faListOl,
  faLockAlt,
  faMagnifyingGlass,
  faOutdent,
  faPaperclip,
  faPen,
  faPlay,
  faPlus,
  faQuestionCircle,
  faRedo,
  faRemoveFormat,
  faSave,
  faSdCard,
  faSearch,
  faShare,
  faShieldCheck,
  faSignature,
  faSlidersH,
  faSpinnerThird,
  faSquare,
  faStrikethrough,
  faTasks,
  faTextSize,
  faTimes,
  faTrash,
  faTrashAlt,
  faTrashCan,
  faUnderline,
  faUser,
  faUserFriends,
  faUserLock,
  faUsers,
  faXmark,
}
