<template>
  <div class="ChecklistTableTaskAttachedFilesDialogMultiSelectBar">
    <app-button class="mr-2"
                type="icon"
                @click="deselectAllAttachedFiles">
      <app-icon icon-name="xmark" />
    </app-button>
    <div class="text-h4">
      {{$tc('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialogMultiSelectBar.attachedFileCount', computedSelectedAttachedFiles.length, {files: computedSelectedAttachedFiles.length})}}
    </div>
    <MultiSelectBarButton
      v-if="selectionCanBeShared"
      class="ml-10"
      @click.native="openSharePane"
    >
      <font-awesome-icon :icon="['fas', 'share']" class="mr-2"></font-awesome-icon>
      {{$t('common.share')}}
    </MultiSelectBarButton>
    <MultiSelectBarButton
      v-if="canFilesBeAddedToChecklist"
      :to="signingChecklistAddRouteOptions"
    >
      <font-awesome-icon :icon="['fas', 'plus']" class="mr-2"></font-awesome-icon>
      {{$t('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialogMultiSelectBar.addSigningChecklist')}}
    </MultiSelectBarButton>
    <MultiSelectBarButton
      v-if="canSelectionBeWritten"
      @click.native="openMovePane"
    >
      <font-awesome-icon :icon="['fas', 'folder']" class="mr-2"></font-awesome-icon>
      {{$t('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialogMultiSelectBar.move')}}
    </MultiSelectBarButton>
    <MultiSelectBarButton v-if="selectionCanBeDownloaded" @click="OPEN_DOCUMENTS_DOWNLOAD_FILES(computedSelectedAttachedFiles)"
    >
      <font-awesome-icon :icon="['fas', 'download']" class="mr-2"></font-awesome-icon>
      {{$t('common.download')}}
    </MultiSelectBarButton>
    <PolyOfficeMultiSelectBarButtonExport v-if="canSelectionBeExportedToPolyOffice"
      :selectionSize="fileSelectionSize"
      :selectionCount="computedSelectedAttachedFiles.length"
      @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
    />
    <ArchiveAddAccessMultiSelectBarButton :routeOptions="archiveAddRouteOptions" />
    <MultiSelectBarButton
      v-if="canSelectionBeWritten"
      @click.native="openDeleteDialog"
    >
      <font-awesome-icon :icon="['fas', 'trash']" class="mr-2"></font-awesome-icon>
      {{$t('common.delete')}}
    </MultiSelectBarButton>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import ArchiveAddAccessMultiSelectBarButton from '@/common/archives/ArchiveAddAccessMultiSelectBarButton.vue'
import { DocumentsExport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeMultiSelectBarButtonExport from '@/common/polyOffice/PolyOfficeMultiSelectBarButtonExport'
import { OPEN_DOCUMENTS_DOWNLOAD_FILES } from '@/store/modules/documents-download-files/action_types'
import { GET_EXPORT_IFRAME } from '@/store/modules/poly-office/action_types'

import MultiSelectBarButton from '../../../common/buttons/MultiSelectBarButton'
import { canFilesBeAddedToChecklist } from '../../../common/utils/signingChecklist'
import { SIGNING_CHECKLIST_ADD_ROUTE_NAME } from '../../../router'
import {
  RESET_SELECTED_ATTACHED_FILE,
  SET_DELETE_ATTACHED_FILES_DIALOG,
  SET_TABLE_TASK_PANE,
} from '../../../store/modules/checklist/mutation_types'
export default {
  name: 'ChecklistTableTaskAttachedFilesDialogMultiSelectBar',
  components: { MultiSelectBarButton, ArchiveAddAccessMultiSelectBarButton, PolyOfficeMultiSelectBarButtonExport },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    selectionModel: {
      type: Array,
      default: undefined,
    },
  },
  computed: {
    ...mapState('checklist', ['selectedAttachedFiles', 'currentTaskFolder']),
    ...mapGetters('room', ['isCurrentUserPm', 'maxSignableSize', 'currentUserRights']),
    ...mapGetters('user', ['currentUserId', 'polyOfficeEnabled']),
    ...mapGetters('groups', ['currentUserGroupId']),
    // Use store selectedAttachedFiles as default if selectionModel is not provided
    computedSelectedAttachedFiles () {
      return this.selectionModel ? this.selectionModel : this.selectedAttachedFiles
    },
    canFilesBeAddedToChecklist () {
      return canFilesBeAddedToChecklist(this.computedSelectedAttachedFiles, this.isCurrentUserPm, this.maxSignableSize)
    },
    signingChecklistAddRouteOptions () {
      return {
        name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
        params: {
          files: this.computedSelectedAttachedFiles,
        },
        query: {
          fileIds: this.computedSelectedAttachedFiles.map((document) => document.id).toString(),
        },
      }
    },
    selectionCanBeDownloaded () {
      return this.currentUserRights.canDownload
    },
    selectionCanBeShared () {
      return this.isCurrentUserPm || this.computedSelectedAttachedFiles.every(doc => doc.canShare)
    },
    canSelectionBeWritten () {
      return this.computedSelectedAttachedFiles.every(doc => {
        if (doc.canWrite) return true
        if (doc.rights && doc.rights.write) {
          return doc.rights.write.groups.find((group) => group.id === this.currentUserGroupId) ||
              doc.rights.write.users.find((user) => user.id === this.currentUserId)
        } else {
          return false
        }
      })
    },
    fileSelectionSize () {
      return this.computedSelectedAttachedFiles.reduce((accSum, item) => {
        return item.type === 'file' ? accSum + item.size : accSum
      }, 0)
    },
    canSelectionBeExportedToPolyOffice () {
      return this.polyOfficeEnabled && this.computedSelectedAttachedFiles.every(doc => doc.type === 'file')
    },
    archiveAddRouteOptions () {
      return {
        params: {
          files: this.computedSelectedAttachedFiles,
        },
        query: {
          fileIds: this.computedSelectedAttachedFiles.map((document) => document.id).toString(),
          fromMnemo: this.mnemo,
        },
      }
    },
  },
  methods: {
    ...mapActions('polyOffice', [GET_EXPORT_IFRAME]),
    ...mapActions('documentsDownloadFiles', [OPEN_DOCUMENTS_DOWNLOAD_FILES]),
    deselectAllAttachedFiles () {
      if (this.selectionModel) {
        this.$emit('input', [])
      } else {
        this.$store.commit(`checklist/${RESET_SELECTED_ATTACHED_FILE}`)
      }
    },
    openSharePane () {
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: true,
        tab: 'share',
        documents: this.computedSelectedAttachedFiles,
        parent: this.currentTaskFolder,
      })
    },
    openMovePane () {
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: true,
        tab: 'move',
        documents: this.computedSelectedAttachedFiles,
        parent: this.currentTaskFolder,
      })
    },
    openDeleteDialog () {
      this.$store.commit(`checklist/${SET_DELETE_ATTACHED_FILES_DIALOG}`, {
        isOpen: true,
        filesToDelete: this.computedSelectedAttachedFiles,
      })
    },
    preparePolyOfficePayload () {
      const selectedFileIds = this.computedSelectedAttachedFiles.map(attachedFile => attachedFile.id)

      return new DocumentsExport(this.mnemo, selectedFileIds)
    },
    async polyOfficeOpenDialogHandler () {
      const payload = this.preparePolyOfficePayload()
      try {
        await this.GET_EXPORT_IFRAME({
          payload,
          successMessage: this.$t('project.checklist.dialogs.ChecklistTableTaskAttachedFilesDialogMultiSelectBar.polyOffice.exportSuccessful'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .ChecklistTableTaskAttachedFilesDialogMultiSelectBar {
    display: flex;
    align-items: center;
    padding: 0 20px;
    border: 1px solid var(--v-primary-base);
    border-radius: 8px;
    flex-wrap: wrap;
  }
</style>
