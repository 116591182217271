import { render, staticRenderFns } from "./Bible.vue?vue&type=template&id=201d1e28&scoped=true"
import script from "./Bible.vue?vue&type=script&lang=js"
export * from "./Bible.vue?vue&type=script&lang=js"
import style0 from "./Bible.vue?vue&type=style&index=0&id=201d1e28&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201d1e28",
  null
  
)

export default component.exports