<template>
  <v-container
    fluid
    class="pa-0"
    :class="{ 'px-1': $vuetify.breakpoint.mdAndDown }"
  >
    <v-row>
      <v-col>
        <h1 class="text-h1">{{ $t("project.views.Checklist.title") }}</h1>
        <p class="mt-3 mb-0 body-1 accent--text text--lighten-2">
          {{ $t("project.views.Checklist.subtitle") }}
          <AppInfoTooltip>
            <p>
              {{ $t("project.views.Checklist.alertText1") }}
            </p>
            <p class="mb-0">
              {{ $t("project.views.Checklist.alertText2") }}
            </p>
            <p class="mb-0">
              {{ $t("project.views.Checklist.alertText3") }}
            </p>
          </AppInfoTooltip>
        </p>
      </v-col>
    </v-row>
    <vue-affix-box :offset-top="headerTop">
      <v-row class="checklist-sticky">
        <v-col>
          <ChecklistHeader v-if="checklist && checklist.length > 0"
                          :mnemo="mnemo"
          />
        </v-col>
      </v-row>
      <v-row v-if="bulkActionsAreEnabled" class="checklist-sticky">
        <v-col>
          <ChecklistTableBulkActions />
        </v-col>
      </v-row>
    </vue-affix-box>
    <ChecklistFiltersActive/>
    <v-row class="ChecklistTable-container">
      <v-col>
        <!--
          If Double validation when deleting checklist items
          Delete elements then display the list of checklists
        -->
        <div v-if="deleteChecklistDoubleValidationPending" class="text-center mt-8">
          <AppLoader></AppLoader>
        </div>
        <ChecklistTable v-else :mnemo="mnemo"/>
      </v-col>
    </v-row>
    <ChecklistPane />
    <ChecklistTableTaskPane :mnemo="mnemo"/>
    <DocumentsDownloadFilesDialog
      v-if="documentsDownloadFilesDialogIsOpen"
      :fileType="downloadFilesDialogFileType"
      :mnemo="mnemo"
      @close="CLOSE_DOCUMENTS_DOWNLOAD_FILES"
    />
    <ChecklistDeleteAttachedFilesDialog :mnemo="mnemo"/>
    <ChecklistRenameAttachedFileDialog :mnemo="mnemo"/>
    <ChecklistShareTasksDialog v-if="shareTasksDialog.isOpen"
                               :mnemo="mnemo"
                               @close="onShareChecklistTasksDialogClose"
    />
    <SaveChecklistDialog v-if="saveChecklistDialogIsOpen"
                         :mnemo="mnemo"
                         @close="onSaveChecklistDialogClose"
    />
    <ImportChecklistDialog v-if="importChecklistDialogIsOpen"
                           :is-from-template-dialog="checklistTemplatesSelectedTab !== TAB_INDEX.UNSET"
                           @close="onImportChecklistDialogClose"
    />
    <ImportChecklistFromTemplateDialog v-if="importChecklistFromTemplateDialogIsOpen"
                                       @close="onImportChecklistFromTemplateDialogClose"
    />
    <ChecklistGlobalAttachedFilesDialog
      v-if="globalAttachedFilesDialogIsOpen"
      :mnemo="mnemo"
      @close="onGlobalAttachedFilesDialogClose"
    />
    <ChecklistFiltersPane :mnemo="mnemo"/>
  </v-container>
</template>

<script>
import VueAffixBox from 'vue-affix-box'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppInfoTooltip from '@/common/AppInfoTooltip'
import AppLoader from '@/common/AppLoader'
import { getRoomRouteType } from '@/common/utils/rooms'
import ChecklistFiltersActive from '@/project/checklist/ChecklistFiltersActive'
import ChecklistFiltersPane from '@/project/checklist/ChecklistFiltersPane'
import { TAB_INDEX } from '@/project/checklist/dialogs/enums.ts'
import ImportChecklistDialog from '@/project/checklist/dialogs/ImportChecklistDialog.vue'
import DocumentsDownloadFilesDialog from '@/project/documents/dialogs/DocumentsDownloadFilesDialog'
import { ROOM_MEMBERS_ROUTE_NAME } from '@/router'
import { ROOM_CHECKLIST_ROUTE_NAME, ROOM_CHECKLIST_DELETION_VALIDATION_ROUTE_NAME } from '@/router/index'
import { DELETE_CHECKLIST_DOUBLE_VALIDATION } from '@/store/modules/checklist/action_types'
import { CLOSE_DOCUMENTS_DOWNLOAD_FILES } from '@/store/modules/documents-download-files/action_types'
import { ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

import {
  CLOSE_SAVE_CHECKLIST_DIALOG,
  CLOSE_IMPORT_CHECKLIST_DIALOG,
  CLOSE_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG,
} from '../../store/modules/checklist/action_types'
import {
  RESET_CHECKLIST,
  SET_SHARE_TASKS_DIALOG,
  SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN,
} from '../../store/modules/checklist/mutation_types'
import ChecklistHeader from '../checklist/ChecklistHeader'
import ChecklistTable from '../checklist/ChecklistTable'
import ChecklistTableBulkActions from '../checklist/ChecklistTableBulkActions'
import ChecklistDeleteAttachedFilesDialog from '../checklist/dialogs/ChecklistDeleteAttachedFilesDialog'
import ChecklistGlobalAttachedFilesDialog from '../checklist/dialogs/ChecklistGlobalAttachedFilesDialog.vue'
import ChecklistRenameAttachedFileDialog from '../checklist/dialogs/ChecklistRenameAttachedFileDialog'
import ChecklistShareTasksDialog from '../checklist/dialogs/ChecklistShareTasksDialog'
import ImportChecklistFromTemplateDialog from '../checklist/dialogs/ImportChecklistFromTemplateDialog'
import SaveChecklistDialog from '../checklist/dialogs/SaveChecklistDialog'
import ChecklistPane from '../checklist/pane/ChecklistPane'
import ChecklistTableTaskPane from '../checklist/pane/ChecklistTableTaskPane'
import { FILE_TYPES } from '../documents/dialogs/DocumentsDownloadFilesDialog.vue'

export default {
  name: 'Checklist',
  components: {
    AppInfoTooltip,
    AppLoader,
    ChecklistFiltersActive,
    DocumentsDownloadFilesDialog,
    ChecklistDeleteAttachedFilesDialog,
    ChecklistFiltersPane,
    ChecklistHeader,
    ChecklistPane,
    ChecklistRenameAttachedFileDialog,
    ChecklistShareTasksDialog,
    ChecklistTable,
    ChecklistTableBulkActions,
    ChecklistTableTaskPane,
    ImportChecklistDialog,
    ImportChecklistFromTemplateDialog,
    ChecklistGlobalAttachedFilesDialog,
    SaveChecklistDialog,
    VueAffixBox,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  metaInfo () {
    return {
      title: this.$t('project.views.Checklist.metaTitle'),
    }
  },
  data () {
    return {
      TAB_INDEX,
      ROOM_CHECKLIST_ROUTE_NAME,
      ROOM_CHECKLIST_DELETION_VALIDATION_ROUTE_NAME,
    }
  },
  computed: {
    ...mapState('checklist', [
      'checklist',
      'shareTasksDialog',
      'saveChecklistDialogIsOpen',
      'importChecklistFromTemplateDialogIsOpen',
      'importChecklistDialogIsOpen',
      'checklistTemplatesSelectedTab',
      'deleteChecklistDoubleValidationPending',
      'globalAttachedFilesDialogIsOpen',
    ]),
    ...mapGetters('checklist', ['allSelectedChecklistItems']),
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapState('room', ['currentRoom']),
    ...mapState('documentsDownloadFiles',
      {
        documentsDownloadFilesDialogIsOpen: 'isOpen',
      },
    ),
    downloadFilesDialogFileType () {
      return FILE_TYPES.TODO
    },
    headerTop () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 64
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 76
      }
      return 72
    },
    bulkActionsAreEnabled () {
      return this.isCurrentUserPm && this.allSelectedChecklistItems.length > 0
    },
  },
  created () {
    if (this.currentRoom?.hideTodo) {
      this.$router.replace({ name: ROOM_MEMBERS_ROUTE_NAME, params: { roomType: getRoomRouteType(this.currentRoom) } })
    } else if (this.$route.name === ROOM_CHECKLIST_DELETION_VALIDATION_ROUTE_NAME) {
      const cleanParams = (data) => {
        return data ? data.split(',').filter(item => item).map(id => parseInt(id)) : []
      }

      const data = {
        tasks: cleanParams(this.$route.query.taskIds),
        titles: cleanParams(this.$route.query.titleIds),
      }

      this.deleteChecklistDoubleValidation(data)
    }
  },
  beforeDestroy () {
    this.RESET_CHECKLIST()
  },
  methods: {
    ...mapActions('documentsDownloadFiles', [CLOSE_DOCUMENTS_DOWNLOAD_FILES]),
    ...mapMutations('checklist', [RESET_CHECKLIST, SET_SHARE_TASKS_DIALOG, SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN]),
    ...mapActions('checklist', [
      CLOSE_SAVE_CHECKLIST_DIALOG,
      CLOSE_IMPORT_CHECKLIST_DIALOG,
      CLOSE_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG,
      DELETE_CHECKLIST_DOUBLE_VALIDATION,
    ]),
    ...mapMutations([ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_ERROR_SNACKBAR]),
    async deleteChecklistDoubleValidation (data) {
      const elements = data.tasks.concat(data.titles)
      try {
        await this.DELETE_CHECKLIST_DOUBLE_VALIDATION({
          mnemo: this.mnemo,
          data,
        })
        this.ENQUEUE_SUCCESS_SNACKBAR(this.$tc('project.checklist.dialogs.ChecklistTableDeleteSelectionDialog.deleteSelectionSuccess', elements.length))
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$tc('project.checklist.dialogs.ChecklistTableDeleteSelectionDialog.deleteSelectionError', elements.length))
      } finally {
        this.$router.replace({
          name: ROOM_CHECKLIST_ROUTE_NAME,
          params: {
            mnemo: this.mnemo,
            roomType: 'projects',
          },
        })
      }
    },
    onSaveChecklistDialogClose () {
      this.CLOSE_SAVE_CHECKLIST_DIALOG()
    },
    onImportChecklistDialogClose () {
      this.CLOSE_IMPORT_CHECKLIST_DIALOG()
    },
    onImportChecklistFromTemplateDialogClose () {
      this.CLOSE_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG()
    },
    onGlobalAttachedFilesDialogClose () {
      this.SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN(false)
    },
    onShareChecklistTasksDialogClose () {
      this.SET_SHARE_TASKS_DIALOG({
        isOpen: false,
        tasksToShare: [],
        parent: null,
      })
    },
  },
}
</script>

<style scoped lang="scss">
  .checklist-sticky{
    background-color: var(--v-originalGrey-lighten5);
  }
  .ChecklistTable-container {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      margin-left: -24px;
      margin-right: -24px;
    }
  }
</style>
