<template>
  <div>
    <v-expand-transition v-if="filesToUpload.length">
      <AppFileManager @cancel="cancelAllFilesDialogIsOpen = true">
        <template #header>
          <div class="d-flex align-center">
            <font-awesome-icon v-if="noError"
                               :icon="['far', 'check']"
                               class="success--text mr-3"
                               style="font-size: 20px;"
            ></font-awesome-icon>
            {{ $tc('common.app-file-upload.AppFileUpload.title', successFilders, { successCount: successFilders, totalCount: uploadQueue.length }) }}
          </div>
        </template>
        <template #content>
          <div class="AppFileUpload__fildersContainer">
            <template v-for="(filder, index) in filesToUpload">
              <component :is="filder.type === 'file' ? 'AppFileUploadFile' : 'AppFileUploadFolder'"
                         :key="`filder-${index}`"
                         :file="filder"
                         :folder="filder"
              />
            </template>
          </div>
        </template>
      </AppFileManager>
    </v-expand-transition>
    <RoomStorageExceededDialog />
    <CancelAllFilesDialog v-if="cancelAllFilesDialogIsOpen"
                          @close="cancelAllFilesDialogIsOpen = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppFileUploadFile from '@/common/app-file-upload/AppFileUploadFile'
import AppFileUploadFolder from '@/common/app-file-upload/AppFileUploadFolder'
import CancelAllFilesDialog from '@/common/app-file-upload/CancelAllFilesDialog'
import RoomStorageExceededDialog from '@/common/app-file-upload/RoomStorageExceededDialog'

import { uploadStatus } from './uploadStatus'
import AppFileManager from '../AppFileManager'
export default {
  name: 'AppFileUpload',
  components: {
    AppFileManager,
    AppFileUploadFile,
    AppFileUploadFolder,
    CancelAllFilesDialog,
    RoomStorageExceededDialog,
  },
  data () {
    return {
      cancelAllFilesDialogIsOpen: false,
    }
  },
  computed: {
    ...mapState('appUploadHandler', ['filesToUpload', 'uploadQueue']),
    successFilders () {
      return this.uploadQueue.filter(f => f.status === uploadStatus.SUCCESS).length
    },
    noError () {
      return this.uploadQueue.every(f => f.status === uploadStatus.SUCCESS || f.status === uploadStatus.CANCELED)
    },
  },
}
</script>

<style scoped lang="scss">
.AppFileUpload {
  &__fildersContainer {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
