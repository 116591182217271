export default [
  {
    key: 'personal-information',
    title: 'common.profileSection.personalInformation',
    icon: 'fa-user',
  },
  {
    key: 'password',
    title: 'common.profileSection.password',
    icon: 'fa-key',
  },
  {
    key: 'identity-checking',
    title: 'common.profileSection.identityChecking',
    icon: 'fa-id-card',
  },
  {
    key: 'settings',
    title: 'common.profileSection.settings',
    icon: 'fa-gear',
  },
]
