<template>
  <AppDialog :is-open="isDialogOpened"
             size="xl"
             :ok-text="$t('project.question-answer.QuestionAnswerImportDialog.import')"
             :ok-disabled="okDisabled"
             :ok-loading="importQuestionsPending"
             :cancel-disabled="importQuestionsPending"
             @ok="importFile"
             @cancel="closeDialog"
  >
    <template #title>
      {{ $t('project.question-answer.QuestionAnswerImportDialog.titleImportQuestionsAndAnswers') }}
    </template>
    <template #body>
      <!-- EXCEL FILE -->
      <ImportChecklistDialogExcelFile :templateLink="templateLink"
                                      :hasImportErrors="!!massInputError"
                                      :allowedExtensions="QA_IMPORT_ALLOWED_EXTENSIONS"
                                      isQuestionAnswerDialog
                                      @update-file="selectFile"
                                      @reset-import-errors="massInputError = null"
      >
      </ImportChecklistDialogExcelFile>

      <v-row v-if="massInputError" class="mt-2">
        <v-col>
          <div class="primary--text text-center">
            {{ $t('project.question-answer.QuestionAnswerImportDialog.error.massImport_' + massInputError) }}
            <template v-if="massInputError === 'ERR_INCORRECT_DATA'">
              <ul style="list-style-position: inside;" class="text-left">
                <li v-for="(error, i) in massInputErrorArray" :key="`errorLine-${i}`" class="py-1">
                  {{ $t('error.massImport_atLine')}} {{Number(i) + 2 }}
                  <ul style="list-style-position: inside;" class="ml-5">
                    <li v-for="(errorDetail, j) in error" :key="`error-${j}`">
                      {{ $t("error.massImport_" + errorDetail.errorCode) }}
                    </li>
                  </ul>
                </li>
              </ul>
            </template>
          </div>
        </v-col>
      </v-row>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ImportChecklistDialogExcelFile from '@/project/checklist/dialogs/ImportChecklistDialogExcelFile.vue'

import AppDialog from '../../common/dialogs/AppDialog'
import { QA_IMPORT_ALLOWED_EXTENSIONS } from '../../common/utils/files'
import { dialogMixin } from '../../mixins/dialogMixin'
import { QuestionAnswerActionTypes } from '../../store/modules/question-answer/action_types'

const MAX_FILE_SIZE = 10 * 1024 * 1024

export default {
  name: 'QuestionAnswerImportDialog',
  components: { AppDialog, ImportChecklistDialogExcelFile },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      QA_IMPORT_ALLOWED_EXTENSIONS,
      selectedFile: null,
      massInputError: null,
      massInputErrorArray: [],
    }
  },
  computed: {
    ...mapState('questionAnswer', ['importQuestionsPending']),
    templateLink () {
      return `${process.env.VUE_APP_API_URL}/resources/massQAImportTemplate`
    },
    okDisabled () {
      return this.importQuestionsPending || !this.selectedFile || !!this.massInputError
    },
  },
  methods: {
    ...mapActions('questionAnswer', [QuestionAnswerActionTypes.IMPORT_QUESTIONS]),
    selectFile (event) {
      this.massInputError = null
      this.selectedFile = event
      // Check for file size
      if (this.selectedFile?.size > MAX_FILE_SIZE) {
        this.massInputError = 'ERR_FILE_TOO_LARGE'
      }
    },
    async importFile () {
      try {
        const formData = new FormData()
        formData.append('file', this.selectedFile, this.selectedFile.name)
        await this.IMPORT_QUESTIONS({
          mnemo: this.mnemo,
          formData,
        })
        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          message: this.$t('project.question-answer.QuestionAnswerImportDialog.importSuccess'),
        })
        this.closeDialog()
      } catch (error) {
        if (error.response?.data) {
          const { data: errorData, errorCode } = error.response.data
          switch (errorCode) {
            case 'ERR_INCORRECT_DATA': {
              this.massInputError = errorCode
              this.massInputErrorArray = errorData
              break
            }
            case 'ERR_FILE_TOO_LARGE': {
              this.massInputError = errorCode
              this.massInputErrorArray = errorData
              break
            }
            case 'ERR_FILE_WRONG_TYPE': {
              this.massInputError = errorCode
              this.massInputErrorArray = errorData
              break
            }
            case 'ERR_FILE_EMPTY': {
              this.massInputError = errorCode
              this.massInputErrorArray = errorData
              break
            }
            case 'ERR_CANNOT_BE_PROCESSED': {
              this.massInputError = errorCode
              this.massInputErrorArray = errorData
              break
            }
            case 'ERR_FILE_WRONG_FORMAT': {
              this.massInputError = errorCode
              this.massInputErrorArray = errorData
              break
            }
          }
        } else {
          this.$store.commit('enqueueSnackbar', {
            color: 'error',
            message: this.$t('project.question-answer.QuestionAnswerImportDialog.error.genericError'),
          })
        }
      }
    },
  },
}
</script>
