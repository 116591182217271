<template>
  <AppCard class="h-100 pa-2">
    <v-row justify="space-between" align="center" no-gutters>
      <v-col cols="auto">
        <v-img src="/img/icons/folder-zip.svg"
               width="40"
        />
      </v-col>
      <v-col class="text-h3 mx-4">
        <AppTooltip top>
          <template #activator="{attrs, on}">
            <div class="multiLinesTextTruncate"
                 v-bind="attrs"
                 v-on="on"
            >
              {{ bible.name }}
            </div>
          </template>
          <span>{{ bible.name }}</span>
        </AppTooltip>
      </v-col>
      <v-col v-if="bible.generatedAt" cols="auto">
        <ArchiveAddAccess :showText="false" :routeOptions="archiveAddRouteOptions" />
      </v-col>
      <v-col v-if="polyOfficeEnabled && bible.size !== null" cols="auto">
        <PolyOfficeIconButtonExport
          :fileSize="bible.size"
          @polyOfficeOpenDialog="polyOfficeOpenDialogHandler"
        />
      </v-col>
      <v-col cols="auto">
        <BibleDeleteDialog :bible="bible" :mnemo="mnemo"/>
      </v-col>
      <v-col v-if="imanageEnabledAndLogged" cols="auto">
         <AppTooltip top>
          <template #activator="{attrs, on}">
            <div v-bind="attrs"
                 v-on="on"
            >
              <AppButton :disabled="exportBibleToImanagePending" icon @click="$emit('export-to-imanage', bible.id)">
                <v-img src="/img/iManage_logo.png" width="25" />
              </AppButton>
            </div>
          </template>
          {{$t('project.bibles.Bible.exportToImanage')}}
        </AppTooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <template v-if="bible.deleteAt">
          <Alert v-if="expirationDate"
                 type="warning"
                 no-icon
                 :dismissible="false"
          >
              <span v-html="$t('project.bibles.Bible.daysBeforeDeletion', {date: expirationDate})"></span>
          </Alert>
        </template>
        <template v-else>
          <v-skeleton-loader type="text"/>
        </template>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <div style="color: #9e9e9e">{{ $t('project.bibles.Bible.creationDate') }}</div>
        <template v-if="bible.generatedAt">
          <div>{{ creationDate }}</div>
        </template>
        <template v-else>
          <v-skeleton-loader type="text"/>
        </template>
      </v-col>
      <v-col>
        <div style="color: #9e9e9e">{{ $t('project.bibles.Bible.filesCount') }}</div>
        <template v-if="bible.nbItems !== null && bible.size !== null">
          <div>{{$tc('project.bibles.Bible.bibleInformation', bible.nbItems, {files: bible.nbItems})}} - <app-filesize-display v-model="bible.size"/></div>
        </template>
        <template v-else>
          <v-skeleton-loader type="text"/>
        </template>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <AppDownloadFile v-if="bible.status !== 'processing'" ref="appDownloadBibleRef" :link="bibleURL" :isNewTab="false">
          <template #default="{ submitForm }">
            <AppButton color="primary"
                       @click="submitForm"
            >
              {{ $t('common.download') }}
            </AppButton>
          </template>
        </AppDownloadFile>
      </v-col>
      <v-col cols="auto">
        <BiblesSharePane v-if="bible.status !== 'processing'"
                         :bible="bible"
                         :mnemo="mnemo"
                         @share-success="$emit('share-success')"
        />
      </v-col>
    </v-row>
  </AppCard>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import ArchiveAddAccess from '@/common/archives/ArchiveAddAccess.vue'
import { BiblesExport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeIconButtonExport from '@/common/polyOffice/PolyOfficeIconButtonExport.vue'
import { GET_EXPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import BibleDeleteDialog from './BibleDeleteDialog'
import BiblesSharePane from './BiblesSharePane'
import Alert from '../../common/alerts/Alert'
import AppCard from '../../common/AppCard'
import AppDownloadFile from '../../common/AppDownloadFile'
import AppTooltip from '../../common/AppTooltip'
import AppButton from '../../common/buttons/AppButton'
import { ISOToShortenedDate, ISOToShortDate } from '../../common/utils/dates'

export default {
  name: 'Bible',
  components: {
    PolyOfficeIconButtonExport,
    BiblesSharePane,
    AppButton,
    AppDownloadFile,
    BibleDeleteDialog,
    Alert,
    AppCard,
    AppTooltip,
    ArchiveAddAccess,
  },
  props: {
    bible: {
      type: Object,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
    autoDownload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('bibles', ['exportBibleToImanagePending']),
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    creationDate () {
      return ISOToShortenedDate(this.bible.generatedAt)
    },
    expirationDate () {
      return ISOToShortDate(this.bible.deleteAt)
    },
    bibleURL () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/bible/${this.bible.id}`
    },
    archiveAddRouteOptions () {
      return {
        params: {
          bibles: [this.bible],
        },
        query: {
          bibleIds: this.bible.id,
          fromMnemo: this.mnemo,
        },
      }
    },
  },
  mounted () {
    if (this.autoDownload) {
      this.$refs.appDownloadBibleRef.submitForm()
    }
  },
  methods: {
    ...mapActions('polyOffice', [
      GET_EXPORT_IFRAME,
    ]),
    async polyOfficeOpenDialogHandler () {
      const payload = new BiblesExport(
        this.mnemo,
        [this.bible.id],
      )
      try {
        await this.GET_EXPORT_IFRAME({
          payload,
          successMessage: this.$t('project.bibles.Bible.polyOffice.exportSuccessful'),
        })
      } catch (error) {
        console.error(error)
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('common.polyOfficeErrors.ERR_FILE_TOO_LARGE', 1),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.multiLinesTextTruncate {
  overflow: hidden;
  position: relative;
  line-height: 20px;
  max-height: 40px;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
}
</style>
