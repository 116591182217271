<template>
  <div>
    <template v-if="isCurrentUserPm || currentUserRights.canUploadVersions">
      <v-row>
        <v-col class="text-h4">
          {{ $t('project.documents.pane.DocumentsPaneVersions.addVersion') }}
        </v-col>
      </v-row>
      <v-row v-if="documentsTablePane.documents.length > 0">
        <v-col>
          <Alert type="warning"
                 :dismissible="false"
                 no-icon
          >
          <span class="text-h4">
            {{ $t('project.documents.pane.DocumentsPaneVersions.versionNumber') }}
            {{ documentsTablePane.documents[0].nbVersions }}
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-right']"></font-awesome-icon>
            {{ documentsTablePane.documents[0].nbVersions + 1 }}
          </span>
          </Alert>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-file-input v-model="newVersionFile"
                        prepend-icon="fal fa-paperclip"
                        dense
                        color="blue darken-2"
                        style="cursor:pointer;"
                        hide-details
                        outlined
                        :label="$t('project.documents.pane.DocumentsPaneVersions.newVersionFileLabel')"
          ></v-file-input>
        </v-col>
      </v-row>
      <template v-if="imanageEnabledAndLogged">
        <v-row>
          <v-col class="body-1 font-weight-semi-bold text-center">
            {{ $t('project.documents.pane.DocumentsPaneVersions.or') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <AppButton color="tertiary"
                       dark
                       @click="openIManageFilesPicker"
            >
              <v-img src="/img/iManage_logo.png" width="25" class="mr-2" />
              {{ $t('project.documents.pane.DocumentsPaneVersions.importFromImanage') }}
            </AppButton>
          </v-col>
        </v-row>
        <template v-if="newVersionFileFromImanage">
          <v-row>
            <v-col class="text-center">
              {{$t('project.documents.pane.DocumentsPaneVersions.newVersionFileFromImanage')}}
              <span class="font-weight-semi-bold d-inline-flex align-center">
                {{ newVersionFileFromImanage.name ? `${newVersionFileFromImanage.name}.${newVersionFileFromImanage.extension}` : newVersionFileFromImanage}}
                <AppButton icon
                           class="ml-1"
                           @click="newVersionFileFromImanage = null"
                >
                  <font-awesome-icon :icon="['fas', 'trash']"></font-awesome-icon>
                </AppButton>
              </span>
            </v-col>
          </v-row>
        </template>
      </template>
      <v-row>
        <v-col>
          <v-switch v-model="cloneRightsFromPrevious"
                    color="primary"
                    class="mt-0"
                    hide-details
          >
            <template #label>
              <v-row style="flex-direction: column;" align="start" class="ml-1">
              <v-row>
                <v-col>
                  {{ $t('project.documents.pane.DocumentsPaneVersions.cloneRightsFromPrevious') }}
                </v-col>
              </v-row>
              <v-row class="mt-n3">
                <v-col>
                  <RightsDisplayButton v-if="cloneRightsFromPrevious && documentsTablePane.documentVersions.length > 0 && isCurrentUserPm && documentsTablePane.documentVersions[0].rights"
                                      :rights="documentsTablePane.documentVersions[0].rights"
                                      :isClickable="false"
                  />
                  <RightsDisplayButton v-else-if="cloneRightsFromPrevious && isCurrentUserPm && documentsTablePane.documents[0].rights"
                                      :rights="documentsTablePane.documents[0].rights"
                                      :isClickable="false"
                  />
                  <span v-if="!cloneRightsFromPrevious" class="DocumentPaneVersionNoCloneText">{{ $t('project.documents.pane.DocumentsPaneVersions.cloneRightsFromPreviousOff') }}</span>
                </v-col>
              </v-row>
              </v-row>
            </template>
          </v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-switch v-model="sendNotif"
                    :label="$t('project.documents.pane.DocumentsPaneVersions.sendNotif')"
                    color="primary"
                    class="mt-0"
                    hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <VisualTextEditor v-if="sendNotif"
                            v-model="notifyMessage"
                            counter="footer"
                            :error-messages="notifyMessageErrors"
                            :max-length="notifyMessageMaxLength"
                            :placeholder="$t('project.documents.pane.DocumentsPaneVersions.optionalMessageWithCopy')"
          />
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <AppButton color="primary"
                   outlined
                   block
                   @click="close"
        >
          {{$t('common.cancel')}}
        </AppButton>
      </v-col>
      <v-col>
        <AppButton color="primary"
                   block
                   :loading="versionsLoading"
                   :disabled="confirmDisabled"
                   @click="onAddVersionClick"
        >
          {{$t('project.documents.pane.DocumentsPaneVersions.replace')}}
        </AppButton>
      </v-col>
    </v-row>
    <IManageFilePickerDialog :is-open.sync="iManageFilesPickerDialogIsOpen"
                             :params="{ singleFile: true }"
                             @files="onImanageFiles"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import Alert from '@/common/alerts/Alert'
import AppButton from '@/common/buttons/AppButton'
import IManageFilePickerDialog from '@/common/imanage/IManageFilePickerDialog'
import RightsDisplayButton from '@/common/users/RightsDisplayButton.vue'
import { ISOToShortenedDate } from '@/common/utils/dates'
import { escapeHtml } from '@/common/utils/strings'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import {
  ADD_VERSION,
  CLOSE_TABLE_DOCUMENTS_PANE,
  GET_DOCUMENTS_BY_ID,
  POST_VERSION_FROM_IMANAGE,
} from '@/store/modules/documents/action_types'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

const NOTIFY_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'DocumentsPaneVersions',
  components: { IManageFilePickerDialog, Alert, AppButton, VisualTextEditor, RightsDisplayButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      newVersionFile: null,
      // iManage usually returns an object with id and fileName props but sometimes it can be a plain id as string
      newVersionFileFromImanage: null,
      cloneRightsFromPrevious: true,
      sendNotif: true,
      notifyMessage: '',
      notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
      iManageFilesPickerDialogIsOpen: false,
    }
  },
  computed: {
    ...mapState('documents', ['documentsTablePane', 'documents', 'versionsLoading']),
    ...mapGetters('room', ['isCurrentUserPm', 'currentUserRights']),
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    notifyMessageLength () {
      return escapeHtml(this.notifyMessage).length
    },
    notifyMessageIsTooLong () {
      return this.notifyMessageLength > this.notifyMessageMaxLength
    },
    notifyMessageErrors () {
      const errors = []

      if (this.notifyMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    confirmDisabled () {
      return (!this.newVersionFile && !this.newVersionFileFromImanage) || this.notifyMessageIsTooLong
    },
  },
  methods: {
    ...mapActions('documents', [CLOSE_TABLE_DOCUMENTS_PANE, ADD_VERSION, GET_DOCUMENTS_BY_ID, POST_VERSION_FROM_IMANAGE]),
    fileUploadDate (date) {
      return ISOToShortenedDate(date)
    },
    prepareAddVersion () {
      const formData = new FormData()
      formData.append('fileSelect', this.newVersionFile)
      formData.append('formCloneRightsFromPrevious', this.cloneRightsFromPrevious)
      formData.append('formToggleNotif', this.sendNotif)
      formData.append('formNotifMessage', this.notifyMessage)
      this.ADD_VERSION(formData)
    },
    close () {
      this.CLOSE_TABLE_DOCUMENTS_PANE()
      this.newVersionFile = null
      this.newVersionFileFromImanage = null
      this.cloneRightsFromPrevious = true
      this.sendNotif = true
      this.notifyMessage = ''
      this.iManageFilesPickerDialogIsOpen = false
    },
    openIManageFilesPicker () {
      this.iManageFilesPickerDialogIsOpen = true
    },
    onImanageFiles (files) {
      const file = files.output_data?.selected[0]
      if (file) {
        this.newVersionFileFromImanage = {
          id: file.id,
          name: file.name,
          extension: file.extension,
        }
      } else {
        this.newVersionFileFromImanage = files.selected
      }
    },
    async preparePostVersionFromIManage (targetId) {
      try {
        await this.POST_VERSION_FROM_IMANAGE({
          mnemo: this.mnemo,
          data: {
            fileId: this.documentsTablePane.documents[0].id,
            targetId: targetId,
            formCloneRightsFromPrevious: this.cloneRightsFromPrevious,
            formToggleNotif: this.sendNotif,
            formNotifMessage: this.notifyMessage,
          },
        })
        this.GET_DOCUMENTS_BY_ID({ queryObject: { markRecent: true } })
        this.CLOSE_TABLE_DOCUMENTS_PANE()
      } catch (error) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.pane.DocumentsPaneVersions.postVersionFromImanageError'),
        })
      }
    },
    onAddVersionClick () {
      if (this.newVersionFile) {
        this.prepareAddVersion()
      } else if (this.newVersionFileFromImanage) {
        this.preparePostVersionFromIManage(this.newVersionFileFromImanage.id || this.newVersionFileFromImanage)
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .DocumentPaneVersionNoCloneText{
    font-style: italic;
    font-size:12px;
  }
  ::v-deep .v-file-input {
    .fa-paperclip {
      color: #333;
      font-size: 20px;
    }
    .v-label {
      font-size: 14px;
    }
  }
  .versionName:hover {
    text-decoration: underline;
  }
</style>
