<template>
  <div class="mt-4 mx-auto">
    <div v-if="!loading" class="envelope-users-selector-container">
      <div class="header-row"
           :style="gridStyles"
      >
        <span></span>
        <!-- Spacer -->
        <span v-if="hasSignersOrder"
              class="text-center font-weight-bold"
        >
          {{$t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.signatoriesOrder")}}
        </span>
        <span v-if="hasWitnesses"
              class="text-center font-weight-bold"
        >
          {{$t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.witnesses")}}
        </span>
        <template v-if="isForMultiEnvelope">
          <span class="text-center font-weight-bold">
            {{$t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.commonSigners")}}
            <AppHelpTooltip :text="$t('common.users.envelopeUsersSelector.EnvelopeUsersSelector.commonSignersTooltip')"/>
          </span>
          <span class="text-center font-weight-bold">
            <span class="required-asterisk">{{$t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.uniqueSigners")}}</span>
            <AppHelpTooltip :text="$t('common.users.envelopeUsersSelector.EnvelopeUsersSelector.uniqueSignersTooltip')"/>
          </span>
        </template>
        <template v-else>
          <span class="text-center font-weight-bold">
          {{$t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.signers")}}
        </span>
        </template>
        <span class="text-center font-weight-bold">
          {{$t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.validators")}}
          <AppHelpTooltip :text="$t('common.users.envelopeUsersSelector.EnvelopeUsersSelector.validatorsTooltip')"/>
        </span>
        <span class="text-center font-weight-bold">
          {{$t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.recipients")}}
          <AppHelpTooltip :text="$t('common.users.envelopeUsersSelector.EnvelopeUsersSelector.recipientsTooltip')"/>
        </span>
        <span class="text-center font-weight-bold">
          {{$t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.observers")}}
        </span>
      </div>
      <div v-if="displayedGroups.length > 0">
        <EnvelopeUsersSelectorGroup
          v-for="group in displayedGroups"
          :key="group.id"
          :disabled="!!searchQuery"
          :group="group"
          :step="step"
          :has-witnesses="hasWitnesses"
          :has-signers-order="hasSignersOrder"
          :grid-styles="gridStyles"
          :is-for-multi-envelope="isForMultiEnvelope"
        />
      </div>
      <div
        v-else
        style="max-width: 575px"
        class="text-center mx-auto mt-8 mb-4"
      >
        <app-icon icon-name="magnifying-glass" icon-weight="far" class="d-inline-block mb-2" />
        <app-text variant="large-bold">
          {{ $t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.noResultOnSearch") }}
        </app-text>
        <app-text variant="small-regular">
          {{ $t("common.users.envelopeUsersSelector.EnvelopeUsersSelector.noResultOnSearchHint") }}
        </app-text>
      </div>
    </div>
    <div v-else class="text-center overflow-hidden">
      <v-progress-circular
        style="width: 64px; height: 64px"
        indeterminate
        size="64"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import { flatMap, reduce } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'

import AppHelpTooltip from '@/common/AppHelpTooltip.vue'

import EnvelopeUsersSelectorGroup from './EnvelopeUsersSelectorGroup.vue'

const { mapState: mapGroupsState } = createNamespacedHelpers('groups')
export default {
  name: 'EnvelopeUsersSelector',
  components: {
    AppHelpTooltip,
    EnvelopeUsersSelectorGroup,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    isForMultiEnvelope: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGroupsState(['loading', 'groups']),
    displayedGroups () {
      const groupMembersByGroupFn = members => reduce(members, (groups, member) => {
        const groupIndex = groups.findIndex(group => group.id === member.group.id)
        if (groupIndex >= 0) {
          groups[groupIndex].members.push(member)
        } else {
          groups.push({ ...member.group, members: [member] })
        }
        return groups
      }, [])

      if (this.searchQuery) {
        const loweredSearchQuery = this.searchQuery.toLowerCase()
        const memberMatchSearchQueryFn = member =>
          member.fullName.toLowerCase().includes(loweredSearchQuery) ||
          member.company.toLowerCase().includes(loweredSearchQuery) ||
          member.email.toLowerCase().includes(loweredSearchQuery) ||
          member.group.name.toLowerCase().includes(loweredSearchQuery)

        const matchingMembers = this.roomMembers.filter(memberMatchSearchQueryFn)
        return groupMembersByGroupFn(matchingMembers)
      }

      return this.groups
    },
    roomMembers () {
      const memberMapFn = (member, group) => ({
        id: member.id,
        fullName: member.fullName,
        company: member.company,
        email: member.email,
        group: {
          id: group.id,
          name: group.name,
          isPm: group.isPm,
        },
      })
      const groupMapFn = group => group.members.map(
        member => memberMapFn(member, group),
      )

      return flatMap(this.groups, groupMapFn)
    },
    gridStyles () {
      let cols = this.isForMultiEnvelope ? 5 : 4
      if (this.hasSignersOrder) { cols++ }
      if (this.hasWitnesses) { cols++ }
      return { gridTemplateColumns: `auto repeat(${cols}, 120px)` }
    },
    hasSignersOrder () {
      return this.step.signers.length > 1
    },
    hasWitnesses () {
      return this.step.hasOwnProperty('witnesses') && this.step.witnesses?.length > 0
    },
  },
  mounted () {
    if (this.displayedGroups) {
      const pmGroup = this.displayedGroups.find(group => group.isPm)
      if (pmGroup) {
        for (const user of pmGroup.members) {
          if (this.step.observers.indexOf(user.id) < 0) {
            this.step.observers.push(user.id)
          }
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.envelope-users-selector-container {
  min-width: 900px;
  max-height: 300px;
  overflow-y: auto;
}

.header-row {
  display: grid;
  grid-gap: 10px;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  padding-bottom: 10px;
}
</style>
