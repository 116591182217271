import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import Vue from 'vue'
import VueChatScroll from 'vue-chat-scroll'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

import {
  navigatingOnAvailableSubdomain,
  getSubDomainFromCurrentUrl,
  SUBDOMAIN_COOKIE_KEY,
} from '@/common/utils/url'
import { USER_TOKEN_STORAGE_KEY } from '@/common/utils/users'
import { globallyRegisterDesignSystemComponents } from '@/design-system/register-components'

import App from './App.vue'
import i18n from './i18n'
import './plugins/axios'
import { duotoneLibrary } from './plugins/font-awesome-libraries/duotone-library'
import { lightLibrary } from './plugins/font-awesome-libraries/light-library'
import { regularLibrary } from './plugins/font-awesome-libraries/regular-library'
import { solidLibrary } from './plugins/font-awesome-libraries/solid-library'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { GET_SUBSCRIPTION_BRANDING } from './store/modules/subscription/action_types'
import { SET_SUBSCRIPTION_BRANDING } from './store/modules/subscription/mutation_types'

library.add(
  duotoneLibrary,
  solidLibrary,
  regularLibrary,
  lightLibrary,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// Design system components
globallyRegisterDesignSystemComponents()

Vue.use(VueMeta)
Vue.use(VueChatScroll)
Vue.use(VueCookies)

// TODO: May lazy-load this later
Vue.use(VueTelInputVuetify, {
  vuetify,
})

Vue.config.productionTip = false

const token = localStorage.getItem(USER_TOKEN_STORAGE_KEY)
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  // We could also have custom environments for local testing,
  // That way we could filter each local environment if multiple DSN isn't viable
  environment: process.env.VUE_APP_SENTRY_ENVIRONNEMENT || 'local',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'oqtopus.com', /^\//],
    }),
  ],
  tracesSampleRate: 0.5,
  release: `closd-front@${process.env.VUE_APP_VERSION}`,
})

// Both of those env vars come from vue.config.js because they're computed
console.info(`closd-front version ${process.env.VUE_APP_VERSION} rev ${process.env.VUE_APP_REVISION}`)

Sentry.setTags({
  version: process.env.VUE_APP_VERSION,
  revision: process.env.VUE_APP_REVISION,
})

async function getSubscriptionBranding (subdomain: string) {
  await store.dispatch(`subscription/${GET_SUBSCRIPTION_BRANDING}`, subdomain)
}

let app
if (!app) {
  // handle page reloads
  const createApp = () => {
    app = new Vue({
      el: '#app',
      name: 'Root',
      router,
      vuetify,
      store,
      i18n,
      render: (h) => h(App),
    })
  }

  const applySubscriptionBrandingColor = () => {
    const color = store.getters['subscription/getSubscriptionBrandingColor']
    if (color) {
      vuetify.framework.theme.themes.light.primary = color
    } else {
      vuetify.framework.theme.themes.light.primary = vuetify.framework.theme.themes.light.brand
    }
  }

  /**
   * Vuetify theme color is not correctly customized in App.vue
   * So do it before intializing vuetify to avoid rendering problems
   */
  const subdomain = (navigatingOnAvailableSubdomain() && getSubDomainFromCurrentUrl()) ||
                    Vue.$cookies.get(SUBDOMAIN_COOKIE_KEY)
  if (subdomain) {
    // Displays loader for subcription branding loading
    const subscriptionBrandingLoaderId = document.getElementById('pre-init-loader')
    if (subscriptionBrandingLoaderId) {
      subscriptionBrandingLoaderId.style.display = 'flex'
    }
    getSubscriptionBranding(subdomain).then(() => {
      applySubscriptionBrandingColor()
    }).finally(() => {
      if (subscriptionBrandingLoaderId) {
        subscriptionBrandingLoaderId.style.display = 'none'
      }
      createApp()
    })
  } else {
    // If the user doesn't connect via a subdomain and has customization enabled
    const customizationEnabled = store.getters['user/customizationEnabled']
    if (customizationEnabled) {
      store.commit(`subscription/${SET_SUBSCRIPTION_BRANDING}`, store.state.user?.profile?.subscription)
      applySubscriptionBrandingColor()
    }

    createApp()
  }
}
