var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{style:({ 'min-width': _vm.$vuetify.breakpoint.mobile ? '320px' : '100%' }),attrs:{"fixed":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.navigationDrawerProfile),callback:function ($$v) {_vm.navigationDrawerProfile=$$v},expression:"navigationDrawerProfile"}},[_c('v-list',[_c('v-list-item',{staticClass:"mb-2 px-6"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h5 font-weight-bold"},[_vm._v(_vm._s(_vm.profile.firstName)+" "+_vm._s(_vm.profile.lastName))])],1)],1),_c('v-divider',{staticClass:"mb-6"}),_vm._l((_vm.profileSections),function(section){return _c('v-list-item',{key:section.id,staticClass:"px-6",attrs:{"link":""},on:{"click":function($event){return _vm.selectProfileSection(section)}}},[_c('v-list-item-icon',[_c('v-icon',{class:{
            'primary--text': _vm.path === `/profile/${section.key}`,
          },attrs:{"small":""},domProps:{"textContent":_vm._s(
            _vm.path === `/profile/${section.key}`
              ? section.icon
              : 'fal ' + section.icon
          )}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
            'primary--text': _vm.path === `/profile/${section.key}`,
          }},[_vm._v(" "+_vm._s(_vm.$t(section.title))+" ")])],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }