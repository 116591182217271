<template>
  <AppDialog :is-open="isDialogOpen"
             :ok-text="$t('common.delete')"
             :ok-loading="isPending"
             @ok="validateFildersDeletion"
             @cancel="close"
  >
    <template #title>
      <template v-if="homeLayout">
        <div class="font-weight-bold">
          {{ $t('project.documents.dialogs.DocumentsDeleteDialog.deleteProjectsFolder', { name: projectsFolderToDelete.name }) }}
        </div>
      </template>
      <template v-else>
        {{ $tc('project.documents.dialogs.DocumentsDeleteDialog.deleteDocuments', documents.length) }}
      </template>
    </template>
    <template #body>
      <template v-if="homeLayout">
        <i18n path="project.documents.dialogs.DocumentsDeleteDialog.deleteProjectsFolderText" tag="p">
          <template v-slot:name>
            <span class="font-weight-bold">{{ projectsFolderToDelete.name }}</span>
          </template>
          <template v-slot:parentName>
            <span class="font-weight-bold">{{ parentFolderName }}</span>
          </template>
        </i18n>
      </template>
      <template v-else>
        <template v-if="singleFile">
          {{$t('project.documents.dialogs.DocumentsDeleteDialog.deleteSingleFile')}}
        </template>
        <template v-if="singleFolder">
          {{$t('project.documents.dialogs.DocumentsDeleteDialog.deleteSingleFolder')}}
        </template>
        <template v-if="multipleDeletion">
          {{$t('project.documents.dialogs.DocumentsDeleteDialog.deleteMultiple')}}
        </template>
      </template>
    </template>
  </AppDialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog'
import { LOAD_FOLDERS_TREE } from '@/store/modules/documents/action_types'
import { CLOSE_DOCUMENTS_DELETION_DIALOG, DELETE_FILDERS } from '@/store/modules/documents-delete/action_types'
import { LOAD_PROJECTS_FOLDERS_TREE, GET_PROJECTS_FOLDER_BY_ID } from '@/store/modules/projects-folders/action_types'
import { DELETE_PROJECTS_FOLDER } from '@/store/modules/projects-folders-delete/action_types'
import {
  SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN,
  SET_PROJECTS_FOLDER_TO_DELETE,
} from '@/store/modules/projects-folders-delete/mutation_types'
import { GET_ROOMS_PROGRESSIVELY } from '@/store/modules/rooms/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default {
  name: 'DocumentsDeleteDialog',
  components: { AppDialog },
  computed: {
    ...mapState(['homeLayout']),
    ...mapState('projectsFolders', ['currentProjectsFolder']),
    ...mapState('documentsDelete', ['isOpen', 'loading', 'documents']),
    ...mapState('projectsFoldersDelete', ['projectsFolderDeleteDialogIsOpen', 'projectsFolderDeletePending', 'projectsFolderToDelete']),
    isDialogOpen () {
      return this.homeLayout ? this.projectsFolderDeleteDialogIsOpen : this.isOpen
    },
    isPending () {
      return this.homeLayout ? this.projectsFolderDeletePending : this.loading
    },
    parentFolderName () {
      return this.currentProjectsFolder.name || this.$t('projects.ProjectsHeader.myProjects')
    },
    multipleDeletion () {
      return this.documents.length > 1
    },
    singleFile () {
      return this.documents.length === 1 && this.documents[0].type === 'file'
    },
    singleFolder () {
      return this.documents.length === 1 && this.documents[0].type === 'folder'
    },
  },
  methods: {
    ...mapActions('documents', [LOAD_FOLDERS_TREE]),
    ...mapActions('rooms', [GET_ROOMS_PROGRESSIVELY]),
    ...mapActions('documentsDelete', [CLOSE_DOCUMENTS_DELETION_DIALOG, DELETE_FILDERS]),
    ...mapMutations('projectsFoldersDelete', [SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN, SET_PROJECTS_FOLDER_TO_DELETE]),
    ...mapActions('projectsFoldersDelete', [DELETE_PROJECTS_FOLDER]),
    ...mapActions('projectsFolders', [LOAD_PROJECTS_FOLDERS_TREE, GET_PROJECTS_FOLDER_BY_ID]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    async validateFildersDeletion () {
      if (this.homeLayout) {
        try {
          await this.DELETE_PROJECTS_FOLDER(this.projectsFolderToDelete.id)

          if (this.currentProjectsFolder.id) {
            try {
              this.GET_PROJECTS_FOLDER_BY_ID({
                folderId: this.currentProjectsFolder.id,
              })
            } catch (error) {
              this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsNewFolderDialog.getFolderError', { name: this.currentProjectsFolder.name }))
            }
          } else {
            try {
              this.LOAD_PROJECTS_FOLDERS_TREE()
            } catch (error) {
              this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.ProjectsHeader.getProjectsFoldersTreeError'))
            }
            this.GET_ROOMS_PROGRESSIVELY()
          }

          this.SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN(false)
        } catch (error) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.dialogs.DocumentsDeleteDialog.deleteFolderError'))
        }
      } else {
        const needFoldersTreeRefreshAfterDeletion = this.documents.some(document => document.type === 'folder')

        await this.DELETE_FILDERS()

        if (needFoldersTreeRefreshAfterDeletion) {
          await this.LOAD_FOLDERS_TREE()
        }
      }
    },
    close () {
      if (this.homeLayout) {
        this.SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN(false)
        this.SET_PROJECTS_FOLDER_TO_DELETE({})
      } else {
        this.CLOSE_DOCUMENTS_DELETION_DIALOG()
      }
    },
  },
}
</script>
