export const state = {
  showIdentityCheckLandingModal: false,
  showIdentityCheckModal: false,
  getSelfUserPending: false,
  profile: {},
  userCanCreateRooms: null,
  updateProfilePending: false,
  updateProfileError: null,
  updateLanguagePending: false,
  updateLanguageError: null,
  updateNotificationPreferencesPending: false,
  updateNotificationPreferencesError: null,
  currentNotificationPreferencesPending: false,
  currentNotificationPreferences: {},
  currentNotificationPreferencesError: null,
  createNotificationPreferencesPending: false,
  createNotificationPreferences: null,
  createNotificationPreferencesError: null,
  updatePasswordPending: false,
  updatePasswordError: null,
  deleteNotificationPreferencesPending: false,
  deleteNotificationPreferencesError: null,
  getSelfContactListPending: false,
  selfContactListFromSubscription: [],
  selfContactListFromSubscriptionPayingUsers: [],
  selfContactList: [],
  idCheckPending: false,
  idCheck: null,
  idCheckError: null,
  idCheckStatePending: false,
  idCheckState: null,
  idCheckStateError: null,
  selectedIdDocumentType: null,
  activityPollingPending: false,
  activityPollingError: null,
  zendeskAuthPending: false,
}
