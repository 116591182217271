<template>
  <v-chip color="primary"
          small
          outlined
  >
    <!-- No translation here, validated by product team -->
    <slot>
      New
    </slot>
  </v-chip>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'AppNewLabel',
})
</script>

<style lang="scss">
  .v-chip {
    user-select: none;
  }
</style>
