<template>
  <AppDialog :is-open="deleteAttachedFilesDialog.isOpen"
             :ok-text="$t('common.delete')"
             :ok-loading="deleteFildersPending"
             :ok-disabled="deleteFildersPending"
             :cancel-disabled="deleteFildersPending"
             @cancel="resetAndClose"
             @ok="prepareDeleteFilders"
  >
    <template #title>{{ $tc('project.checklist.dialogs.ChecklistDeleteAttachedFilesDialog.title', deleteAttachedFilesDialog.filesToDelete.length) }}</template>
    <template #body>{{ $tc('project.checklist.dialogs.ChecklistDeleteAttachedFilesDialog.text', deleteAttachedFilesDialog.filesToDelete.length) }}</template>
  </AppDialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AppDialog from '../../../common/dialogs/AppDialog'
import { DELETE_FILDERS, GET_GLOBAL_TASK_FOLDER, GET_TASK_FOLDER } from '../../../store/modules/checklist/action_types'
import {
  RESET_SELECTED_ATTACHED_FILE,
  SET_DELETE_ATTACHED_FILES_DIALOG,
} from '../../../store/modules/checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'
export default {
  name: 'ChecklistDeleteAttachedFilesDialog',
  components: { AppDialog },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('checklist', ['deleteAttachedFilesDialog', 'deleteFildersPending', 'currentTaskFolder']),
  },
  methods: {
    ...mapActions('checklist', [DELETE_FILDERS, GET_TASK_FOLDER, GET_GLOBAL_TASK_FOLDER]),
    resetAndClose () {
      this.$store.commit(`checklist/${RESET_SELECTED_ATTACHED_FILE}`)
      this.$store.commit(`checklist/${SET_DELETE_ATTACHED_FILES_DIALOG}`, {
        isOpen: false,
        filesToDelete: [],
      })
    },
    async prepareDeleteFilders () {
      try {
        await this.DELETE_FILDERS({
          mnemo: this.mnemo,
          data: {
            fileIds: this.deleteAttachedFilesDialog.filesToDelete.map(item => { return item.id }),
          },
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$tc('project.checklist.dialogs.ChecklistDeleteAttachedFilesDialog.deleteFildersSuccess', this.deleteAttachedFilesDialog.filesToDelete.length),
        })
        // Global delete operation
        if (this.$store.state.checklist.globalAttachedFilesDialogIsOpen) {
          this.GET_GLOBAL_TASK_FOLDER(this.mnemo)
        } else {
          this.GET_TASK_FOLDER({
            mnemo: this.mnemo,
            folderId: this.deleteAttachedFilesDialog.filesToDelete[0]?.parent?.id,
          })
        }
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$tc('project.checklist.dialogs.ChecklistDeleteAttachedFilesDialog.deleteFildersError', this.deleteAttachedFilesDialog.filesToDelete.length),
        })
      } finally {
        this.resetAndClose()
      }
    },
  },
}
</script>
