<template>
  <Pane v-model="documentsTablePane.isOpen"
        temporary
        class="DocumentsTablePane"
        :tabs="tabs"
        :current-tab.sync="tab"
        @input="onInput"
  >
    <template #fixed-header>
      <v-row>
        <v-col class="text-h4">{{$tc('project.documents.pane.DocumentsTablePane.selectedDocuments', documentsTablePane.documents.length)}}</v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-chip v-for="document in documentsTablePane.documents"
                  :key="`document-${document.type}-${document.id}`"
                  class="Document-chip mr-2 mb-2"
                  outlined
                  close
                  close-icon="fal fa-xmark"
                  @click:close="removeDocument(document.id)"
          >
            <ClosdFildersIcon :document="document" :size="document.type === 'folder' ? 24 : 22"/>
            <AppTooltip top>
              <template #activator="{ on, attrs }">
                <div class="Document-name"
                     v-bind="attrs"
                     v-on="on"
                >
                  <span v-if="numberingEnabled && document.numbering" class="pr-1">{{document.numbering}}</span>
                  {{document.basename || document.name}}
                </div>
              </template>
              <span v-if="numberingEnabled && document.numbering" class="pr-1">{{document.numbering}}</span>
              <span>{{document.basename || document.name}}</span>
            </AppTooltip>
            <v-chip v-if="document.version > 1"
                    color="secondary"
                    x-small
                    class="caption mx-2"
                    label
            >
              {{ `v.${document.version}` }}
            </v-chip>
          </v-chip>
        </v-col>
      </v-row>
    </template>
    <template #content-share>
      <v-row>
        <v-col>
          <DocumentShare :documents="documentsTablePane.documents"
                         :force-pm-selection="true"
          />
        </v-col>
      </v-row>
    </template>
    <template #content-move>
      <v-row>
        <v-col>
          <!-- This component has to share its lifecycle with the pane open state -->
          <!-- because we need to fully reset its state when closing -->
          <!-- See V2-1160 for one of the things that happens when not doing that. -->
          <DocumentsPaneMove v-if="documentsTablePane.isOpen"/>
        </v-col>
      </v-row>
    </template>
    <template #content-duplicate>
      <v-row>
        <v-col>
          <DocumentsPaneDuplicate v-if="documentsTablePane.isOpen"/>
        </v-col>
      </v-row>
    </template>
    <template #content-versions>
      <v-row>
        <v-col>
          <DocumentsPaneVersions :mnemo="mnemo"/>
        </v-col>
      </v-row>
    </template>
  </Pane>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import DocumentsPaneDuplicate from './DocumentsPaneDuplicate'
import DocumentsPaneMove from './DocumentsPaneMove'
import DocumentsPaneVersions from './DocumentsPaneVersions'
import AppTooltip from '../../../common/AppTooltip'
import DocumentShare from '../../../common/document-share/DocumentShare'
import ClosdFildersIcon from '../../../common/filders/ClosdFildersIcon'
import Pane from '../../../common/Pane'
import {
  CHANGE_TABLE_DOCUMENTS_PANE_TAB,
  CLOSE_TABLE_DOCUMENTS_PANE,
  DESELECT_ALL_DOCUMENTS,
  UNSELECT_DOCUMENT,
} from '../../../store/modules/documents/action_types'

export default {
  name: 'DocumentsTablePane',
  components: {
    Pane,
    DocumentsPaneDuplicate,
    DocumentsPaneVersions,
    DocumentsPaneMove,
    DocumentShare,
    ClosdFildersIcon,
    AppTooltip,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('documents', ['documentsTablePane', 'numberingEnabled']),
    ...mapGetters('room', ['isCurrentUserPm']),
    ...mapGetters('user', ['currentUserId']),
    ...mapGetters('groups', ['currentUserGroupId']),
    tab: {
      get () {
        return this.documentsTablePane.tab
      },
      set (val) {
        this.CHANGE_TABLE_DOCUMENTS_PANE_TAB(val)
      },
    },
    tabs () {
      const tabs = []
      if (this.isCurrentUserPm) {
        tabs.push(
          {
            title: this.$t('project.documents.pane.DocumentsTablePane.share'),
            id: 'share',
          },
        )
      }
      if (this.canWriteAllFiles) {
        tabs.push({
          title: this.$t('project.documents.pane.DocumentsTablePane.move'),
          id: 'move',
        })
      }
      if (this.documentsTablePane.documents.length === 1 && this.documentsTablePane.documents[0].type === 'file') {
        tabs.push({
          title: this.$t('project.documents.pane.DocumentsTablePane.duplicate'),
          id: 'duplicate',
        }, {
          title: this.$t('project.documents.pane.DocumentsTablePane.versions'),
          id: 'versions',
        })
      }
      return tabs
    },
    canWriteAllFiles () {
      return this.documentsTablePane?.documents.every(doc => {
        if (doc.canWrite) {
          return true
        } else {
          return doc.rights?.write?.groups?.find((group) => group.id === this.currentUserGroupId) ||
            doc.rights?.write?.users?.find((user) => user.id === this.currentUserId)
        }
      })
    },
  },
  methods: {
    ...mapActions('documents', [
      CHANGE_TABLE_DOCUMENTS_PANE_TAB,
      CLOSE_TABLE_DOCUMENTS_PANE,
      DESELECT_ALL_DOCUMENTS,
      UNSELECT_DOCUMENT,
    ]),
    removeDocument (documentId) {
      this.UNSELECT_DOCUMENT(documentId)
    },
    onInput (value) {
      if (!value) {
        this.DESELECT_ALL_DOCUMENTS()
        this.CLOSE_TABLE_DOCUMENTS_PANE()
      }
    },
  },
}
</script>

<style lang="scss">
  .Document-name {
    max-width: 350px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Document-chip {
    .v-chip__close:hover {
      color: var(--v-error-base) !important;
    }
  }
</style>
