import i18n from '@/i18n'

export const ROOT_FOLDER = {
  id: 0,
  name: i18n.t('common.documents.rootFolder'),
} as const

export const RECENT_FOLDER = {
  id: 'recentFolder',
  name: i18n.t('common.documents.recentFolder'),
} as const
