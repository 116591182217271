export const ADD_SELECTED_ATTACHED_FILE = 'ADD_SELECTED_ATTACHED_FILE'
export const ADD_SELECTED_OBSERVERS_GROUPS = 'ADD_SELECTED_OBSERVERS_GROUPS'
export const ADD_SELECTED_OBSERVERS_MEMBERS = 'ADD_SELECTED_OBSERVERS_MEMBERS'
export const ADD_SELECTED_RESPONSIBLE_GROUPS = 'ADD_SELECTED_RESPONSIBLE_GROUPS'
export const ADD_SELECTED_RESPONSIBLE_MEMBERS = 'ADD_SELECTED_RESPONSIBLE_MEMBERS'
export const ADD_SELECTED_VALIDATORS_GROUPS = 'ADD_SELECTED_VALIDATORS_GROUPS'
export const ADD_SELECTED_VALIDATORS_MEMBERS = 'ADD_SELECTED_VALIDATORS_MEMBERS'
export const REMOVE_DELETE_TASK_COMMENT_PENDING = 'REMOVE_DELETE_TASK_COMMENT_PENDING'
export const REMOVE_FROM_EXPAND_TASK_AFTER_DELETE = 'REMOVE_FROM_EXPAND_TASK_AFTER_DELETE'
export const REMOVE_POST_TASK_COMMENT_PENDING = 'REMOVE_POST_TASK_COMMENT_PENDING'
export const REMOVE_REFRESH_TASK_PENDING = 'REMOVE_REFRESH_TASK_PENDING'
export const REMOVE_SELECTED_ATTACHED_FILE = 'REMOVE_SELECTED_ATTACHED_FILE'
export const REMOVE_SELECTED_OBSERVERS_GROUPS = 'REMOVE_SELECTED_OBSERVERS_GROUPS'
export const REMOVE_SELECTED_OBSERVERS_MEMBERS = 'REMOVE_SELECTED_OBSERVERS_MEMBERS'
export const REMOVE_SELECTED_RESPONSIBLE_GROUPS = 'REMOVE_SELECTED_RESPONSIBLE_GROUPS'
export const REMOVE_SELECTED_RESPONSIBLE_MEMBERS = 'REMOVE_SELECTED_RESPONSIBLE_MEMBERS'
export const REMOVE_SELECTED_VALIDATORS_GROUPS = 'REMOVE_SELECTED_VALIDATORS_GROUPS'
export const REMOVE_SELECTED_VALIDATORS_MEMBERS = 'REMOVE_SELECTED_VALIDATORS_MEMBERS'
export const REMOVE_TASK_COMMENTS_PENDING = 'REMOVE_TASK_COMMENTS_PENDING'
export const REMOVE_TASK_FOLDER_PENDING = 'REMOVE_TASK_FOLDER_PENDING'
export const RESET_ATTACHED_FILE_VERSIONS = 'RESET_ATTACHED_FILE_VERSIONS'
export const RESET_CHECKLIST = 'RESET_CHECKLIST'
export const RESET_ROLE_PICKER = 'RESET_ROLE_PICKER'
export const RESET_SELECTED_ATTACHED_FILE = 'RESET_SELECTED_ATTACHED_FILE'
export const RESET_TASK_FOLDER = 'RESET_TASK_FOLDER'
export const SET_ATTACHED_FILE_VERSIONS = 'SET_ATTACHED_FILE_VERSIONS'
export const SET_ATTACHED_FILE_VERSIONS_ERROR = 'SET_ATTACHED_FILE_VERSIONS_ERROR'
export const SET_ATTACHED_FILE_VERSIONS_PENDING = 'SET_ATTACHED_FILE_VERSIONS_PENDING'
export const SET_CHECKLIST = 'SET_CHECKLIST'
export const SET_CHECKLIST_ERROR = 'SET_CHECKLIST_ERROR'
export const SET_CHECKLIST_PENDING = 'SET_CHECKLIST_PENDING'
export const SET_CONTACT_RESPONSIBLE_ERROR = 'SET_CONTACT_RESPONSIBLE_ERROR'
export const SET_CONTACT_RESPONSIBLE_PENDING = 'SET_CONTACT_RESPONSIBLE_PENDING'
export const SET_CREATE_TASK_ERROR = 'SET_CREATE_TASK_ERROR'
export const SET_CREATE_TASK_PENDING = 'SET_CREATE_TASK_PENDING'
export const SET_CREATE_TITLE_ERROR = 'SET_CREATE_TITLE_ERROR'
export const SET_CREATE_TITLE_PENDING = 'SET_CREATE_TITLE_PENDING'
export const SET_CURRENT_TITLE_EDIT = 'SET_CURRENT_TITLE_EDIT'
export const SET_CURRENT_TITLE_EDIT_ERROR = 'SET_CURRENT_TITLE_EDIT_ERROR'
export const SET_CURRENT_TITLE_EDIT_PENDING = 'SET_CURRENT_TITLE_EDIT_PENDING'
export const SET_DELETE_ATTACHED_FILES_DIALOG = 'SET_DELETE_ATTACHED_FILES_DIALOG'
export const SET_DELETE_FILDERS_ERROR = 'SET_DELETE_FILDERS_ERROR'
export const SET_DELETE_FILDERS_PENDING = 'SET_DELETE_FILDERS_PENDING'
export const SET_DELETE_SAVED_CHECKLIST_PENDING = 'SET_DELETE_SAVED_CHECKLIST_PENDING'
export const SET_DELETE_SAVED_CHECKLIST_ERROR = 'SET_DELETE_SAVED_CHECKLIST_ERROR'
export const SET_SHARE_SAVED_CHECKLIST_PENDING = 'SET_SHARE_SAVED_CHECKLIST_PENDING'
export const SET_SHARE_SAVED_CHECKLIST_ERROR = 'SET_SHARE_SAVED_CHECKLIST_ERROR'
export const SET_RENAME_SAVED_CHECKLIST_PENDING = 'SET_RENAME_SAVED_CHECKLIST_PENDING'
export const SET_RENAME_SAVED_CHECKLIST_ERROR = 'SET_RENAME_SAVED_CHECKLIST_ERROR'
export const SET_DELETE_TABLE_SELECTION_ERROR = 'SET_DELETE_TABLE_SELECTION_ERROR'
export const SET_DELETE_CHECKLIST_DOUBLE_VALIDATION_PENDING = 'SET_DELETE_CHECKLIST_DOUBLE_VALIDATION_PENDING'
export const SET_DELETE_TABLE_SELECTION_PENDING = 'SET_DELETE_TABLE_SELECTION_PENDING'
export const SET_DELETE_TASK_COMMENT_ERROR = 'SET_DELETE_TASK_COMMENT_ERROR'
export const SET_DELETE_TASK_COMMENT_PENDING = 'SET_DELETE_TASK_COMMENT_PENDING'
export const SET_DELETE_TASK_ERROR = 'SET_DELETE_TASK_ERROR'
export const SET_DELETE_TASK_MODAL = 'SET_DELETE_TASK_MODAL'
export const SET_DELETE_TASK_PENDING = 'SET_DELETE_TASK_PENDING'
export const SET_DELETE_TITLE_ERROR = 'SET_DELETE_TITLE_ERROR'
export const SET_DELETE_TITLE_PENDING = 'SET_DELETE_TITLE_PENDING'
export const SET_DUPLICATE_TASK_ERROR = 'SET_DUPLICATE_TASK_ERROR'
export const SET_DUPLICATE_TASK_PENDING = 'SET_DUPLICATE_TASK_PENDING'
export const SET_EDIT_TASK = 'SET_EDIT_TASK'
export const SET_EDIT_TASK_ERROR = 'SET_EDIT_TASK_ERROR'
export const SET_EDIT_TASK_PENDING = 'SET_EDIT_TASK_PENDING'
export const SET_EDIT_TITLE = 'SET_EDIT_TITLE'
export const SET_EDIT_TITLE_ERROR = 'SET_EDIT_TITLE_ERROR'
export const SET_EDIT_TITLE_PENDING = 'SET_EDIT_TITLE_PENDING'
export const SET_TITLES_EXPAND_STATE = 'SET_TITLES_EXPAND_STATE'
export const SET_FILTER_COMPLETION_DATE = 'SET_FILTER_COMPLETION_DATE'
export const SET_FILTER_SELECTED_RESPONSIBLE_PARTIES = 'SET_FILTER_SELECTED_RESPONSIBLE_PARTIES'
export const SET_FILTER_SELECTED_STATUSES = 'SET_FILTER_SELECTED_STATUSES'
export const SET_FILTER_SELECTED_VALIDATORS = 'SET_FILTER_SELECTED_VALIDATORS'
export const SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN = 'SET_IMPORT_CHECKLIST_DIALOG_IS_OPEN'
export const SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN = 'SET_IMPORT_CHECKLIST_FROM_TEMPLATE_DIALOG_IS_OPEN'
export const SET_CHECKLISTS_TEMPLATES_SELECTED_TAB = 'SET_CHECKLISTS_TEMPLATES_SELECTED_TAB'
export const SET_IMPORT_CHECKLIST_FROM_CLOSD_ERROR = 'SET_IMPORT_CHECKLIST_FROM_CLOSD_ERROR'
export const SET_IMPORT_CHECKLIST_FROM_CLOSD_PENDING = 'SET_IMPORT_CHECKLIST_FROM_CLOSD_PENDING'
export const SET_IMPORT_CHECKLIST_FROM_EXCEL_PENDING = 'SET_IMPORT_CHECKLIST_FROM_EXCEL_PENDING'
export const SET_LAST_TASK_ADDED = 'SET_LAST_TASK_ADDED'
export const SET_LAST_TITLE_ADDED = 'SET_LAST_TITLE_ADDED'
export const SET_MOVE_FILDERS_ERROR = 'SET_MOVE_FILDERS_ERROR'
export const SET_MOVE_FILDERS_PENDING = 'SET_MOVE_FILDERS_PENDING'
export const SET_PANE = 'SET_PANE'
export const SET_PANE_TAB = 'SET_PANE_TAB'
export const SET_PARENT_CHILDREN = 'SET_PARENT_CHILDREN'
export const SET_POST_ATTACHED_FILE_VERSIONS_ERROR = 'SET_POST_ATTACHED_FILE_VERSIONS_ERROR'
export const SET_POST_ATTACHED_FILE_VERSIONS_PENDING = 'SET_POST_ATTACHED_FILE_VERSIONS_PENDING'
export const SET_POST_MOVE_TASK_ERROR = 'SET_POST_MOVE_TASK_ERROR'
export const SET_POST_MOVE_TASK_PENDING = 'SET_POST_MOVE_TASK_PENDING'
export const SET_POST_STATUS_ERROR = 'SET_POST_STATUS_ERROR'
export const SET_POST_STATUS_PENDING = 'SET_POST_STATUS_PENDING'
export const SET_POST_TASK_COMMENT_ERROR = 'SET_POST_TASK_COMMENT_ERROR'
export const SET_POST_TASK_COMMENT_PENDING = 'SET_POST_TASK_COMMENT_PENDING'
export const SET_REFRESH_CHECKLIST = 'SET_REFRESH_CHECKLIST'
export const SET_REFRESH_CHECKLIST_ERROR = 'SET_REFRESH_CHECKLIST_ERROR'
export const SET_REFRESH_CHECKLIST_PENDING = 'SET_REFRESH_CHECKLIST_PENDING'
export const SET_REFRESH_TASK = 'SET_REFRESH_TASK'
export const SET_REFRESH_TASK_ERROR = 'SET_REFRESH_TASK_ERROR'
export const SET_REFRESH_TASK_PENDING = 'SET_REFRESH_TASK_PENDING'
export const SET_RENAME_ATTACHED_FILE_DIALOG = 'SET_RENAME_ATTACHED_FILE_DIALOG'
export const SET_RENAME_ATTACHED_FILE_ERROR = 'SET_RENAME_ATTACHED_FILE_ERROR'
export const SET_RENAME_ATTACHED_FILE_PENDING = 'SET_RENAME_ATTACHED_FILE_PENDING'
export const SET_SAVED_CHECKLISTS = 'SET_SAVED_CHECKLISTS'
export const SET_SAVED_CHECKLISTS_ERROR = 'SET_SAVED_CHECKLISTS_ERROR'
export const SET_SAVED_CHECKLISTS_PENDING = 'SET_SAVED_CHECKLISTS_PENDING'
export const SET_SELF_SAVED_CHECKLISTS_PENDING = 'SET_SELF_SAVED_CHECKLISTS_PENDING'
export const SET_SELF_SAVED_CHECKLISTS = 'SET_SELF_SAVED_CHECKLISTS'
export const SET_SELECTED_SELF_SAVED_CHECKLIST = 'SET_SELECTED_SELF_SAVED_CHECKLIST'
export const SET_SELECTED_SAVED_CHECKLIST = 'SET_SELECTED_SAVED_CHECKLIST'
export const SET_SAVED_CHECKLIST_PREVIEW_PENDING = 'SET_SAVED_CHECKLIST_PREVIEW_PENDING'
export const SET_SAVED_CHECKLIST_PREVIEW = 'SET_SAVED_CHECKLIST_PREVIEW'
export const SET_SAVED_CHECKLIST_PREVIEW_ERROR = 'SET_SAVED_CHECKLIST_PREVIEW_ERROR'
export const SET_LEXISNEXIS_CHECKLISTS_PENDING = 'SET_LEXISNEXIS_CHECKLISTS_PENDING'
export const SET_LEXISNEXIS_CHECKLISTS = 'SET_LEXISNEXIS_CHECKLISTS'
export const SET_LEXISNEXIS_CHECKLISTS_ERROR = 'SET_LEXISNEXIS_CHECKLISTS_ERROR'
export const SET_SELECTED_LEXISNEXIS_CHECKLIST = 'SET_SELECTED_LEXISNEXIS_CHECKLIST'
export const SET_LEXISNEXIS_CHECKLIST_PREVIEW_PENDING = 'SET_LEXISNEXIS_CHECKLIST_PREVIEW_PENDING'
export const SET_LEXISNEXIS_CHECKLIST_PREVIEW = 'SET_LEXISNEXIS_CHECKLIST_PREVIEW'
export const SET_LEXISNEXIS_CHECKLIST_PREVIEW_ERROR = 'SET_LEXISNEXIS_CHECKLIST_PREVIEW_ERROR'
export const SET_SAVE_CHECKLIST_DIALOG_IS_OPEN = 'SET_SAVE_CHECKLIST_DIALOG_IS_OPEN'
export const SET_SAVE_CHECKLIST_ERROR = 'SET_SAVE_CHECKLIST_ERROR'
export const SET_SAVE_CHECKLIST_PENDING = 'SET_SAVE_CHECKLIST_PENDING'
export const SET_UPDATE_CHECKLIST_PENDING = 'SET_UPDATE_CHECKLIST_PENDING'
export const SET_SELECTED_ATTACHED_FILE = 'SET_SELECTED_ATTACHED_FILE'
export const SET_SELECTED_TASKS = 'SET_SELECTED_TASKS'
export const SET_SELECTED_TITLES = 'SET_SELECTED_TITLES'
export const SET_SHARE_ATTACHED_FILES_ERROR = 'SET_SHARE_ATTACHED_FILES_ERROR'
export const SET_SHARE_ATTACHED_FILES_PENDING = 'SET_SHARE_ATTACHED_FILES_PENDING'
export const SET_SHARE_CHECKLIST_BY_EMAIL_PENDING = 'SET_SHARE_CHECKLIST_BY_EMAIL_PENDING'
export const SET_SHARE_TASKS_DIALOG = 'SET_SHARE_TASKS_DIALOG'
export const SET_SHARE_TASKS_ERROR = 'SET_SHARE_TASKS_ERROR'
export const SET_SHARE_TASKS_PENDING = 'SET_SHARE_TASKS_PENDING'
export const SET_TABLE_TASK_PANE = 'SET_TABLE_TASK_PANE'
export const SET_TABLE_TASK_PANE_TAB = 'SET_TABLE_TASK_PANE_TAB'
export const SET_TASK_COMMENTS_ERROR = 'SET_TASK_COMMENTS_ERROR'
export const SET_TASK_COMMENTS_PENDING = 'SET_TASK_COMMENTS_PENDING'
export const SET_TASK_FOLDER = 'SET_TASK_FOLDER'
export const SET_TASK_FOLDER_ERROR = 'SET_TASK_FOLDER_ERROR'
export const SET_TASK_FOLDER_PENDING = 'SET_TASK_FOLDER_PENDING'
export const SET_TITLE_INDENTATION_ERROR = 'SET_TITLE_INDENTATION_ERROR'
export const SET_TITLE_INDENTATION_PENDING = 'SET_TITLE_INDENTATION_PENDING'
export const TOGGLE_SELECTED_OBSERVERS_GROUPS = 'TOGGLE_SELECTED_OBSERVERS_GROUPS'
export const TOGGLE_SELECTED_OBSERVERS_MEMBERS = 'TOGGLE_SELECTED_OBSERVERS_MEMBERS'
export const TOGGLE_SELECTED_RESPONSIBLE_GROUPS = 'TOGGLE_SELECTED_RESPONSIBLE_GROUPS'
export const TOGGLE_SELECTED_RESPONSIBLE_MEMBERS = 'TOGGLE_SELECTED_RESPONSIBLE_MEMBERS'
export const TOGGLE_SELECTED_VALIDATORS_GROUPS = 'TOGGLE_SELECTED_VALIDATORS_GROUPS'
export const TOGGLE_SELECTED_VALIDATORS_MEMBERS = 'TOGGLE_SELECTED_VALIDATORS_MEMBERS'
export const SET_TASK_STATUS_CATEGORIES = 'SET_TASK_STATUS_CATEGORIES'
export const SET_TASK_STATUS_CATEGORIES_PENDING = 'SET_TASK_STATUS_CATEGORIES_PENDING'
export const SET_TASK_STATUS_CATEGORIES_ERROR = 'SET_TASK_STATUS_CATEGORIES_ERROR'
// Filters
export const REMOVE_CHECKLIST_FILTER = 'REMOVE_CHECKLIST_FILTER'
export const SET_CHECKLIST_FILTERS = 'SET_CHECKLIST_FILTERS'
export const SET_FILTERS_PANE_IS_OPEN = 'SET_FILTERS_PANE_IS_OPEN'
// Search
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const SET_SEARCH_QUERY_PENDING = 'SET_SEARCH_QUERY_PENDING'
export const SET_GLOBAL_TASK_FOLDER_PENDING = 'SET_GLOBAL_TASK_FOLDER_PENDING'
export const SET_GLOBAL_TASK_FOLDER = 'SET_GLOBAL_TASK_FOLDER'
export const SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN = 'SET_GLOBAL_ATTACHED_FILES_DIALOG_IS_OPEN'
export const SET_DOWNLOAD_TODO_FILES_PENDING = 'SET_DOWNLOAD_TODO_FILES_PENDING'
