import { ROOM_TYPES } from '@/common/utils/roomTypes'
import { getProvidersMaxSize } from '@/common/utils/signingChecklist'

export const getters = {
  roomName: state => { return state.currentRoom?.name },
  roomMnemo: state => { return state.currentRoom?.mnemo },
  roomProviders: state => state.currentRoom?.providers,
  maxSignableSize: state => getProvidersMaxSize(state.currentRoom?.providers),
  isCurrentUserPm: state => { return !!state.currentRoom?.isPM },
  currentUserRights: state => { return state.currentRoom?.userRights },
  isDataRoom: state => { return state.currentRoom?.type === ROOM_TYPES.DATAROOM || state.currentRoom?.type === ROOM_TYPES.DATAROOM_UNLIMITED },
  totalStorage: (state) => {
    const storage = state.roomStats?.storage.capacity
    if (typeof (storage) === 'number') {
      if (storage > 1024) {
        return {
          storage: (storage / 1024).toFixed(2),
          unit: 'unit.Gb',
        }
      } else {
        return {
          storage: storage.toFixed(2),
          unit: 'unit.Mb',
        }
      }
    }
    // No storage shouldn't happen, value shouldn't be falsy. Just setting it back to 0
    // This is done for consistency with usedStorage but a totalStorage of 0 shouldn't happen
    // And there should always be storage...
    return {
      storage: 0,
      unit: 'unit.Mb',
    }
  },
  usedStorage: (state) => {
    const storage = state.roomStats?.storage.current
    if (typeof (storage) === 'number') {
      if (storage > 1024) {
        return {
          storage: (storage / 1024).toFixed(2),
          unit: 'unit.Gb',
        }
      } else {
        return {
          storage: storage.toFixed(2),
          unit: 'unit.Mb',
        }
      }
    }
    // No storage shouldn't happen, value shouldn't be falsy. Just setting it back to 0
    // It was previously just erroring the render and rendering nothing.
    // We could do that again, without the error
    return {
      storage: 0,
      unit: 'unit.Mb',
    }
  },
  storagePercent (state) {
    const storage = state.roomStats?.storage

    if (storage) {
      return storage.capacity !== 0 ? (storage.current * 100) / storage.capacity : 0
    }

    return 0
  },
  getNotificationsParams (state) {
    const params = {}
    // Search
    if (state.roomNotificationsSearchQuery) {
      params.filters = {}
      params.filters.user_full_name = state.roomNotificationsSearchQuery
    }
    // Sort
    if (state.roomNotificationsSort.sortBy) {
      params.sortBy = state.roomNotificationsSort.sortBy
      params.sortDir = state.roomNotificationsSort.sortDir
    }

    return params
  },
  advancedSignature (state) {
    return state.roomStats?.advanced || null
  },
  simpleSignatures (state) {
    return state.roomStats?.simple || null
  },
  qualifiedSignatures (state) {
    return state.roomStats?.qualified || null
  },
  handwrittenSignatures (state) {
    return state.roomStats?.handwritten || null
  },
  archivedDocuments (state) {
    return state.roomStats?.archives.count || null
  },
  files (state) {
    return state.roomStats?.files.count || null
  },
  storage (state) {
    return state.roomStats?.storage || null
  },
  isBillingByEnvelope (state) {
    return !!state.roomStats?.isBillingByEnvelope
  },
  isPmOfRoomSubscription (state, getters, rootState) {
    return getters.isCurrentUserPm && rootState.user?.profile?.subscription?.id === state.currentRoom?.subscription?.id
  },
  isWitnessEnabled (state) {
    return state.currentRoom?.witnessEnabled
  },
  isWopiEnabled (state) {
    return state.currentRoom?.wopiOfficeEnabled
  },
  isNotarityEnabled (state) {
    return state.currentRoom?.notarityEnabled
  },
  allowValidationChecklistItemDeletion (state) {
    return state.currentRoom?.allowValidationChecklistItemDeletion
  },
}
