import { merge } from 'lodash-es'

import { FAILED_ID_CHECKS_LIMIT, removeIdCheckFromMissingInfo } from '@/common/utils/users'

import {
  GET_USER,
  UPDATE_PROFILE,
  UPDATE_LANGUAGE,
  UPDATE_NOTIFICATION_PREFERENCES,
  UPDATE_NOTIFICATION_MUTE_EMAILS,
  GET_CURRENT_NOTIFICATION_PREFERENCES,
  CREATE_NOTIFICATION_PREFERENCES,
  UPDATE_PASSWORD,
  DELETE_NOTIFICATION_PREFERENCES,
  GET_SELF_CONTACT_LIST,
  POST_ID_CHECK,
  GET_ID_CHECK_STATE,
  GET_ACTIVITY_POLLING,
  GET_ZENDESK_AUTH,
} from './action_types'
import {
  SET_UPDATE_PROFILE_ERROR,
  SET_UPDATE_PROFILE_PENDING,
  SET_UPDATE_LANGUAGE_PENDING,
  SET_UPDATE_LANGUAGE_ERROR,
  SET_UPDATE_NOTIFICATION_PREFERENCES_PENDING,
  SET_UPDATE_NOTIFICATION_PREFERENCES_ERROR,
  SET_CURRENT_NOTIFICATION_PREFERENCES,
  SET_CURRENT_NOTIFICATION_PREFERENCES_PENDING,
  SET_CURRENT_NOTIFICATION_PREFERENCES_ERROR,
  SET_CREATE_NOTIFICATION_PREFERENCES_PENDING,
  SET_CREATE_NOTIFICATION_PREFERENCES_ERROR,
  SET_UPDATE_PASSWORD_PENDING,
  SET_UPDATE_PASSWORD_ERROR,
  SET_DELETE_NOTIFICATION_PREFERENCES_PENDING,
  SET_DELETE_NOTIFICATION_PREFERENCES_ERROR,
  SET_GET_SELF_USER_PENDING,
  SET_SELF_CONTACT_LIST,
  SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION,
  SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION_PAYING_USERS,
  SET_SELF_CONTACT_LIST_PENDING,
  SET_ID_CHECK,
  SET_ID_CHECK_PENDING,
  SET_ID_CHECK_ERROR,
  SET_USER_PROFILE,
  SET_ID_CHECK_STATE,
  SET_ID_CHECK_STATE_PENDING,
  SET_ID_CHECK_STATE_ERROR,
  SET_ACTIVITY_POLLING_PENDING,
  SET_ACTIVITY_POLLING_ERROR,
  SET_GET_ZENDESK_AUTH_PENDING,
} from './mutation_types'
import userService from '../../../services/user.service'
export const actions = {
  async [GET_ACTIVITY_POLLING] ({ commit }) {
    commit(SET_ACTIVITY_POLLING_PENDING, true)
    try {
      await userService.getActivityPolling()
    } catch (error) {
      commit(SET_ACTIVITY_POLLING_ERROR, error)
      // There shouldn't be anything else normally
      if (error.request?.status !== 401) {
        throw error
      }
    } finally {
      commit(SET_ACTIVITY_POLLING_PENDING, false)
    }
  },
  async [GET_ID_CHECK_STATE] ({ commit, state }) {
    commit(SET_ID_CHECK_STATE_PENDING, true)
    try {
      const { data } = await userService.getIdCheck()
      if (data.hasValidIdCheck) {
        removeIdCheckFromMissingInfo(state.profile)
      } else {
        if (Array.isArray(state.profile.missingInfoToSign)) {
          if (!state.profile.missingInfoToSign.includes('idCheck')) {
            state.profile.missingInfoToSign.push('idCheck')
          }
        } else {
          state.profile.missingInfoToSign = ['idCheck']
        }
      }
      commit(SET_ID_CHECK_STATE, data)
      commit(SET_USER_PROFILE, state.profile)
    } catch (error) {
      commit(SET_ID_CHECK_STATE_ERROR, error)
      throw error
    } finally {
      commit(SET_ID_CHECK_STATE_PENDING, false)
    }
  },
  async [POST_ID_CHECK] ({ commit, state }, idCheck) {
    commit(SET_ID_CHECK_PENDING, true)
    // Reset it because the user is attempting another id check
    commit(SET_ID_CHECK_ERROR, null)
    try {
      const { data } = await userService.postIdCheck(idCheck)
      commit(SET_ID_CHECK, data)
      removeIdCheckFromMissingInfo(state.profile)
      commit(SET_ID_CHECK_STATE, {
        hasValidIdCheck: true,
        idCheckValidUntil: data.docExpDate,
      })
      commit(SET_USER_PROFILE, state.profile)
    } catch (error) {
      let computedError = error
      if (error.response?.status === 400) {
        // If it's a response from the server, we may want to do something with it
        if (error.response.data?.triesLeft || error.response.data?.triesLeft === 0) {
          computedError = error.response.data
          state.profile.failedIdChecks = FAILED_ID_CHECKS_LIMIT - computedError.triesLeft
          commit(SET_USER_PROFILE, state.profile)
        }
      }
      commit(SET_ID_CHECK_ERROR, computedError)
      throw computedError
    } finally {
      commit(SET_ID_CHECK_PENDING, false)
    }
  },
  async [DELETE_NOTIFICATION_PREFERENCES] ({ commit }, id) {
    commit(SET_DELETE_NOTIFICATION_PREFERENCES_PENDING, true)
    try {
      await userService.deleteNotificationPreferences(id)
      commit(SET_CURRENT_NOTIFICATION_PREFERENCES, {})
    } catch (error) {
      commit(SET_DELETE_NOTIFICATION_PREFERENCES_ERROR, error)
      throw error
    } finally {
      commit(SET_DELETE_NOTIFICATION_PREFERENCES_PENDING, false)
    }
  },
  async [UPDATE_PASSWORD] ({ commit, state }, data) {
    commit(SET_UPDATE_PASSWORD_PENDING, true)
    try {
      await userService.updateProfile(data)
      commit(SET_USER_PROFILE, { ...state.profile, ...data })
    } catch (error) {
      commit(SET_UPDATE_PASSWORD_ERROR, error)
      throw error
    } finally {
      commit(SET_UPDATE_PASSWORD_PENDING, false)
    }
  },
  async [CREATE_NOTIFICATION_PREFERENCES] ({ commit }, id) {
    commit(SET_CREATE_NOTIFICATION_PREFERENCES_PENDING, true)
    try {
      // The answer gives back the default params for editing
      const { data } = await userService.postNotificationPreferences(id)
      commit(SET_CURRENT_NOTIFICATION_PREFERENCES, data)
    } catch (error) {
      commit(SET_CREATE_NOTIFICATION_PREFERENCES_ERROR, error)
      throw error
    } finally {
      commit(SET_CREATE_NOTIFICATION_PREFERENCES_PENDING, false)
    }
  },
  async [GET_CURRENT_NOTIFICATION_PREFERENCES] ({ commit }, id) {
    commit(SET_CURRENT_NOTIFICATION_PREFERENCES_PENDING, true)
    try {
      let { data } = await userService.getNotificationPreferences(id)
      // The back-end may just return a blank response. We change it back to an empty object.
      if (!data) {
        data = {}
      }
      commit(SET_CURRENT_NOTIFICATION_PREFERENCES, data)
    } catch (error) {
      commit(SET_CURRENT_NOTIFICATION_PREFERENCES_ERROR, error)
      throw error
    } finally {
      commit(SET_CURRENT_NOTIFICATION_PREFERENCES_PENDING, false)
    }
  },
  async [UPDATE_NOTIFICATION_PREFERENCES] ({ commit }, { preferences, id }) {
    commit(SET_UPDATE_NOTIFICATION_PREFERENCES_PENDING, true)
    try {
      await userService.updateNotificationPreferences(preferences, id)
    } catch (error) {
      commit(SET_UPDATE_NOTIFICATION_PREFERENCES_ERROR, error)
      throw error
    } finally {
      commit(SET_UPDATE_NOTIFICATION_PREFERENCES_PENDING, false)
    }
  },
  async [UPDATE_NOTIFICATION_MUTE_EMAILS] ({ commit }, muteEmails) {
    commit(SET_UPDATE_NOTIFICATION_PREFERENCES_PENDING, true)
    try {
      await userService.updateNotificationMuteEmail(muteEmails)
    } catch (error) {
      commit(SET_UPDATE_NOTIFICATION_PREFERENCES_ERROR, error)
      throw error
    } finally {
      commit(SET_UPDATE_NOTIFICATION_PREFERENCES_PENDING, false)
    }
  },
  async [UPDATE_LANGUAGE] ({ commit, state }, language) {
    commit(SET_UPDATE_LANGUAGE_PENDING, true)
    try {
      await userService.updateProfile({ locale: language })
      commit(SET_USER_PROFILE, { ...state.profile, locale: language })
    } catch (error) {
      commit(SET_UPDATE_LANGUAGE_ERROR, error)
      throw error
    } finally {
      commit(SET_UPDATE_LANGUAGE_PENDING, false)
    }
  },
  async [UPDATE_PROFILE] ({ commit }, data) {
    commit(SET_UPDATE_PROFILE_PENDING, true)
    try {
      const response = await userService.updateProfile(data)

      if (response.status === 200) {
        if (response.data && response.data.birthDate) {
          // We don't need the timezone info.
          // The back-end currently gives it back and this is a problem for pickers.
          response.data.birthDate = response.data.birthDate.substr(0, 10)
        }
        commit(SET_USER_PROFILE, response.data)
      }
    } catch (error) {
      commit(SET_UPDATE_PROFILE_ERROR, error)
      throw error
    } finally {
      commit(SET_UPDATE_PROFILE_PENDING, false)
    }
  },
  /**
   * @function getUser
   * @param {store} store
   * @param {query} query
   */
  async [GET_USER] ({ commit, state }, query) {
    try {
      commit(SET_GET_SELF_USER_PENDING, true)
      const response = await userService.getUser(query)
      if (response.data && response.data.birthDate) {
        // We don't need the timezone info.
        // The back-end currently gives it back and this is a problem for pickers.
        response.data.birthDate = response.data.birthDate.substr(0, 10)
      }
      commit(SET_USER_PROFILE, merge(state.profile, response.data))
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      commit(SET_GET_SELF_USER_PENDING, false)
    }
  },
  [GET_SELF_CONTACT_LIST]: async function ({ commit, state }, { fromUserSubscriptionOnly = false, payingUsersOnly = false } = {}) {
    let contactList
    const hasRequestedSelfContactListAlready =
      (fromUserSubscriptionOnly && payingUsersOnly && state.selfContactListFromSubscriptionPayingUsers.length !== 0) ||
      (fromUserSubscriptionOnly && !payingUsersOnly && state.selfContactListFromSubscription.length !== 0) ||
      (!fromUserSubscriptionOnly && !payingUsersOnly && state.selfContactList.length !== 0)

    if (!hasRequestedSelfContactListAlready) {
      commit(SET_SELF_CONTACT_LIST_PENDING, true)
      const { data } = await userService.getSelfContactList(fromUserSubscriptionOnly, payingUsersOnly)
      contactList = data
      commit(SET_SELF_CONTACT_LIST_PENDING, false)
      if (payingUsersOnly) {
        commit(SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION_PAYING_USERS, contactList)
      } else if (fromUserSubscriptionOnly) {
        commit(SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION, contactList)
      } else {
        commit(SET_SELF_CONTACT_LIST, contactList)
      }
    } else {
      if (payingUsersOnly) {
        contactList = state.selfContactListFromSubscriptionPayingUsers
      } else if (fromUserSubscriptionOnly) {
        contactList = state.selfContactListFromSubscription
      } else {
        contactList = state.selfContactList
      }
    }
    return contactList
  },
  async [GET_ZENDESK_AUTH] ({ commit }) {
    commit(SET_GET_ZENDESK_AUTH_PENDING, true)
    try {
      const { data } = await userService.getZendeskAuth()
      window.zE('messenger', 'loginUser', function (callback) {
        callback(data.token)
      })
    } finally {
      commit(SET_GET_ZENDESK_AUTH_PENDING, false)
    }
  },
}
