var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"CerteuropeSignHeader",attrs:{"align":"center"}},[(_vm.signingView)?_c('v-col',[_c('div',{staticClass:"text-h3 white--text mb-1"},[_vm._v(_vm._s(_vm.$t('certeurope-sign.CerteuropeSignHeader.reviewAndSignDocument')))]),_c('div',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$tc('certeurope-sign.CerteuropeSignHeader.remainingTags', _vm.tagsCount))+" "),_c('v-chip',{attrs:{"color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.tagsCount)+" ")])],1)]):_vm._e(),_c('v-col',{staticClass:"text-right"},[(_vm.signingView)?[(_vm.tagsCount > 0)?_c('AppButton',{staticClass:"mr-2",attrs:{"color":"white"},on:{"click":_vm.goToNextTag}},[_vm._v(" "+_vm._s(_vm.$t('certeurope-sign.CerteuropeSignHeader.continue'))+" ")]):_vm._e(),(_vm.tagsCount === 0)?_c('AppButton',{staticClass:"CerteuropeSignHeader-finishButton mr-2",attrs:{"color":"white","disabled":_vm.signTagPending,"loading":_vm.confirmSignaturePending},on:{"click":_vm.onFinishClick}},[_vm._v(" "+_vm._s(_vm.$t('common.finish'))+" ")]):_vm._e()]:_vm._e(),_c('AppButton',{attrs:{"color":"white","to":{
                 name: _vm.SIGN_LANDING_PAGE_ROUTE_NAME,
                 params: { mnemo: _vm.mnemo },
                 query: {
                   event: 'cancel',
                 },
                }}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }