import Vue from 'vue'
import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const colors = {
  brand: {
    base: '#FF5D35',
    lighten5: '#FFF1EE',
    lighten4: '#FFDFD7',
    lighten3: '#FFBEAE',
    lighten2: '#FF8E72',
    lighten1: '#FF6D49',
    darken1: '#F7552D',
    darken2: '#991F00',
    darken3: '#661400',
    darken4: '#330A00',
  },
  project: {
    base: '#4286ED',
    lighten5: '#EDF3FE',
    lighten4: '#D9E7FB',
    lighten3: '#B3CFF8',
    lighten2: '#77ABF9',
    lighten1: '#5593EF',
    darken1: '#327DEE',
    darken2: '#0D408C',
    darken3: '#092B5D',
    darken4: '#04152F',
  },
  dataroom: {
    base: '#803DEC',
    lighten5: '#F3ECFE',
    lighten4: '#E6D8FB',
    lighten3: '#CCB1F7',
    lighten2: '#A779F0',
    lighten1: '#8D50EE',
    darken1: '#782FEE',
    darken2: '#3E0E8B',
    darken3: '#29095D',
    darken4: '#15052E',
  },
  archive: {
    base: '#1565C0',
    lighten5: '#E8F0F9',
    lighten4: '#D0E0F2',
    lighten3: '#A1C1E6',
    lighten2: '#5A92D2',
    lighten1: '#2C74C6',
    darken1: '#0D60BE',
    darken2: '#0F488A',
    darken3: '#0A305C',
    darken4: '#05182E',
  },
  white: '#FFFFFF',
  grey: {
    base: '#717B85',
    lighten5: '#F1F2F3',
    lighten4: '#E3E5E7',
    lighten3: '#AAB0B6',
    lighten2: '#AAB0B6',
    lighten1: '#8D959D',
    darken1: '#4F565D',
    darken2: '#444A50',
    darken3: '#2D3135',
    darken4: '#0B0C0D',
  },
  info: {
    base: '#0095DA',
    lighten1: '#CCEAF8',
  },
  success: {
    base: '#389B3C',
    lighten1: '#D7EBD8',
  },
  warning: {
    base: '#EB8916',
    lighten1: '#FBE7D0',
  },
  error: {
    base: '#E71E31',
    lighten1: '#FAD2D6',
  },
  'brand-secondary': '#4C75B5',

  /**
   * TODO:
   * This color was not included in the Design system colors
   * it was implemented for the needs of ticket V2-2897
   * We'll come back to it
   */
  blue: {
    base: '#EDF1F8',
  },
}

export const opts = {
  lang: {
    locales: { fr, en, de },
    current: 'fr',
  },
  breakpoint: {
    thresholds: {
      md: 1424,
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        secondary: '#4EA9AD',
        tertiary: '#4B75B5',
        tertiaryLight: '#e8ebf0',
        quaternary: '#B99C6B',
        accent: '#333333',
        greyDark: '#7a7a7a',
        originalGrey: {
          base: '#9E9E9E',
          lighten1: '#BDBDBD',
          lighten2: '#E0E0E0',
          lighten3: '#EEEEEE',
          lighten4: '#F5F5F5',
          lighten5: '#FAFAFA',
          darken1: '#757575',
          darken2: '#616161',
          darken3: '#424242',
          darken4: '#212121',
        },

        // design system
        white: colors.white,
        grey: colors.grey,
        primary: colors.brand,
        brand: colors.brand,
        project: colors.project,
        dataroom: colors.dataroom,
        archive: colors.archive,
        'brand-secondary': colors['brand-secondary'],
        blue: colors.blue,
        text: {
          title: colors.grey.darken3,
          primary: colors.grey.darken2,
          secondary: colors.grey.base,
          tertiary: colors.grey.lighten1,
          disable: colors.grey.lighten3,
          hyperlink: colors.brand.base,
          reversed: colors.white,
        },
        info: colors.info,
        success: colors.success,
        warning: colors.warning,
        error: colors.error,
      },
    },
  },
  icons: {
    iconfont: 'fa',
  },
}

export default new Vuetify(opts)
