export const mutations = {
  SET_LOADING: function (state, value) {
    state.loading = value
  },
  SET_IS_OPEN: function (state, value) {
    state.isOpen = value
  },
  SET_DOCUMENTS: function (state, documents) {
    state.documents = documents
  },
}
