<template>
    <div>
      <template v-if="attachedFileVersionsPending">
        <v-row>
          <v-col class="text-center mt-5">
            <AppLoader/>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col class="text-subtitle-1">
            <b>{{ $t('project.checklist.pane.ChecklistTableTaskPaneVersions.addVersion') }}</b>
          </v-col>
        </v-row>
        <v-row v-if="tableTaskPane.documents.length > 0">
          <v-col>
            <Alert type="warning"
                   :dismissible="false"
                   no-icon
            >
          <span class="text-h4">
            {{ $t('project.checklist.pane.ChecklistTableTaskPaneVersions.versionNumber') }}
            {{ tableTaskPane.documents[0].nbVersions }}
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-right']"></font-awesome-icon>
            {{ tableTaskPane.documents[0].nbVersions + 1 }}
          </span>
            </Alert>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-file-input v-model="newVersionFile"
                          prepend-icon="fal fa-paperclip"
                          dense
                          style="cursor:pointer;"
                          hide-details
                          color="blue darken-2"
                          outlined
                          :label="$t('project.checklist.pane.ChecklistTableTaskPaneVersions.newVersionFileLabel')"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch v-model="cloneRightsFromPrevious"
                      color="primary"
                      class="mt-0"
                      hide-details
            >
              <template #label>
                {{ $t('project.checklist.pane.ChecklistTableTaskPaneVersions.cloneRightsFromPrevious') }}
                <RightsDisplayButton v-if="attachedFileVersions && attachedFileVersions.length > 0 && attachedFileVersions[0].rights"
                                     :rights="attachedFileVersions[0].rights"
                                     class="ml-2"
                />
                <RightsDisplayButton v-else-if="tableTaskPane.documents[0].rights"
                                     :rights="tableTaskPane.documents[0].rights"
                                     class="ml-2"
                />
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch v-model="sendNotif"
                      :label="$t('project.checklist.pane.ChecklistTableTaskPaneVersions.sendNotif')"
                      color="primary"
                      class="mt-0"
                      hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <VisualTextEditor v-if="sendNotif"
                              v-model="notifyMessage"
                              counter="footer"
                              :error-messages="notifyMessageErrors"
                              :max-length="notifyMessageMaxLength"
                              :placeholder="$t('project.checklist.pane.ChecklistTableTaskPaneVersions.optionalMessageWithCopy')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <AppButton color="primary"
                       outlined
                       block
                       :disabled="postAttachedFileVersionsPending"
                       @click="resetAndClose"
            >
              {{$t('common.cancel')}}
            </AppButton>
          </v-col>
          <v-col>
            <AppButton color="primary"
                       block
                       :loading="postAttachedFileVersionsPending"
                       :disabled="replaceDisabled"
                       @click="preparePostAttachedFileVersion"
            >
              {{$t('project.checklist.pane.ChecklistTableTaskPaneVersions.replace')}}
            </AppButton>
          </v-col>
        </v-row>
      </template>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Alert from '../../../common/alerts/Alert'
import AppLoader from '../../../common/AppLoader'
import AppButton from '../../../common/buttons/AppButton'
import RightsDisplayButton from '../../../common/users/RightsDisplayButton.vue'
import { escapeHtml } from '../../../common/utils/strings'
import VisualTextEditor from '../../../common/visual-text-editor/VisualTextEditor'
import { GET_ATTACHED_FILE_VERSIONS, POST_ATTACHED_FILE_VERSION, GET_TASK_FOLDER, GET_GLOBAL_TASK_FOLDER } from '../../../store/modules/checklist/action_types'
import { RESET_ATTACHED_FILE_VERSIONS, SET_TABLE_TASK_PANE } from '../../../store/modules/checklist/mutation_types'
import { ENQUEUE_SNACKBAR } from '../../../store/mutation_types'

const NOTIFY_MESSAGE_MAX_LENGTH = 400

export default {
  name: 'ChecklistTableTaskPaneVersions',
  components: { Alert, AppLoader, VisualTextEditor, AppButton, RightsDisplayButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      newVersionFile: null,
      cloneRightsFromPrevious: true,
      sendNotif: true,
      notifyMessage: '',
      notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
    }
  },
  computed: {
    ...mapState('checklist', ['tableTaskPane', 'attachedFileVersions', 'attachedFileVersionsPending', 'postAttachedFileVersionsPending']),
    file () {
      return this.tableTaskPane.documents[0]
    },
    paneTab () {
      return this.tableTaskPane.tab
    },
    paneIsOpen () {
      return this.tableTaskPane.isOpen
    },
    notifyMessageLength () {
      return escapeHtml(this.notifyMessage).length
    },
    notifyMessageIsTooLong () {
      return this.notifyMessageLength > this.notifyMessageMaxLength
    },
    notifyMessageErrors () {
      const errors = []

      if (this.notifyMessageIsTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    replaceDisabled () {
      return this.notifyMessageIsTooLong ||
        !this.newVersionFile ||
        this.postAttachedFileVersionsPending
    },
  },
  watch: {
    paneTab (val) {
      if (val === 'move') {
        this.loadData()
      }
    },
    paneIsOpen (val) {
      if (!val) {
        this.resetAndClose()
      }
    },
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('checklist', [GET_ATTACHED_FILE_VERSIONS, POST_ATTACHED_FILE_VERSION, GET_TASK_FOLDER, GET_GLOBAL_TASK_FOLDER]),
    async loadData () {
      try {
        await this.GET_ATTACHED_FILE_VERSIONS({
          mnemo: this.mnemo,
          fileId: this.file.id,
        })
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.pane.ChecklistTableTaskPaneVersions.getAttachedFileVersionsError'),
        })
      }
    },
    resetAndClose () {
      this.newVersionFile = null
      this.cloneRightsFromPrevious = true
      this.sendNotif = true
      this.notifyMessage = ''
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: false,
        tab: '',
        documents: [],
        parent: null,
      })
      this.$store.commit(`checklist/${RESET_ATTACHED_FILE_VERSIONS}`)
    },
    async preparePostAttachedFileVersion () {
      try {
        const formData = new FormData()
        formData.append('fileSelect', this.newVersionFile)
        formData.append('formCloneRightsFromPrevious', this.cloneRightsFromPrevious)
        formData.append('formToggleNotif', this.sendNotif)
        formData.append('formNotifMessage', this.notifyMessage)
        await this.POST_ATTACHED_FILE_VERSION({
          mnemo: this.mnemo,
          documentId: this.file.id,
          data: formData,
        })
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'success',
          message: this.$t('project.checklist.pane.ChecklistTableTaskPaneVersions.postAttachedFileVersionSuccess'),
        })
        // Global new version operation
        if (this.$store.state.checklist.globalAttachedFilesDialogIsOpen) {
          this.GET_GLOBAL_TASK_FOLDER(this.mnemo)
        } else {
          this.GET_TASK_FOLDER({
            mnemo: this.mnemo,
            folderId: this.file.parent.id,
          })
        }

        this.resetAndClose()
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.checklist.pane.ChecklistTableTaskPaneVersions.postAttachedFileVersionError'),
        })
      }
    },
  },
}
</script>
