<template>
  <div>
    <template v-if="isPending">
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            hide-on-leave
            class="mx-auto mb-2"
            type="image"
            height="40"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </template>
    <v-row v-else-if="folders.length">
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="12">
            <div class="ProjectsFolders-title">{{ $t('projects.ProjectsHeader.folders') }}</div>
          </v-col>
        </v-row>
          <v-container fluid class="py-4 px-0">
            <ProjectsFoldersTemplate :folders="folders"></ProjectsFoldersTemplate>
          </v-container>
      </v-col>
    </v-row>

    <!-- DIALOGS -->
    <DocumentsDeleteDialog v-if="projectsFolderDeleteDialogIsOpen"/>
    <DocumentsRenameDialog v-if="projectsFolderRenameDialogIsOpen"/>
    <ProjectsMoveRoomDialog v-if="projectsFolderMoveDialogIsOpen"/>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import DocumentsDeleteDialog from '@/project/documents/dialogs/DocumentsDeleteDialog.vue'
  import DocumentsRenameDialog from '@/project/documents/dialogs/DocumentsRenameDialog.vue'
  import ProjectsMoveRoomDialog from '@/projects/dialogs/ProjectsMoveRoomDialog.vue'

  import ProjectsFoldersTemplate from './ProjectsFoldersTemplate.vue'

  export default {
    name: 'ProjectsFolders',
    components: {
      ProjectsFoldersTemplate,
      DocumentsDeleteDialog,
      DocumentsRenameDialog,
      ProjectsMoveRoomDialog,
    },
    computed: {
      ...mapState('projectsFolders', ['projectsFoldersTree', 'getProjectsFoldersTreePending', 'currentProjectsFolder', 'projectsFolderByIdPending']),
      ...mapState('projectsFoldersRename', ['projectsFolderRenameDialogIsOpen']),
      ...mapState('projectsFoldersDelete', ['projectsFolderDeleteDialogIsOpen']),
      ...mapState('projectsFoldersMove', ['projectsFolderMoveDialogIsOpen']),
      isPending () {
        return this.projectsFolderByIdPending || this.getProjectsFoldersTreePending
      },
      folders () {
        return this.currentProjectsFolder.children || this.projectsFoldersTree
      },
    },
  }
</script>

<style scoped lang="scss">
  .ProjectsFolders-title {
    font-size: 21px;
    font-weight: 600;
  }
</style>
