<template>
  <AppDialog :is-open="isOpen"
             :ok-text="$t(`common.app-file-upload.RoomStorageExceededDialog.dialogOkLabel.${currentUserTranslationStatus}`)"
             :ok-loading="notifyPmNoMoreSpacePending"
             @cancel="closeDialog"
             @ok="onConfirm"
  >
    <template #title>
      <div class="d-flex align-center">
        <app-icon icon-name="times-circle" icon-weight="fas" class="error--text mr-2"/>
        {{ $t('common.app-file-upload.RoomStorageExceededDialog.dialogTitle') }}
      </div>
    </template>
    <template #body>
     {{ $t(`common.app-file-upload.RoomStorageExceededDialog.dialogContent.${currentUserTranslationStatus}`)}}
    </template>
  </AppDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { ROOM_SETTINGS_ROUTE_NAME } from '@/router'
import { NOTIFY_PM_NO_MORE_SPACE } from '@/store/modules/room/action_types'
import { SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN } from '@/store/modules/room/mutation_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'RoomStorageExceededDialog',
  components: { AppDialog },
  computed: {
    ...mapGetters('room', ['roomMnemo', 'isPmOfRoomSubscription']),
    ...mapState('room', ['roomStorageExceededDialogIsOpen', 'notifyPmNoMoreSpacePending']),
    isOpen () : boolean {
      return this.roomStorageExceededDialogIsOpen
    },
    currentUserTranslationStatus () : string {
      return this.isPmOfRoomSubscription ? 'isPm' : 'isGuest'
    },
  },
  methods: {
    ...mapMutations('room', [SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN]),
    ...mapActions('room', [NOTIFY_PM_NO_MORE_SPACE]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    closeDialog () {
      this.SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN(false)
    },
    async onConfirm () {
      if (this.isPmOfRoomSubscription) {
        this.$router.push({ name: ROOM_SETTINGS_ROUTE_NAME, query: { show: 'upgradeDialog' } })
      } else {
        try {
          await this.NOTIFY_PM_NO_MORE_SPACE({ mnemo: this.roomMnemo })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyPmSuccess'))
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyPmError'))
        }
      }
      this.closeDialog()
    },
  },
})
</script>
