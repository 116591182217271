<template>
  <v-menu offset-y right min-width="150">
    <template #activator="{ on, attrs }">
      <app-button type="icon" v-bind="attrs" v-on="on">
        <app-icon icon-name="ellipsis"/>
      </app-button>
    </template>
    <v-list class="py-0">
      <v-list-item v-for="item in itemMenu" :key="item.action"
                   @click="handleMenuAction(item.action)"
      >
        <v-list-item-icon v-if="item.icon || item.img"
                          :class="item.class"
                          class="mr-2"
        >
          <img v-if="item.img"
               :src="`/img/icons/${item.img}.svg`"
               :alt="item.img"
               class="menu-item-img"
          />
          <app-icon v-else :icon-name="item.icon"/>
        </v-list-item-icon>
        <v-list-item-content :class="item.class">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapMutations } from 'vuex'

  import { FOLDER_MAX_DEPTH } from '@/common/constants'
  import {
    SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN,
    SET_PROJECTS_FOLDER_TO_DELETE,
  } from '@/store/modules/projects-folders-delete/mutation_types'
  import {
    SET_PROJECTS_FOLDER_MOVE_DIALOG_IS_OPEN,
    SET_PROJECTS_FOLDER_TO_MOVE,
  } from '@/store/modules/projects-folders-move/mutation_types'
  import {
    SET_PROJECTS_FOLDER_RENAME_DIALOG_IS_OPEN,
    SET_PROJECTS_FOLDER_TO_RENAME,
  } from '@/store/modules/projects-folders-rename/mutation_types'
  import {
    SET_MOVE_ROOM_DIALOG_IS_OPEN,
    SET_ROOM_TO_MOVE,
  } from '@/store/modules/room/mutation_types'

  export default {
    name: 'ProjectsFoldersGridMenu',
    props: {
      // Room or Folder
      item: {
        type: Object,
        required: true,
      },
      isRoom: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      itemMenu () {
        const menu = []
        if (this.isRoom) {
            menu.push({
              img: 'move_icon',
              text: this.$t('common.move'),
              action: 'move',
            })
            // TODO
            // menu.push({
            //   icon: 'pen-to-square',
            //   text: this.$t('common.rename'),
            //   action: 'edit',
            // })
        } else {
          menu.push({
            icon: 'pen-to-square',
            text: this.$t('common.rename'),
            action: 'edit',
          })
          if (this.item.maxDepth < FOLDER_MAX_DEPTH || this.item.depth === FOLDER_MAX_DEPTH) {
            menu.push({
              img: 'move_icon',
              text: this.$t('common.move'),
              action: 'move',
            })
          }
          menu.push({
            icon: 'trash-can',
            text: this.$t('common.delete'),
            action: 'delete',
            class: 'error--text',
          })
        }
        return menu
      },
    },
    methods: {
      ...mapMutations('projectsFoldersRename', [
        SET_PROJECTS_FOLDER_RENAME_DIALOG_IS_OPEN,
        SET_PROJECTS_FOLDER_TO_RENAME,
      ]),
      ...mapMutations('projectsFoldersMove', [
        SET_PROJECTS_FOLDER_MOVE_DIALOG_IS_OPEN,
        SET_PROJECTS_FOLDER_TO_MOVE,
      ]),
      ...mapMutations('projectsFoldersDelete', [
        SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN,
        SET_PROJECTS_FOLDER_TO_DELETE,
      ]),
      ...mapMutations('room', [
        SET_MOVE_ROOM_DIALOG_IS_OPEN,
        SET_ROOM_TO_MOVE,
      ]),
      handleMenuAction (action) {
        switch (action) {
          case 'edit':
            if (this.isRoom) {
              // TODO
            } else {
              this.SET_PROJECTS_FOLDER_TO_RENAME(this.item)
              this.SET_PROJECTS_FOLDER_RENAME_DIALOG_IS_OPEN(true)
            }
            break
          case 'move':
            if (this.isRoom) {
              this.SET_ROOM_TO_MOVE(this.item)
              this.SET_MOVE_ROOM_DIALOG_IS_OPEN(true)
            } else {
              this.SET_PROJECTS_FOLDER_TO_MOVE(this.item)
              this.SET_PROJECTS_FOLDER_MOVE_DIALOG_IS_OPEN(true)
            }
            break
          case 'delete':
            if (!this.isRoom) {
              this.SET_PROJECTS_FOLDER_TO_DELETE(this.item)
              this.SET_PROJECTS_FOLDER_DELETE_DIALOG_IS_OPEN(true)
            }
            break
        }
      },
    },
  }
</script>

<style scoped>
  .menu-item-img {
    /* To align with fa icon width */
    width: 22px;
    margin-left: 2px;
  }
</style>
