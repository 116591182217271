<template>
  <app-alert-in-page :variant="computedAlertVariant" class="my-4">
    <template>
      <app-text variant="small-bold" class="mb-0">
        {{ $t(`project.documents.DocumentsStorageExceededAlert.alertContent.${computedAlertVariant}`) }}
      </app-text>
    </template>
    <template #button>
      <app-button-alert v-if="isPmOfRoomSubscription" :type="computedAlertVariant" :to="{ name: ROOM_SETTINGS_ROUTE_NAME, query: { show: 'upgradeDialog' } }">
        {{ $t('project.documents.DocumentsStorageExceededAlert.alertCtaLabel')}}
      </app-button-alert>
      <app-button-alert v-if="!isPmOfRoomSubscription && computedAlertVariant === 'error'" :type="computedAlertVariant" @click="notifyPms">
        {{ $t(`common.app-file-upload.RoomStorageExceededDialog.dialogOkLabel.isGuest`)}}
      </app-button-alert>
    </template>
  </app-alert-in-page>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  import { ROOM_SETTINGS_ROUTE_NAME } from '@/router'
  import { NOTIFY_PM_NO_MORE_SPACE } from '@/store/modules/room/action_types'
  import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

  export default defineComponent({
    name: 'DocumentsStorageExceededAlert',
    data () {
      return {
        ROOM_SETTINGS_ROUTE_NAME,
      }
    },
    computed: {
      ...mapGetters('room', ['storagePercent', 'roomMnemo', 'isPmOfRoomSubscription']),
      computedAlertVariant () : 'error' | 'warning' {
        return this.storagePercent >= 100 ? 'error' : 'warning'
      },
    },
    methods: {
      ...mapActions('room', [NOTIFY_PM_NO_MORE_SPACE]),
      ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
      async notifyPms () {
        try {
          await this.NOTIFY_PM_NO_MORE_SPACE({ mnemo: this.roomMnemo })
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyPmSuccess'))
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.app-file-upload.RoomStorageExceededDialog.notifyPmError'))
        }
      },
    },
  })
</script>
