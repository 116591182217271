<template>
  <div>
    <div v-if="displayTreeStructure && filder.type === 'title'" class="py-2 pl-12">
      <b>{{ filder.display }} - {{ filder.title }}</b>
    </div>

    <AppTooltip v-else top
                :disabled="!isTooLargeToBeSigned"
    >
      <template #activator="{attrs, on}">
        <div class="AppClosdFildersTreeItem pl-6" :class="{'AppClosdFildersTreeItem-disabled': checkboxDisabled}"
             v-bind="attrs"
             v-on="on"
        >
          <div class="AppClosdFildersTreeItem-container">
            <v-icon v-if="filder.type === 'folder'"
                    class="AppClosdFildersTreeItem-caretButton"
                    :class="{'AppClosdFildersTreeItem-caretButton--closed': !folderIsOpen, 'fa-spin': loadingChildren}"
                    @click="onCaretButtonClick"
            >
              {{ loadingChildren ? 'fas fa-spinner-third' : 'fas fa-caret-down' }}
            </v-icon>
          </div>
          <v-checkbox v-model="isChecked"
                      color="primary"
                      dense
                      :indeterminate="partiallyChecked"
                      hide-details
                      :disabled="checkboxDisabled"
                      :ripple="false"
                      class="AppClosdFildersTreeItem-checkbox mt-0"
          >
            <template #label>
              <template v-if="filder.type === 'folder'">
                <font-awesome-icon v-if="folderIsOpen"
                                   class="AppClosdFildersTreeItem-checkboxLabelIcon AppClosdFildersTreeItem-checkboxLabelIcon--folder"
                                   :icon="['fad', 'folder-open']"
                                   size="lg"
                >
                </font-awesome-icon>
                <font-awesome-icon v-if="!folderIsOpen"
                                   class="AppClosdFildersTreeItem-checkboxLabelIcon AppClosdFildersTreeItem-checkboxLabelIcon--folder"
                                   :icon="['fad', 'folder']"
                                   size="lg"
                >
                </font-awesome-icon>
                {{ fullname }}
              </template>
              <template v-if="filder.type === 'file'">
                <ClosdFildersIcon class="AppClosdFildersTreeItem-checkboxLabelIcon" :document="filder"/>
                {{ fullname }}
                <v-chip v-if="filder.version > 1"
                        color="secondary"
                        small
                        class="caption mx-2"
                        label
                >
                  {{ `v.${filder.version}` }}
                </v-chip>
              </template>
            </template>
          </v-checkbox>
        </div>
      </template>
      <span>{{ $t('common.app-closd-filders-tree.AppClosdFildersTreeItem.isTooLargeToBeSigned', { size: maxSignableSize }) }}</span>
    </AppTooltip>
    <div v-show="folderIsOpen" class="pl-6">
      <AppClosdFildersTreeItem v-for="child in filder.children"
                               :key="`${child.type}-${child.id}`"
                               :display-tree-structure="displayTreeStructure"
                               :filder="child"
                               :selected-filders="selectedFilders"
                               :multiple="multiple"
                               :parent-is-selected="isChecked"
                               :disable-folder-without-file="disableFolderWithoutFile"
                               :disable-files-too-large-to-be-signed="disableFilesTooLargeToBeSigned"
                               :max-signable-size="maxSignableSize"
                               @child-checked="onChildCheck"
                               @check-children-except-one="checkChildrenExceptOne"
      />
    </div>
  </div>
</template>

<script>
import { numericSort } from '@/common/utils/sorting'

import documentsService from '../../services/documents.service'
import AppTooltip from '../AppTooltip'
import ClosdFildersIcon from '../filders/ClosdFildersIcon'
import { isFileLowerThanMaxSignableSize } from '../utils/files'
/**
 * Default Filder tree item
 * @displayName AppClosdFildersTreeItem
 */
/**
 * Display a folder or a file in AppClosdFildersTree
 * @see AppClosdFildersTree
 */
export default {
  name: 'AppClosdFildersTreeItem',
  components: { AppTooltip, ClosdFildersIcon },
  props: {
    /**
     * The item to display
     */
    filder: {
      type: Object,
      required: true,
    },
    /**
     * Displays tree titles and subtitles
     */
     displayTreeStructure: {
      type: Boolean,
      default: false,
    },
    /**
     * Array of selected items
     */
    selectedFilders: {
      type: Array,
      required: true,
    },
    /**
     * If the parent is selected. Internal purpose
     */
    parentIsSelected: {
      type: Boolean,
      required: true,
    },
    /**
     * Allow selecting multiple items
     */
    multiple: {
      type: Boolean,
      default: true,
    },
    /**
     * If true, display only files
     */
    filesOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, disable checkbox of folders without files in children (deep)
     */
    disableFolderWithoutFile: {
      type: Boolean,
      required: true,
    },
    /**
     * If true, disable selection for files larger than the provided maxSignableSize
     */
    disableFilesTooLargeToBeSigned: {
      type: Boolean,
      default: false,
    },
    /**
     * If disableFilesTooLargeToBeSigned is true, this should be set
     */
    maxSignableSize: {
      type: [Number, String],
      default: 0,
    },
  },
  data () {
    return {
      folderIsOpen: false,
      loadingChildren: false,
    }
  },
  computed: {
    isChecked: {
      get () { return this.indexIfSelected >= 0 || (this.parentIsSelected && this.multiple) },
      set (value) {
        if (value) {
          if (this.indexIfSelected < 0) {
            if (this.filesOnly && this.filder.type === 'folder') {
              return
            }
            if (!this.multiple) {
              this.selectedFilders.splice(0, this.selectedFilders.length)
              this.selectedFilders.push(this.filder)
            } else {
              this.selectedFilders.push(this.filder)
            }
            this.$emit('child-checked')
          }
        } else {
          if (this.indexIfSelected >= 0) {
            this.selectedFilders.splice(this.indexIfSelected, 1)
          } else if (this.indexIfSelected < 0 && this.parentIsSelected) {
            this.$emit('check-children-except-one', this.filder)
          }
        }
      },
    },
    partiallyChecked () {
      if (this.filder.type === 'folder') {
        return this.filder.children.some(o => this.selectedFilders.some(selectedFilder => selectedFilder.id === o.id && selectedFilder.type === o.type)) && this.multiple
      }
      return false
    },
    indexIfSelected () {
      return this.selectedFilders.findIndex(o => o.id === this.filder.id && o.type === this.filder.type)
    },
    fullname () {
      return (this.filder.numbering ? this.filder.numbering + ' ' : '') + (this.filder.basename || this.filder.name)
    },
    checkboxDisabled () {
      if (this.filder.type === 'file') {
        return this.isTooLargeToBeSigned
      }

      if (!this.multiple && this.filesOnly) {
        return true
      }

      return this.disableFolderWithoutFile && !this.folderHasChildrenFile(this.filder)
    },
    isTooLargeToBeSigned () {
      if (this.filder.type === 'file' && this.disableFilesTooLargeToBeSigned) {
        return !isFileLowerThanMaxSignableSize(this.filder, this.maxSignableSize)
      }
      return false
    },
  },
  watch: {
    parentIsSelected (value) {
      if (value) {
        if (this.indexIfSelected >= 0) {
          this.selectedFilders.splice(this.indexIfSelected, 1)
        }
      }
    },
  },
  mounted () {
    if (this.displayTreeStructure && this.filder.type === 'title') {
      this.folderIsOpen = true // Display title and subtitles by default
    }

    if (!this.displayTreeStructure && this.filder.children) {
      this.filder.children = this.filder.children.sort((a, b) => {
        if (a.type === 'folder' && b.type === 'file') {
          return 0
        } else if (a.type === 'file' && b.type === 'folder') {
          return 1
        } else {
          const aFullname = a.numbering ? `${a.numbering} ${a.basename}` : a.basename
          const bFullname = b.numbering ? `${b.numbering} ${b.basename}` : b.basename

          return numericSort(aFullname, bFullname)
        }
      })
    }
  },
  methods: {
    onChildCheck () {
      if (this.filder.type !== 'title' && this.filder.children.every(child => this.selectedFilders.some(o => o.id === child.id && o.type === child.type)) && this.multiple) {
        this.isChecked = true
        // If we don't return only the files, we can return the folder containg them all
        if (!this.filesOnly) {
          this.filder.children.forEach(f => {
            const index = this.selectedFilders.findIndex(o => o.id === f.id && o.type === f.type)
            if (index >= 0) {
              this.selectedFilders.splice(index, 1)
            }
          })
        }
      }
    },
    checkChildrenExceptOne (exceptFilder) {
      if (this.indexIfSelected >= 0) {
        this.selectedFilders.splice(this.indexIfSelected, 1)
      }
      this.filder.children.forEach(child => {
        if (child.id !== exceptFilder.id) {
          this.selectedFilders.push(child)
        } else {
          if (child.type !== exceptFilder.type) {
            this.selectedFilders.push(child)
          }
        }
      })
      if (this.parentIsSelected) {
        this.$emit('check-children-except-one', this.filder)
      }
    },
    onCaretButtonClick () {
      if (this.displayTreeStructure || this.filder.childrenCount === this.filder.children.length) {
        this.folderIsOpen = !this.folderIsOpen
      } else if (this.filder.childrenCount > 0) {
        this.loadChildren()
      }
    },
    async loadChildren () {
      this.loadingChildren = true
      try {
        const response = await documentsService.getDocumentsById(this.$route.params.mnemo, this.filder.id)
        this.filder.children = response.data.children.map(o => {
          o.key = `${o.type}-${o.id}`
          return o
        })
        this.folderIsOpen = true
      } finally {
        this.loadingChildren = false
      }
    },
    folderHasChildrenFile (folder) {
      if (folder.children.some(filder => filder.type === 'file')) {
        return true
      }

      const childrenFolders = folder.children.filter(filder => filder.type === 'folder')
      return childrenFolders.some(this.folderHasChildrenFile)
    },
  },
}
</script>

<style scoped lang="scss">
.AppClosdFildersTreeItem {
  display: flex;
  align-items: center;
  min-height: 40px;
  overflow: hidden;
    &:hover, &:focus {
      background-color: #f7f7f7;
    }
}

.AppClosdFildersTreeItem-container {
  min-width: 24px;
}
.AppClosdFildersTreeItem-caretButton {
  width: 24px;
  height: 24px;
  &--closed {
    transform: rotate(-90deg);
  }
}
.AppClosdFildersTreeItem-checkbox {
  margin-left: 6px;
}
.AppClosdFildersTreeItem-checkboxLabelIcon {
  display: inline-block !important;
}
.AppClosdFildersTreeItem-checkboxLabelIcon--folder {
  margin-right: 6px;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
  --fa-primary-color: #6aaefe;
  --fa-secondary-color: #6aaefe;
}

.AppClosdFildersTreeItem-disabled {
  opacity: 50%;
}

::v-deep .AppClosdFildersTreeItem-disabled label {
  color: #8D959D;
}
::v-deep .AppClosdFildersTreeItem-checkbox {
  label {
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
    text-overflow: ellipsis;
    padding-right: 50px;
    display: inline-block !important;
  }
}
</style>
