import axios from 'axios'

export default {
  /**
   * @function getUser - Retrieve current user
   * @return AxiosInstance
   */
  getUser: function (query) {
    return axios.get('user/self', { params: query })
  },
  /**
   * @param {object} data - An object containing part or the entirety of a user's profile.
   * This includes its password and old password when updating it, and the locale if a change is needed
   * @returns Promise
   */
  updateProfile: async function (data) {
    return axios.put('user/self', { userInfo: data })
  },
  postIdCheck: async function (data) {
    const fileData = new FormData()
    for (const key in data) {
      fileData.append(key, data[key])
    }
    return axios.post('user/self/idCheck', fileData)
  },
  getIdCheck: async function () {
    return axios.get('user/self/idCheck')
  },
  getNotificationPreferences: function (id) {
    return axios.get('user/self/notifications/' + id)
  },
  postNotificationPreferences: function (id) {
    return axios.post('user/self/notifications/' + id)
  },
  deleteNotificationPreferences: function (id) {
    return axios.delete('user/self/notifications/' + id)
  },
  updateNotificationPreferences: async function (preferences, id) {
    return axios.patch('user/self/notifications/' + id, { preferences })
  },
  updateNotificationMuteEmail: async function (muteEmails) {
    return axios.patch('user/self/notifications/muteEmails', muteEmails)
  },
  getSelfContactList: function (fromUserSubscriptionOnly = false, payingUsersOnly = false) {
    const params = {}
    if (fromUserSubscriptionOnly) params.mySubscriptionOnly = true
    if (payingUsersOnly) params.payingUsersOnly = true
    return axios.get(
      'user/self/contactList',
      { params },
    )
  },
  getActivityPolling: async function () {
    return axios.get('ping', {
      headers: {
        'X-Poll': 'x-poll',
      },
    })
  },
  getZendeskAuth: function () {
    return axios.get('/user/self/zendesk')
  },
}
