<template>
  <div class="members">
    <MembersHeader :searchQuery.sync="searchQuery"
                   :mnemo="mnemo"
                   @onAddGroup="openAddGroupDialog"
    />
    <template v-if="loading || groups.length === 0">
      <v-row>
        <v-col v-for="i in 4" :key="i" cols="3">
          <v-skeleton-loader type="card-heading, list-item-avatar@4, actions"/>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col v-for="group in filteredGroups"
               :key="`group-${group.id}`"
               cols="12"
               sm="6"
               md="4"
               lg="3"
               xl="2"
        >
          <Group :group="group" :mnemo="mnemo"/>
        </v-col>
        <v-col v-if="isPm"
               cols="12"
               sm="6"
               md="4"
               lg="3"
               xl="2"
        >
          <v-btn depressed
                 color="#e8ebf0"
                 class="add-group-box rounded-lg tertiary--text"
                 :min-height="$vuetify.breakpoint.xs ? '80px' : '200px'"
                 @click="openAddGroupDialog"
          >
            <font-awesome-icon :icon="['fas', 'plus-circle']" class="mr-2"></font-awesome-icon>
            {{ $t('project.members.Members.addGroup') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <MembersAddGroupDialog v-if="addGroupDialogIsOpen" @close="closeAddGroupDialog" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapState } from 'vuex'

import MembersAddGroupDialog from './dialogs/MembersAddGroupDialog'
import Group from './Group'
import MembersHeader from './MembersHeader'

export default {
  name: 'Members',
  components: {
    Group,
    MembersHeader,
    MembersAddGroupDialog,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      addGroupDialogIsOpen: false,
      hideMembersDialogIsOpen: false,
      searchQuery: '',
    }
  },
  metaInfo () {
    return {
      title: this.$t('project.members.Members.metaTitle'),
    }
  },
  computed: {
    ...mapState('groups', ['groups', 'loading']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
    filteredGroups () {
      const filteredGroups = cloneDeep(this.groups)
      if (this.searchQuery) {
        filteredGroups.forEach(group => {
          group.members = group.members.filter(member => member.fullName.toLowerCase().includes(this.searchQuery.toLowerCase()))
        })
      }
      return filteredGroups
    },
  },
  mounted () {
    this.GET_GROUPS(this.mnemo)
  },
  methods: {
    ...mapActions('groups', ['GET_GROUPS']),
    openAddGroupDialog () {
      this.addGroupDialogIsOpen = true
    },
    closeAddGroupDialog () {
      this.addGroupDialogIsOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
  .add-group-box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
</style>
