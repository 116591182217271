<template>
  <aside class="documents-sidebar">
    <v-skeleton-loader v-if="isPending"
                       type="list-item, list-item, list-item"
                       class="documents-sidebar__loader"
    />
    <template v-if="isFolderTreeLoaded">
      <template v-if="homeLayout">
        <ProjectNavigationDrawerLayout :items="sortedFolders" :defaultSelectedItem="selectedItem">
          <template #section="{ item }">
            <DocumentsSidebarFolderItem
              :key="item.id"
              :folder="item"
              :children-sort="foldersSortFn"
              :disabled="false"
            />
          </template>
        </ProjectNavigationDrawerLayout>
      </template>
      <ul v-else :class="{ 'mx-2': homeLayout && !mini }">
        <AppDroppableArea disabled
                        draggable="false"
                        @dragenter="dragEnterFolder(RECENT_FOLDER)"
                        @dragleave="dragLeaveFolder(RECENT_FOLDER)"
        >
          <DocumentsSidebarSectionItem icon="clock"
                                      :section-name="recentFolderName"
                                      :selected="isSpecialFolderSelected(RECENT_FOLDER.id)"
                                      @click.native="navigateToSpecialFolder(RECENT_FOLDER.id)"
          />
          <DocumentsSidebarSectionItem v-if="!homeLayout && isTodoFolderAccessible"
                                      icon="file-lines"
                                      :section-name="$t('common.documents.todoFolder').toString()"
                                      :selected="isSpecialFolderSelected(foldersTreeData.todoFolderId)"
                                      @click.native="navigateToSpecialFolder(foldersTreeData.todoFolderId)"
          />
        </AppDroppableArea>
        <AppDroppableArea :disabled="!dropInCurrentFolderIsEnabled"
                        draggable="false"
                        @dragenter="dragEnterFolder(ROOT_FOLDER)"
                        @dragleave="dragLeaveFolder(ROOT_FOLDER)"
                        @drop="dropDocumentsInFolder($event, ROOT_FOLDER)"
        >
        <DocumentsSidebarSectionItem :icon="homeLayout ? 'rectangle-history' : 'folder'"
                                    :section-name="rootFolderName"
                                    :selected="isSpecialFolderSelected(ROOT_FOLDER.id)"
                                    @click.native="navigateToSpecialFolder(ROOT_FOLDER.id)"
        />
        </AppDroppableArea>

        <DocumentsSidebarFolderItem v-for="folder in sortedFolders"
                                  :key="folder.id"
                                  :folder="folder"
                                  :children-sort="foldersSortFn"
                                  :disabled="!dropInCurrentFolderIsEnabled"
                                  :draggable="true"
                                  @drag-enter-folder="dragEnterFolder"
                                  @drag-leave-folder="dragLeaveFolder"
                                  @drop-documents-in-folder="dropDocumentsInFolder"
                                  @dragstart="dragClosdDocumentStart"
      />
      </ul>
    </template>
  </aside>
</template>

<script lang="ts">
import { last } from 'lodash-es'
import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

import AppDroppableArea from '@/common/AppDroppableArea.vue'
import { DRAG_AND_DROP_MESSAGE_TYPE } from '@/common/utils/dragAndDropMessageType'
import { flatten } from '@/common/utils/flatArray'
import { sortNumbering } from '@/common/utils/sorting'
import { RECENT_FOLDER, ROOT_FOLDER } from '@/project/documents/constants/special-folders'
import ProjectNavigationDrawerLayout from '@/project/navigation-drawer/ProjectNavigationDrawerLayout.vue'
import { ROOMS_ROUTE_NAME } from '@/router/index'
import { SET_UPLOAD_HINT } from '@/store/modules/app-upload-handler/mutation_types'
import { GO_TO_FOLDER, LOAD_FOLDERS_TREE, MOVE_FILDERS, DESELECT_ALL_DOCUMENTS } from '@/store/modules/documents/action_types'
import { SET_DOCUMENTS_TO_MOVE, SET_MOVEMENT_SNACKBAR_IS_OPEN, SET_NUMBERING_SNACKBAR_IS_OPEN } from '@/store/modules/documents/mutation_types'
import { LOAD_PROJECTS_FOLDERS_TREE } from '@/store/modules/projects-folders/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

import DocumentsSidebarFolderItem from './DocumentsSidebarFolderItem.vue'
import { type DocumentsSidebarFolder } from './DocumentsSidebarFolderItemContent.vue'
import DocumentsSidebarSectionItem from './DocumentsSidebarSectionItem.vue'

type DroppableFolder = {
  id: number
}

type DataType = {
  RECENT_FOLDER: typeof RECENT_FOLDER,
  ROOT_FOLDER: typeof ROOT_FOLDER,
  enteredDroppableFolders: Array<DroppableFolder>
  selectedItem: string,
}

export default defineComponent({
  name: 'DocumentsSidebar',
  components: {
    AppDroppableArea,
    ProjectNavigationDrawerLayout,
    DocumentsSidebarFolderItem,
    DocumentsSidebarSectionItem,
  },
  data (): DataType {
    return {
      ROOT_FOLDER,
      RECENT_FOLDER,
      selectedItem: '',
      enteredDroppableFolders: [],
    }
  },
  computed: {
    ...mapState(['homeLayout']),
    ...mapState('global', ['mini']),
    ...mapState('documents', ['documents', 'foldersTree', 'getFoldersTreePending', 'documentsToMove', 'numberingEnabled', 'selectedDocuments']),
    ...mapState('projectsFolders', ['projectsFoldersTree', 'getProjectsFoldersTreePending', 'currentProjectsFolder']),
    ...mapGetters('room', ['currentUserRights', 'roomMnemo']),
    ...mapGetters('documents', ['isTodoFolderAccessible']),
    dropInCurrentFolderIsEnabled (): boolean {
      if (this.homeLayout || !this.documentsToMove.documents) {
      return false
      }
      return this.userHasUploadRight && !(this.dropInSelectedFolder && this.dropSelectedDocuments) && !this.dropToSameFolder && !this.dropParentInChildFolder && !this.dropInSameParentFolder
    },
    dropToSameFolder (): boolean {
      return this.documentsToMove.documents?.some(document =>
        document.id === this.focusedDroppableFolder?.id && document.type === 'folder',
      )
    },
    dropInSelectedFolder (): boolean {
      if (this.documentsToMove.isDragFromFoldersTree) {
        return false
      }
      return this.selectedDocuments.some(document =>
        document.id === this.focusedDroppableFolder?.id && document.type === 'folder',
      )
    },
    dropSelectedDocuments (): boolean {
      return this.documentsToMove.isDragElementSelected
    },
    dropInSameParentFolder (): boolean {
      return this.documentsToMove.originalFolderId === (this.focusedDroppableFolder?.id || ROOT_FOLDER.id)
    },
    dropParentInChildFolder (): boolean {
      if (!this.documentsToMove.documents) {
        return false
      }
      return this.findMultiFoldersChildrenInTree().some(child => child.id === this.focusedDroppableFolder?.id)
    },
    focusedDroppableFolder (): DroppableFolder | undefined {
      return last(this.enteredDroppableFolders)
    },
    isFolderTreeLoaded (): boolean {
      return !this.isPending && !!this.foldersTreeData
    },
    sortedFolders (): Array<DocumentsSidebarFolder> {
      if (this.homeLayout) {
        return [
          {
            id: RECENT_FOLDER.id,
            parentId: 'root',
            icon: 'clock',
            name: this.$t('projects.ProjectsHeader.recent').toString(),
          },
          {
            id: ROOT_FOLDER.id,
            parentId: 'root',
            icon: 'rectangle-history',
            name: this.$t('projects.ProjectsHeader.myProjects').toString(),
          },
        ].concat(this.projectsFoldersTree)
      }
      return [...this.foldersTree.folders].sort(this.foldersSortFn)
    },
    userHasUploadRight (): boolean {
      return this.currentUserRights.canUpload
    },
    recentFolderName (): TranslateResult | string {
      return this.homeLayout ? this.$t('projects.ProjectsHeader.recent') : RECENT_FOLDER.name
    },
    rootFolderName (): TranslateResult | string {
      return this.homeLayout ? this.$t('projects.ProjectsHeader.myProjects') : ROOT_FOLDER.name
    },
    homeSpecialFoldersIds (): Array<number | string> {
      return [RECENT_FOLDER.id, ROOT_FOLDER.id]
    },
    foldersTreeData (): Array<DocumentsSidebarFolder> {
      return this.homeLayout ? this.projectsFoldersTree : this.foldersTree
    },
    isPending (): boolean {
      return this.homeLayout ? this.getProjectsFoldersTreePending : this.getFoldersTreePending
    },
  },
  watch: {
    // If the current folder changes, update the selected item
    // For example, by clicking on the grid of folder or subfolder to open it
    currentProjectsFolder () {
      if (this.homeLayout) {
        this.updateSelectedItem()
      }
    },
    focusedDroppableFolder (folder) {
      if (folder && this.documentsToMove.documents) {
        let messageType = DRAG_AND_DROP_MESSAGE_TYPE.MOVABLE
        if (folder.specialFolder || folder.id === 'recentFolder') {
          messageType = DRAG_AND_DROP_MESSAGE_TYPE.ERROR_NOT_ALLOWED_FOLDER
        }
        if (this.dropInSelectedFolder && this.dropSelectedDocuments) {
          messageType = DRAG_AND_DROP_MESSAGE_TYPE.ERROR_DROP_IN_SELECTED_FOLDER
        }
        this.openUploadHint(folder.name, messageType)
        if (this.dropParentInChildFolder || this.dropInSameParentFolder || (this.dropToSameFolder && !(this.dropInSelectedFolder && this.dropSelectedDocuments))) {
        this.closeUploadHint()
        }
      } else {
        this.closeUploadHint()
      }
    },
  },
  async mounted () {
    if (this.homeLayout) {
      try {
        await this.LOAD_PROJECTS_FOLDERS_TREE()
        this.updateSelectedItem()
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('projects.ProjectsHeader.getProjectsFoldersTreeError'))
      }
    } else {
      await this.LOAD_FOLDERS_TREE()
    }
  },
  methods: {
    ...mapActions('projectsFolders', [LOAD_PROJECTS_FOLDERS_TREE]),
    ...mapActions('documents', [GO_TO_FOLDER, LOAD_FOLDERS_TREE, MOVE_FILDERS, DESELECT_ALL_DOCUMENTS]),
    ...mapMutations('documents', [SET_DOCUMENTS_TO_MOVE, SET_MOVEMENT_SNACKBAR_IS_OPEN, SET_NUMBERING_SNACKBAR_IS_OPEN]),
    ...mapActions('documentsBreadcrumb', ['setBreadcrumbItems']),
    ...mapMutations('appUploadHandler', [SET_UPLOAD_HINT]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    updateSelectedItem () {
      if (this.currentProjectsFolder.id) {
        this.selectedItem = `${this.currentProjectsFolder.id}${this.currentProjectsFolder.parentId}`
      } else if (this.$route.path === '/folder/recent') {
        this.selectedItem = `${RECENT_FOLDER.id}root`
      } else {
        this.selectedItem = `${ROOT_FOLDER.id}root`
      }
    },
    navigateToSpecialFolder (folderId: number | string) {
      if (this.homeLayout) {
        if (this.currentProjectsFolder.id && folderId === 0) {
          this.$router.push({ name: ROOMS_ROUTE_NAME })
        }
      } else {
        this.setBreadcrumbItems([ROOT_FOLDER])
        this.GO_TO_FOLDER(folderId)
      }
    },
    isSpecialFolderSelected (folderId: number | string): boolean {
      if (this.homeLayout) {
        return this.currentProjectsFolder.id === (folderId || undefined)
      }
      return this.documents?.id === folderId
    },
    foldersSortFn (folderA: DocumentsSidebarFolder, folderB: DocumentsSidebarFolder) {
      if (folderA.numbering || folderB.numbering) {
        return sortNumbering(
          folderA.numbering ?? 9999999999,
          folderB.numbering ?? 9999999999,
        )
      } else {
        return folderA.name.localeCompare(folderB.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      }
    },
    dragEnterFolder (folder) {
      this.enteredDroppableFolders.push(folder)
    },
    dragLeaveFolder (folder) {
      this.enteredDroppableFolders = this.enteredDroppableFolders.filter(
        droppableFolderEntered => droppableFolderEntered.id !== folder.id,
      )
    },
    dropDocumentsInFolder ($event, childFolder) {
      const documentFromClosd = $event.dataTransfer.getData('fromClosd') === 'true'
      if (documentFromClosd) {
        if (this.dropInCurrentFolderIsEnabled) {
          this.moveClosdDocument(childFolder)
        }
      }
    },
    dragClosdDocumentStart ($event, draggedFolder) {
      this.SET_MOVEMENT_SNACKBAR_IS_OPEN(false)
      const documentsToMoveArray = [{ id: draggedFolder.id, type: 'folder' }]
      const parentFolderId = this.findParentFolderId(draggedFolder)
      this.SET_DOCUMENTS_TO_MOVE({
          isDragElementSelected: this.selectedDocuments.some(doc => doc.id === draggedFolder.id && doc.type === 'folder'),
          isDragFromFoldersTree: true,
          documents: documentsToMoveArray,
          originalFolderId: parentFolderId || ROOT_FOLDER.id,
        })
      $event.dataTransfer.setData('fromClosd', 'true')
      $event.dataTransfer.setData('documentId', draggedFolder.id)
      $event.dataTransfer.setData('documentType', 'folder')
      $event.dataTransfer.setData('documentParentId', parentFolderId || ROOT_FOLDER.id)
    },
    async moveClosdDocument (targetFolder) {
      try {
        await this.MOVE_FILDERS({
          documents: this.documentsToMove.documents,
          targetFolderId: targetFolder.id,
        })
        this.SET_MOVEMENT_SNACKBAR_IS_OPEN(true)
      } catch {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.documents.table.DocumentsTableBody.moveError'))
        return
      }

      if (this.numberingEnabled) {
        this.SET_NUMBERING_SNACKBAR_IS_OPEN(true)
      }

      if (this.documentsToMove.documents.some(doc => doc.type === 'folder')) {
        await this.LOAD_FOLDERS_TREE()
      }
      if (this.documentsToMove.isDragElementSelected) {
        this.DESELECT_ALL_DOCUMENTS()
      }
    },
    openUploadHint (destinationFolder, messageType) {
      this.SET_UPLOAD_HINT({
        visible: true,
        destinationFolder: destinationFolder,
        messageType: messageType,
      })
    },
    closeUploadHint () {
      this.SET_UPLOAD_HINT({ visible: false })
    },
    findMultiFoldersChildrenInTree () {
      const foldersToMove = this.documentsToMove.documents.filter(doc => doc.type === 'folder')
      const flattenFolders = flatten([], this.foldersTree?.folders)
      const childrenOfFoldersToMove: Array<DroppableFolder> = []
      foldersToMove.forEach(folderToMove => {
        const matchingFolder = flattenFolders.find(flattenFolder => flattenFolder.id === folderToMove.id)
        if (matchingFolder && matchingFolder.children && matchingFolder.children.length > 0) {
          childrenOfFoldersToMove.push(...flatten([], matchingFolder.children))
        }
      })
      return childrenOfFoldersToMove
    },
    findParentFolderId (draggedFolder) {
      if (draggedFolder) {
        const flattenFolders = flatten([], this.foldersTree?.folders)
        for (const folder of flattenFolders) {
          if (folder.children && folder.children.some(child => child.id === draggedFolder.id)) {
               return folder.id
          }
        }
        return null
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.documents-sidebar {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__loader {
    ::v-deep .v-skeleton-loader__list-item {
      background: transparent;
      height: 40px;
    }
  }
}
</style>
