<template>
  <div>
    <v-data-table
      v-model="computedSelectedItems"
      class="checklist-global-attached-files-dialog-table"
      :items="items"
      :headers="headers"
      :footer-props="{
        'items-per-page-options': ITEM_PER_PAGE_DEFAULT,
        showCurrentPage: true,
      }"
      show-select
    >
      <template #item.basename="{item}">
        <div class="d-flex">
          <ClosdFildersIcon class="mr-2" :document="item" />
          <span> {{ item.basename }} </span>
          <v-chip
            v-if="item.version > 1"
            color="secondary"
            small
            class="caption mx-2"
            label
          >
            {{ `v.${item.version}` }}
          </v-chip>
        </div>
      </template>
      <template #item.parent="{item}">
        <span>{{ item.parent.name }}</span>
      </template>
      <template #item.rights="{item}">
        <div v-if="isCurrentUserPm" style="max-width: 120px">
          <RightsDisplayButton :rights="item.rights" @click="onShareButtonClick(item)" />
        </div>
      </template>
      <template #item.size="{item}">
        <app-filesize-display :value="item.size" />
      </template>
      <template #item.updatedBy="{item}">
        <span>{{ item.updateUser }}</span>
      </template>
      <template #item.updatedAt="{item}">
        <span>{{ fileDate(item.updateTime) }}</span>
      </template>
      <template #item.actions="{item}">
        <div class="d-flex align-center column-gap-2">
          <OfficeOnlineEditionButton v-if="isOfficeEditionButtonShowed(item)" :document="item"/>
          <AppDownloadFile :link="fileLink(item.id)">
            <template #default="{ submitForm }">
              <app-button type="icon"
                          @click="submitForm"
              >
                <app-icon icon-weight="fas" icon-name="download" />
              </app-button>
            </template>
          </AppDownloadFile>
          <ChecklistTableTaskAttachedFilesItemMenu :mnemo="mnemo" :file="item" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { cloneDeep } from 'lodash-es'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile.vue'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon.vue'
import OfficeOnlineEditionButton from '@/common/office/OfficeOnlineEditionButton.vue'
import RightsDisplayButton from '@/common/users/RightsDisplayButton.vue'
import { ISOToShortenedDate } from '@/common/utils/dates'
import { SET_TABLE_TASK_PANE } from '@/store/modules/checklist/mutation_types'
import { INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS } from '@/store/modules/document-share-picker/action_types'

import ChecklistTableTaskAttachedFilesItemMenu from '../task/ChecklistTableTaskAttachedFilesItemMenu.vue'

const ITEM_PER_PAGE_DEFAULT = [10, 25, 50]

export default defineComponent({
  components: {
    OfficeOnlineEditionButton,
    RightsDisplayButton,
    ChecklistTableTaskAttachedFilesItemMenu,
    AppDownloadFile,
    ClosdFildersIcon,
  },
    name: 'ChecklistGlobalAttachedFilesDialogTable',
    props: {
      items: {
        type: Array,
        required: true,
      },
      // Selected items model
      value: {
        type: Array,
        default: () => [],
      },
      mnemo: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        headers: [
          {
            value: 'basename',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.filename'),
          },
          {
            value: 'parent',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.taskParent'),
            sort (a, b) {
              return a?.name?.localeCompare(b?.name)
            },
          },
          {
            value: 'rights',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.rights'),
            sortable: false,
          },
          {
            value: 'size',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.size'),
          },
          {
            value: 'updatedBy',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.updatedBy'),
          },
          {
            value: 'updatedAt',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.updatedAt'),
          },
          {
            value: 'actions',
            text: this.$t('project.checklist.dialogs.ChecklistGlobalAttachedFilesDialog.ChecklistGlobalAttachedFilesDialogTable.headers.actions'),
            sortable: false,
          },
        ],
        ITEM_PER_PAGE_DEFAULT,
      }
    },
    computed: {
      ...mapGetters('room', ['isCurrentUserPm', 'isWopiEnabled']),
      computedSelectedItems: {
        get (): Array<unknown> {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    methods: {
      ...mapActions('documentSharePicker', [INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS]),
      ...mapMutations('checklist', [SET_TABLE_TASK_PANE]),
      onShareButtonClick (item) {
        this.INIT_SELECTED_GROUPS(cloneDeep(item.rights.read.groups))
        this.INIT_SELECTED_MEMBERS(cloneDeep(item.rights.read.users))
        this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
          isOpen: true,
          tab: 'share',
          documents: [item],
          parent: item.parent.id,
        })
      },
      fileDate (updateDate) {
        return ISOToShortenedDate(updateDate)
      },
      fileLink (fileId) {
        return `${axios.defaults.baseURL}/room/${this.mnemo}/downloads/file/${fileId}`
      },
      isOfficeEditionButtonShowed (document) {
        return this.isWopiEnabled && document.canEdit && this.isCurrentUserPm
      },
    },
  })
</script>
