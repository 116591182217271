<template>
    <v-row class="CerteuropeSignHeader" align="center">
      <v-col v-if="signingView">
        <div class="text-h3 white--text mb-1">{{ $t('certeurope-sign.CerteuropeSignHeader.reviewAndSignDocument') }}</div>
        <div class="white--text">
          {{ $tc('certeurope-sign.CerteuropeSignHeader.remainingTags', tagsCount) }}
          <v-chip color="white"
                  small
          >
            {{ tagsCount }}
          </v-chip>
        </div>
      </v-col>
      <v-col class="text-right">
        <template v-if="signingView">
          <AppButton v-if="tagsCount > 0"
                    color="white"
                    class="mr-2"
                    @click="goToNextTag"
          >
            {{ $t('certeurope-sign.CerteuropeSignHeader.continue') }}
          </AppButton>
          <AppButton v-if="tagsCount === 0"
                    color="white"
                     :disabled="signTagPending"
                    :loading="confirmSignaturePending"
                    class="CerteuropeSignHeader-finishButton mr-2"
                    @click="onFinishClick"
          >
            {{ $t('common.finish') }}
          </AppButton>
        </template>
        <AppButton color="white"
                   :to="{
                     name: SIGN_LANDING_PAGE_ROUTE_NAME,
                     params: { mnemo },
                     query: {
                       event: 'cancel',
                     },
                    }"
        >
        {{ $t('common.close') }}
      </AppButton>
      </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'

import AppButton from '../common/buttons/AppButton'
import { tagsTypes } from '../common/prepare-signature/tagsTypes'
import { SIGN_LANDING_PAGE_ROUTE_NAME } from '../router'
import { SET_VALIDATION_MODAL_IS_OPEN } from '../store/modules/certeurope-sign/mutation_types'
export default {
  name: 'CerteuropeSignHeader',
  components: { AppButton },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    selectedTag: {
      required: true,
    },
  },
  data () {
    return {
      SIGN_LANDING_PAGE_ROUTE_NAME,
    }
  },
  computed: {
    ...mapState('certeuropeSign', ['signingView', 'confirmSignaturePending', 'signTagPendingIds', 'unsignTagPendingIds']),
    tagsCount () {
      if (this.signingView && this.signingView.pages.length > 0) {
        let counter = 0
        this.signingView.pages.forEach(page => {
          counter += page.tags.filter(item => !item.isSigned &&
          item.type !== tagsTypes.DATE &&
          item.type !== tagsTypes.OPTIONAL_CHECKBOX &&
          item.type !== tagsTypes.TEXT_READ_ONLY).length
        })
        return counter
      }
      return null
    },
    flattenTags () {
      const array = []
      for (const page of this.signingView.pages) {
        array.push(...page.tags)
      }
      return array
    },
    tagsToSign () {
      return this.flattenTags.filter(tag => tag.isSigned === false)
    },
    signTagPending () {
      return this.signTagPendingIds.length > 0 || this.unsignTagPendingIds.length > 0
    },
  },
  mounted () {
    this.$emit('headerHeight', `${document.querySelector('.CerteuropeSignHeader').scrollHeight}px`)
  },
  methods: {
    onFinishClick () {
      if (this.signingView.signatureType === 'advanced') {
        this.$store.commit(`certeuropeSign/${SET_VALIDATION_MODAL_IS_OPEN}`, true)
      } else if (this.signingView.signatureType === 'simple') {
        this.$emit('confirmSimpleSignature')
      }
    },
    goToNextTag () {
      if (this.tagsToSign.length === 0) return
      if (!this.selectedTag) {
        this.$emit('update:selectedTag', this.tagsToSign[0])
      } else {
        const nextTag = this.tagsToSign.findIndex(t => t.id === this.selectedTag.id) + 1
        if (nextTag >= this.tagsToSign.length) {
          this.$emit('update:selectedTag', this.tagsToSign[0])
        } else {
          this.$emit('update:selectedTag', this.tagsToSign[nextTag])
        }
      }
      this.$nextTick(() => {
        const tag = document.querySelector(`#tag-${this.selectedTag.id}`)
        if (tag) {
          tag.scrollIntoView({
            behavior: 'smooth',
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.CerteuropeSignHeader {
  background-color: var(--v-primary-base);
  width: 100%;
  z-index: 1;
}
.CerteuropeSignHeader-finishButton {
  animation: blink 1s infinite;
  color: var(--v-primary-base);
}
@keyframes blink {
  0% {
    color: var(--v-primary-base);
  }
  50% {
    color: var(--v-accent-base);
  }
  100% {
    color: var(--v-primary-base);
  }
}
</style>
