<template>
  <AppDialog :is-open="isDialogOpened"
             size="xxxl"
             :ok-text="$t('common.confirm')"
             :ok-disabled="!canConfirmSwap"
             :ok-loading="uploadHiddenFilePendingIds.length > 0"
             @cancel="onCancelSwap"
             @ok="onConfirmSwap"
  >
    <template #title>
      {{ $t("project.signing-checklist.dialogs.SwapFilesDialog.title") }}
    </template>

    <template #body>
      <div class="d-flex flex-column row-gap-4 swap-files-wrapper">
        <SwapFilesSelector v-for="swapFile in swapFiles.steps"
                          :key="swapFile.id"
                          :mnemo="mnemo"
                          :swapFile="swapFile"
                          @reset-swap="resetSwap(swapFile.id)"
                          @add-swap-file="onAddSwapFile"
        />
      </div>
    </template>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import SwapFilesSelector from '@/project/signing-checklist/SwapFilesSelector.vue'
export default defineComponent({
  name: 'SwapFilesDialog',
  components: {
    AppDialog,
    SwapFilesSelector,
  },
  mixins: [dialogMixin],
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      swapFilesToProcess: [],
      nbFilesSwapped: 0,
    }
  },
  computed: {
    ...mapState('documents', ['uploadHiddenFilePendingIds']),
    ...mapState('signingChecklist', ['swapFiles']),
    canConfirmSwap () {
      return this.nbFilesSwapped !== 0
    },
  },
  methods: {
    onAddSwapFile (swapData) {
      const swapFileIndex = this.swapFilesToProcess.findIndex(swapFile => swapFile.envelopeId === swapData.envelopeId)

      if (swapFileIndex !== -1) {
        this.swapFilesToProcess.splice(swapFileIndex, 1, swapData)
        } else {
          this.swapFilesToProcess.push(swapData)
          this.nbFilesSwapped++
      }
    },
    onCancelSwap () {
      if (this.swapFilesToProcess.length > 0) {
        this.$emit('cancel')
      } else {
        this.resetSwap()
        this.closeDialog()
      }
    },
    async onConfirmSwap () {
      this.$emit('confirm', this.swapFilesToProcess)
      this.closeDialog()
    },
    resetSwap (swapFileId) {
      const swapFileIndex = this.swapFilesToProcess.findIndex(swapFile => swapFile.id === swapFileId)
      this.swapFilesToProcess.splice(swapFileIndex, 1)
      this.nbFilesSwapped--
    },
  },
})
</script>

<style>
.v-card__subtitle {
  display: none;
}

.swap-files-wrapper {
  min-height: 50vh;
}
</style>
