import * as Sentry from '@sentry/vue'
import axios from 'axios'

import { uploadStatus } from '@/common/app-file-upload/uploadStatus'
import { uploadTypes } from '@/common/app-file-upload/uploadTypes'
import { ROOM_TYPES } from '@/common/utils/roomTypes'
import { FileToUpload, FolderToUpload } from '@/models/documentToUpload.model'
import { GET_DOCUMENTS_BY_ID, LOAD_FOLDERS_TREE } from '@/store/modules/documents/action_types'
import { SET_NUMBERING_SNACKBAR_IS_OPEN } from '@/store/modules/documents/mutation_types'
import { GET_ROOM_STATS } from '@/store/modules/room/action_types'
import { SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN } from '@/store/modules/room/mutation_types'

import documentsService from '../../../services/documents.service'
import { GET_TASK_FOLDER } from '../checklist/action_types'

export const actions = {
  addFilesToUpload: async function ({ commit, dispatch }, documents) {
    for (const document of documents) {
      if (document instanceof FileToUpload) {
        commit('ADD_FILE_TO_UPLOAD', document)
        commit('ADD_TO_QUEUE', document)
      } else if (document instanceof FolderToUpload) {
        // Log if it's a folder for sentry's logs
        console.info('Trying to upload a folder')
        const folder = await dispatch('createFolder', document)
        commit('ADD_FILE_TO_UPLOAD', folder)
      }
    }
    dispatch('processQueue')
  },
  createFolder: async function ({ commit, dispatch }, folder) {
    return await documentsService.createNewFolder(
      folder.roomMnemo,
      {
        name: folder.name,
        parentId: folder.parentId,
      })
      .then(async response => {
        for (const document of folder.children) {
          document.parentId = response.data.folder.id
          if (document.type === 'folder') {
            await dispatch('createFolder', document)
          }
          if (document.type === 'file') {
            commit('ADD_TO_QUEUE', document)
          }
        }
        return folder
      })
  },
  processQueue: async function ({ state, commit, dispatch }) {
    const fileToUpload = state.uploadQueue.find(file => file.status === 'pending')
    if (fileToUpload && (state.uploadInProgress < state.simultaneousFileUpload)) {
      dispatch('incrementUploadInProgress')
      fileToUpload.status = uploadStatus.IN_PROGRESS
      dispatch('processQueue')
      try {
        await documentsService.postFile(fileToUpload)
        fileToUpload.status = uploadStatus.SUCCESS
      } catch (error) {
        /*
        * This error is triggered uniquely for pay per use data rooms
        */
        if (error?.response?.data?.errorCode === 'ERR_STORAGE_EXCEEDED') {
          dispatch('cancelAll')
          commit(`room/${SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN}`, true, { root: true })
        }
        if (axios.isCancel(error)) {
          fileToUpload.status = uploadStatus.CANCELED
          fileToUpload.progress = 0
        } else {
          fileToUpload.status = uploadStatus.ERROR
          if (error?.response?.data?.errorCode) {
            fileToUpload.error = error.response.data.errorCode
          }
        }

        if (fileToUpload.status !== uploadStatus.CANCELED) {
          if (fileToUpload.fileObject.webkitRelativePath !== '') {
            console.info('File path : ', fileToUpload.fileObject.webkitRelativePath)
          } else {
            console.info('File name : ', fileToUpload.fileObject.name)
          }
          Sentry.captureException(error)
        }
      } finally {
        dispatch('decrementUploadInProgress')
        dispatch('processQueue')
      }
    } else {
      if (state.uploadQueue.every(file => file.status === uploadStatus.SUCCESS || file.status === uploadStatus.CANCELED)) {
        setTimeout(() => { dispatch('uploadFinished') }, 3000)
      }
    }
  },
  incrementUploadInProgress: function ({ commit }) {
    commit('INCREMENT_UPLOAD_IN_PROGRESS')
  },
  decrementUploadInProgress: function ({ commit }) {
    commit('DECREMENT_UPLOAD_IN_PROGRESS')
  },
  redoFileUpload: ({ commit, dispatch }, fileKey) => {
    commit('SET_FILE_STATUS_TO_PENDING', fileKey)
    dispatch('processQueue')
  },
  cancelAllDocuments: ({ state }) => {
    state.uploadQueue.forEach(file => {
      if (file.status === uploadStatus.IN_PROGRESS) {
        file.cancelToken.cancel()
      } else if (file.status === uploadStatus.PENDING) { file.status = uploadStatus.CANCELED }
    })
  },
  cancelAll: ({ state, commit }) => {
    state.uploadQueue.forEach(file => {
      if (file.status === uploadStatus.IN_PROGRESS) {
        file.cancelToken.cancel()
      } else if (file.status === uploadStatus.PENDING) { file.status = uploadStatus.CANCELED }
    })
    commit('RESET')
  },
  cancelFile: ({ commit, state }, fileKey) => {
    const file = state.uploadQueue.find(file => file.key === fileKey)
    if (file.status === uploadStatus.IN_PROGRESS) {
      file.cancelToken.cancel()
      file.status = uploadStatus.CANCELED
    } else if (file.status === uploadStatus.PENDING) { file.status = uploadStatus.CANCELED }
  },
  redoAllErroredDocuments ({ commit, dispatch }) {
    commit('REDO_ALL_ERRORED_DOCUMENTS')
    dispatch('processQueue')
  },
  uploadFinished ({ commit, dispatch, state, rootState, rootGetters }) {
    if (state.uploadQueue.some(item => item.status === uploadStatus.SUCCESS && item.uploadType === uploadTypes.DOCUMENT)) {
      dispatch(`documents/${GET_DOCUMENTS_BY_ID}`, {
        mnemo: undefined,
        id: undefined,
        queryObject: {
          markRecent: true,
        },
      }, { root: true })
      dispatch(`documents/${LOAD_FOLDERS_TREE}`, null, { root: true })

      if (rootState.documents.numberingEnabled) {
        commit(`documents/${SET_NUMBERING_SNACKBAR_IS_OPEN}`, true, { root: true })
      }
    }
    if (state.uploadQueue.some(item => item.status === uploadStatus.SUCCESS && item.uploadType === uploadTypes.CHECKLIST)) {
      const checklistUploaded = state.uploadQueue.find(item => item.status === uploadStatus.SUCCESS && item.uploadType === uploadTypes.CHECKLIST)
      dispatch(`checklist/${GET_TASK_FOLDER}`, {
        mnemo: checklistUploaded.roomMnemo,
        folderId: checklistUploaded.parentId,
        queryObject: {
          markRecent: true,
        },
      }, { root: true })
    }

    if (state.uploadQueue.some(item => item.uploadType === uploadTypes.DOCUMENT && rootState.room.currentRoom.type === ROOM_TYPES.DATAROOM)) {
      dispatch(`room/${GET_ROOM_STATS}`, rootGetters['room/roomMnemo'], { root: true })
    }

    commit('RESET')
  },
}
