<template>
  <div>
    <AppDialog :is-open="isOpen"
               size="xl"
               @cancel="close"
    >
      <GroupImportForm
        v-if="dialogContent === GROUP_IMPORT_DIALOG_CONTENT.GROUP_IMPORT"
        ref="groupImportForm"
        :groupImportFormModel.sync="groupImportFormModel"
        :errorCode.sync="massInputError"
        :errorDetails="massInputErrorArray"
        :okLoading="postMassLoading"
        @onCancel="close"
        @onValidate="doImport"
      />
      <MemberProjectUpgrade
        v-if="dialogContent === GROUP_IMPORT_DIALOG_CONTENT.PROJECT_UPGRADE"
        :errorData="memberProjectUpgradeErrorData"
        :okLoading="postMassLoading"
        @onCancel="dialogContent = GROUP_IMPORT_DIALOG_CONTENT.GROUP_IMPORT"
        @onUpgradeDecline="close"
        @onUpgradeValidate="doImport"
      />
      <MemberSubscription
        v-if="dialogContent ===  GROUP_IMPORT_DIALOG_CONTENT.MEMBER_SUBSCRIPTION"
        :multi="true"
        :errorData="memberSubscriptionErrorData"
        :okLoading="postMassLoading"
        @onCancel="dialogContent = GROUP_IMPORT_DIALOG_CONTENT.GROUP_IMPORT"
        @onSubscriptionDecline="close"
        @onSubscriptionValidate="doImport"
      />
      <template v-if="dialogContent === GROUP_IMPORT_DIALOG_CONTENT.IRRECOVERABLE_ERROR">
        <v-card>
          <v-card-text class="pt-6 pb-0">
            <v-row>
              <v-col>
                <div>
                  {{ $t('project.members.dialogs.GroupImportDialog.error.ERR_FORBIDDEN_INTRO', { subName }) }}
                </div>
                <div v-if="errorCode === 'ERR_FORBIDDEN_FOR_NON_SUB_USER'" class="mt-2">
                  {{ $t('project.members.dialogs.GroupImportDialog.error.ERR_FORBIDDEN_FOR_NON_SUB_USER', { subName }) }}
                </div>
                <div v-if="errorCode === 'ERR_FORBIDDEN_FOR_NON_SUB_ADMIN'" class="mt-2">
                  {{ $t('project.members.dialogs.GroupImportDialog.error.ERR_FORBIDDEN_FOR_NON_SUB_ADMIN', { subName }) }}
                </div>
                <div v-if="errorCode === 'ERR_FORBIDDEN_FOR_NON_SUB_ADMIN'" class="mt-2">
                  <template v-for="admin in subAdminContacts"><div :key="admin.email">- {{ admin.fullName}} ({{ admin.email }})</div></template>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <AppButton @click="close">
              {{$t('common.cancel')}}
            </AppButton>
          </v-card-actions>
        </v-card>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton.vue'

import AppDialog, { MODAL_FADE_TRANSITION_TIME } from '../../../common/dialogs/AppDialog'
import GroupImportForm from '../GroupImportForm.vue'
import MemberProjectUpgrade from '../MemberProjectUpgrade.vue'
import MemberSubscription from '../MemberSubscription.vue'

 const GROUP_IMPORT_DIALOG_CONTENT = {
  GROUP_IMPORT: 'group-import',
  PROJECT_UPGRADE: 'project-upgrade',
  MEMBER_SUBSCRIPTION: 'member-subscription',
  IRRECOVERABLE_ERROR: 'irrecoverable-error',
}

const getEmptyGroupImportFormModel = (isDataRoom) => ({
  selectedFile: null,
  customMessage: '',
  customMessageIsActive: false,
  sendInvitationNow: true,
  hidePm: false,
  idVerification: !isDataRoom,
})

export default {
  name: 'GroupImportDialog',
  components: {
    AppButton,
    AppDialog,
    MemberProjectUpgrade,
    MemberSubscription,
    GroupImportForm,
},
  props: {
    mnemo: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      massInputError: null,
      dialogContent: GROUP_IMPORT_DIALOG_CONTENT.GROUP_IMPORT,
      groupImportFormModel: getEmptyGroupImportFormModel(this.isDataRoom),
      massInputErrorArray: [],
      memberProjectUpgradeErrorData: null,
      memberSubscriptionErrorData: null,
      errorCode: null,
      subAdminContacts: null,
      subName: null,
      visible: true,
      GROUP_IMPORT_DIALOG_CONTENT,
    }
  },
  computed: {
    ...mapState('groups', ['postMassLoading']),
    ...mapGetters('room', ['isDataRoom']),
    isOpen: {
      get () { return this.visible },
      set (val) {
        if (!val) {
          setTimeout(() => {
            this.$emit('onClose')
          }, MODAL_FADE_TRANSITION_TIME)
        }
        this.visible = val
      },
    },
  },
  methods: {
    ...mapActions('groups', ['POST_MASS_IMPORT', 'GET_GROUPS']),
    close () {
      this.isOpen = false
    },
    doImport (params) {
      const formData = new FormData()
      formData.append('file', this.groupImportFormModel.selectedFile, this.groupImportFormModel.selectedFile.name)
      if (this.groupImportFormModel.customMessageIsActive) {
        formData.append('customMessage', this.groupImportFormModel.customMessage)
      }
      formData.append('sendInvitationNow', this.groupImportFormModel.sendInvitationNow)
      formData.append('idVerification', this.groupImportFormModel.idVerification)
      formData.append('hidePm', this.groupImportFormModel.hidePm)
      for (const key in params) {
        formData.append(key, params[key])
      }
      this.POST_MASS_IMPORT(formData)
        .then(() => {
          if (params?.confirmYearly) {
            this.$store.commit('enqueueSnackbar', {
              color: 'success',
              message: this.$t('project.members.dialogs.GroupImportDialog.messages.successAddedAndToSubMulti'),
            })
          } else {
            this.$store.commit('enqueueSnackbar', {
              color: 'success',
              message: this.$t('project.members.dialogs.GroupImportDialog.messages.successAddedMulti'),
            })
          }
          if (this.mnemo) {
            this.GET_GROUPS(this.mnemo)
          }
          this.close()
        })
        .catch(error => {
          if (error.response?.status === 300) {
            if (error.response?.data?.type === 'upgrade') {
              this.memberProjectUpgradeErrorData = error.response.data
              this.dialogContent = GROUP_IMPORT_DIALOG_CONTENT.PROJECT_UPGRADE
            } else if (error.response?.data) {
              this.memberSubscriptionErrorData = error.response.data
              this.dialogContent = GROUP_IMPORT_DIALOG_CONTENT.MEMBER_SUBSCRIPTION
            }
          }
          if (error.response?.data) {
            const { data: errorData, errorCode, errorContext } = error.response.data
            this.errorCode = errorCode

            switch (errorCode) {
              case 'ERR_USER_ALREADY_IN_ROOM':
              case 'ERR_INVALID_INPUT': {
                this.$store.commit('enqueueSnackbar', {
                  color: 'error',
                  message: this.$t(`project.members.dialogs.GroupImportDialog.error.${errorCode}`),
                })
                this.close()

                break
              }
              case 'ERR_FORBIDDEN_FOR_NON_SUB_USER': {
                this.dialogContent = GROUP_IMPORT_DIALOG_CONTENT.IRRECOVERABLE_ERROR
                this.subName = errorContext?.subName

                break
              }
              case 'ERR_FORBIDDEN_FOR_NON_SUB_ADMIN': {
                this.dialogContent = GROUP_IMPORT_DIALOG_CONTENT.IRRECOVERABLE_ERROR
                this.subName = errorContext?.subName
                this.subAdminContacts = errorContext?.subAdmins

                break
              }
              case 'ERR_FILE_NOT_ALLOWED':
              case 'ERR_FILE_NOT_PROCESSED': {
                this.massInputError = errorCode

                break
              }
              case 'ERR_INCORRECT_DATA':
              case 'ERR_DUPLICATE_EMAIL': {
                this.massInputError = errorCode
                this.massInputErrorArray = errorData
                this.$refs.groupImportForm.resetFileInput()

                break
              }
            }
          } else {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.dialogs.GroupImportDialog.messages.errorGeneric'),
            })
          }
        })
    },
  },
}
</script>
