import {
  SET_USER_CAN_CREATE_ROOMS,
  SET_USER_PROFILE,
  SET_UPDATE_PROFILE_PENDING,
  SET_UPDATE_PROFILE_ERROR,
  SET_UPDATE_LANGUAGE_PENDING,
  SET_UPDATE_LANGUAGE_ERROR,
  SET_UPDATE_NOTIFICATION_PREFERENCES_PENDING,
  SET_UPDATE_NOTIFICATION_PREFERENCES_ERROR,
  SET_CURRENT_NOTIFICATION_PREFERENCES_PENDING,
  SET_CURRENT_NOTIFICATION_PREFERENCES,
  SET_CURRENT_NOTIFICATION_PREFERENCES_ERROR,
  SET_CREATE_NOTIFICATION_PREFERENCES_PENDING,
  SET_CREATE_NOTIFICATION_PREFERENCES_ERROR,
  SET_UPDATE_PASSWORD_PENDING,
  SET_UPDATE_PASSWORD_ERROR,
  SET_DELETE_NOTIFICATION_PREFERENCES_PENDING,
  SET_DELETE_NOTIFICATION_PREFERENCES_ERROR,
  SET_GET_SELF_USER_PENDING,
  SET_SELF_CONTACT_LIST,
  SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION,
  SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION_PAYING_USERS,
  SET_SELF_CONTACT_LIST_PENDING,
  SET_ID_CHECK_PENDING,
  SET_ID_CHECK,
  SET_ID_CHECK_ERROR,
  SET_SHOW_IDENTITY_CHECK_MODAL,
  SET_ID_CHECK_STATE_PENDING,
  SET_ID_CHECK_STATE,
  SET_ID_CHECK_STATE_ERROR,
  SET_SELECTED_ID_DOCUMENT_TYPE,
  SET_SHOW_IDENTITY_CHECK_LANDING_MODAL,
  SET_ACTIVITY_POLLING_PENDING,
  SET_ACTIVITY_POLLING_ERROR,
  SET_GET_ZENDESK_AUTH_PENDING,
} from './mutation_types'

export const mutations = {
  [SET_SHOW_IDENTITY_CHECK_LANDING_MODAL] (state, value) {
    state.showIdentityCheckLandingModal = value
  },
  [SET_SHOW_IDENTITY_CHECK_MODAL] (state, value) {
    state.showIdentityCheckModal = value
  },
  [SET_GET_SELF_USER_PENDING] (state, value) {
    state.getSelfUserPending = value
  },
  [SET_USER_PROFILE]: function (state, profile) {
    state.profile = profile
  },
  [SET_USER_CAN_CREATE_ROOMS]: function (state, userCanCreateRooms) {
    state.userCanCreateRooms = userCanCreateRooms
  },
  [SET_UPDATE_PROFILE_PENDING] (state, value) {
    state.updateProfilePending = value
  },
  [SET_UPDATE_PROFILE_ERROR] (state, value) {
    state.updateProfileError = value
  },
  [SET_UPDATE_LANGUAGE_PENDING] (state, value) {
    state.updateLanguagePending = value
  },
  [SET_UPDATE_LANGUAGE_ERROR] (state, value) {
    state.updateLanguageError = value
  },
  [SET_UPDATE_NOTIFICATION_PREFERENCES_PENDING] (state, value) {
    state.updateNotificationPreferencesPending = value
  },
  [SET_UPDATE_NOTIFICATION_PREFERENCES_ERROR] (state, value) {
    state.updateNotificationPreferencesError = value
  },
  [SET_CURRENT_NOTIFICATION_PREFERENCES_PENDING] (state, value) {
    state.currentNotificationPreferencesPending = value
  },
  [SET_CURRENT_NOTIFICATION_PREFERENCES] (state, value) {
    state.currentNotificationPreferences = value
  },
  [SET_CURRENT_NOTIFICATION_PREFERENCES_ERROR] (state, value) {
    state.currentNotificationPreferencesError = value
  },
  [SET_CREATE_NOTIFICATION_PREFERENCES_PENDING] (state, value) {
    state.createNotificationPreferencesPending = value
  },
  [SET_CREATE_NOTIFICATION_PREFERENCES_ERROR] (state, value) {
    state.createNotificationPreferencesError = value
  },
  [SET_UPDATE_PASSWORD_PENDING] (state, value) {
    state.updatePasswordPending = value
  },
  [SET_UPDATE_PASSWORD_ERROR] (state, value) {
    state.updatePasswordError = value
  },
  [SET_DELETE_NOTIFICATION_PREFERENCES_PENDING] (state, value) {
    state.deleteNotificationPreferencesPending = value
  },
  [SET_DELETE_NOTIFICATION_PREFERENCES_ERROR] (state, value) {
    state.deleteNotificationPreferencesError = value
  },
  [SET_SELF_CONTACT_LIST_PENDING]: function (state, value) {
    state.getSelfContactListPending = value
  },
  [SET_SELF_CONTACT_LIST]: function (state, value) {
    state.selfContactList = value
  },
  [SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION]: function (state, value) {
    state.selfContactListFromSubscription = value
  },
  [SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION_PAYING_USERS]: function (state, value) {
    state.selfContactListFromSubscriptionPayingUsers = value
  },
  [SET_ID_CHECK_PENDING] (state, value) {
    state.idCheckPending = value
  },

  [SET_ID_CHECK] (state, value) {
    state.idCheck = value
  },
  [SET_ID_CHECK_ERROR] (state, value) {
    state.idCheckError = value
  },
  [SET_ID_CHECK_PENDING] (state, value) {
    state.idCheckPending = value
  },

  [SET_ID_CHECK] (state, value) {
    state.idCheck = value
  },
  [SET_ID_CHECK_ERROR] (state, value) {
    state.idCheckError = value
  },
  [SET_ID_CHECK_STATE_PENDING] (state, value) {
    state.idCheckStatePending = value
  },

  [SET_ID_CHECK_STATE] (state, value) {
    state.idCheckState = value
  },
  [SET_ID_CHECK_STATE_ERROR] (state, value) {
    state.idCheckStateError = value
  },
  [SET_SELECTED_ID_DOCUMENT_TYPE] (state, value) {
    state.selectedIdDocumentType = value
  },
  [SET_ACTIVITY_POLLING_PENDING] (state, value) {
    state.activityPollingPending = value
  },
  [SET_ACTIVITY_POLLING_ERROR] (state, value) {
    state.activityPollingError = value
  },
  [SET_GET_ZENDESK_AUTH_PENDING] (state, value) {
    state.zendeskAuthPending = value
  },
}
