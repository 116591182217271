<template>
  <div>
    <template v-if="isRefreshing">
      <v-row>
        <v-col class="text-center">
          <AppLoader/>
        </v-col>
      </v-row>
    </template>
    <template v-if="!isRefreshing && currentTaskFolder">
      <v-row v-if="selectedAttachedFiles.length > 0">
        <v-col>
          <ChecklistTableTaskAttachedFilesDialogMultiSelectBar :mnemo="mnemo"/>
        </v-col>
      </v-row>
      <v-row class="ChecklistTableTaskAttachedFilesDialogFileTree-row py-1" align="center" no-gutters>
        <v-col cols="auto">
          <v-checkbox
                      :input-value="folderCheckboxSelected"
                      hide-details
                      readonly
                      :ripple="false"
                      class="mt-0 pt-0"
                      @click.stop="onFolderCheckboxClick"
          />
        </v-col>
        <v-col cols="auto">
          <v-img src="/img/icons/folder-open.svg"
                 width="25"
          />
        </v-col>
        <v-col class="ChecklistTableTaskAttachedFilesDialogFileTree-folderName ml-2"
               cols="auto"
        >
          {{ currentTaskFolder.name || '' }}
        </v-col>
        <v-col cols="auto" class="ml-12">
          <RightsDisplayButton v-if="isCurrentUserPm && currentTaskFolder.rights"
                               :rights="currentTaskFolder.rights"
                               @click="prepareSharePane(currentTaskFolder)"
          />
        </v-col>
      </v-row>
      <v-row v-for="child in currentTaskFolder.children"
             :key="`folderChild-${child.id}`"
             class="ChecklistTableTaskAttachedFilesDialogFileTree-row py-3 pl-8 text--primary"
             align="center"
             no-gutters
      >
        <v-col cols="auto">
          <v-checkbox
                      :input-value="selectedAttachedFiles.some(item => item.id === child.id)"
                      hide-details
                      readonly
                      :ripple="false"
                      class="mt-0 pt-0"
                      @click.stop="onFileCheckboxClick(child)"
          />
        </v-col>
        <v-col cols="auto">
          <ClosdFildersIcon :document="child"/>
        </v-col>
        <v-col class="text--primary d-flex align-center"
               cols="3"
        >
          <AppTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="DocumentsTableTemplate-fileName"
                   v-bind="attrs"
                   v-on="on"
              >
                <AppDownloadFile :link="fileLink(child.id)">
                  <template #default="{ submitForm }">
                    <template v-if="child.numbering">
                      <span class="mr-1">{{`${child.numbering}`}}</span>
                    </template>
                    <span class="DocumentsTableTemplate-fileName clickable" @click="submitForm">
                      {{child.basename || child.name}}
                    </span>
                  </template>
                </AppDownloadFile>
              </div>
            </template>
            <div>{{child.basename || child.name || ''}}</div>
          </AppTooltip>
          <v-chip v-if="child.version > 1"
                  color="secondary"
                  small
                  class="caption mx-2"
                  label
          >
            {{ `v.${child.version}` }}
          </v-chip>
        </v-col>
        <v-col cols="1">
          <RightsDisplayButton v-if="isCurrentUserPm && child.rights"
                               :rights="child.rights"
                               @click="prepareSharePane(child)"
          />
        </v-col>
        <v-col cols="1">
          <app-filesize-display :value="child.size"/>
        </v-col>
        <v-col cols="2">
          {{ child.updateUser }}
        </v-col>
        <v-col cols="2">
          {{ fileDate(child.updateTime) }}
        </v-col>
        <v-col cols="2" class="text-right d-flex justify-end column-gap-2">
          <OfficeOnlineEditionButton v-if="isOfficeEditionButtonShowed(child)" :document="child" />
          <AppDownloadFile :link="fileLink(child.id)">
            <template #default="{ submitForm }">
              <app-button type="icon" @click="submitForm">
                <app-icon icon-name="download" icon-weight="fas" />
              </app-button>
            </template>
          </AppDownloadFile>
          <ChecklistTableTaskAttachedFilesItemMenu :mnemo="mnemo"
                                                   :file="child"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import AppLoader from '@/common/AppLoader'
import AppTooltip from '@/common/AppTooltip'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon'
import OfficeOnlineEditionButton from '@/common/office/OfficeOnlineEditionButton.vue'
import RightsDisplayButton from '@/common/users/RightsDisplayButton.vue'
import { ISOToShortenedDate } from '@/common/utils/dates'
import {
  ADD_SELECTED_ATTACHED_FILE,
  REMOVE_SELECTED_ATTACHED_FILE,
  SET_TABLE_TASK_PANE,
  SET_SELECTED_ATTACHED_FILE,
} from '@/store/modules/checklist/mutation_types'
import { INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS } from '@/store/modules/document-share-picker/action_types'

import ChecklistTableTaskAttachedFilesDialogMultiSelectBar from './ChecklistTableTaskAttachedFilesDialogMultiSelectBar'
import ChecklistTableTaskAttachedFilesItemMenu from '../task/ChecklistTableTaskAttachedFilesItemMenu'

export default {
  name: 'ChecklistTableTaskAttachedFilesDialogFileTree',
  components: {
    OfficeOnlineEditionButton,
    ClosdFildersIcon,
    ChecklistTableTaskAttachedFilesDialogMultiSelectBar,
    AppLoader,
    ChecklistTableTaskAttachedFilesItemMenu,
    AppDownloadFile,
    AppTooltip,
    RightsDisplayButton,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    folderId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('checklist', ['taskFolderPendingIds', 'currentTaskFolder', 'selectedAttachedFiles']),
    ...mapGetters('room', ['isCurrentUserPm', 'isWopiEnabled']),
    isRefreshing () {
      return this.taskFolderPendingIds.includes(this.folderId.toString())
    },
    folderCheckboxSelected () {
      return this.currentTaskFolder.children.length > 0 && this.selectedAttachedFiles.length === this.currentTaskFolder.children.length
    },
  },
  methods: {
    ...mapActions('documentSharePicker', [INIT_SELECTED_GROUPS, INIT_SELECTED_MEMBERS]),
    isOfficeEditionButtonShowed (document) {
      return this.isWopiEnabled && document.canEdit && this.isCurrentUserPm
    },
    fileDate (date) {
      return ISOToShortenedDate(date)
    },
    fileLink (fileId) {
      return `${this.axios.defaults.baseURL}/room/${this.mnemo}/downloads/file/${fileId}`
    },
    prepareSharePane (item) {
      this.INIT_SELECTED_GROUPS(cloneDeep(item.rights.read.groups))
      this.INIT_SELECTED_MEMBERS(cloneDeep(item.rights.read.users))
      this.$store.commit(`checklist/${SET_TABLE_TASK_PANE}`, {
        isOpen: true,
        tab: 'share',
        documents: [item],
        parent: this.currentTaskFolder,
      })
    },
    onFolderCheckboxClick () {
      if (this.folderCheckboxSelected) {
        this.currentTaskFolder.children.forEach(item => {
          this.$store.commit(`checklist/${REMOVE_SELECTED_ATTACHED_FILE}`, item)
        })
      } else {
        this.currentTaskFolder.children.forEach(item => {
          this.$store.commit(`checklist/${ADD_SELECTED_ATTACHED_FILE}`, item)
        })
      }
    },
    onFileCheckboxClick (item) {
      this.$store.commit(`checklist/${SET_SELECTED_ATTACHED_FILE}`, item)
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistTableTaskAttachedFilesDialogFileTree-row {
  border-bottom: 1px solid #e0e0e0;
}
.ChecklistTableTaskAttachedFilesDialogFileTree-folderName {
  color: #000;
}
.DocumentsTableTemplate-fileName {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    max-width: 100px;
  }
}

.ChecklistTableTaskAttachedFilesDialogFileTree-iconContainer {
  width: 36px
}
</style>
