'use strict'

import axios from 'axios'
import Vue from 'vue'

import { USER_TOKEN_STORAGE_KEY } from '@/common/utils/users'
import { SET_MAINTENANCE_MODE_ENABLED, SET_RETRY_AT } from '@/store/modules/maintenance/mutation_types'

import i18n from '../i18n'
import router, { LOGOUT_ROUTE_NAME, TWO_FACTOR_ROUTE_NAME, NOT_FOUND_ROUTE_NAME } from '../router'
import store from '../store/'
import { ENQUEUE_SNACKBAR } from '../store/mutation_types'

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = process.env.VUE_APP_API_URL || process.env.baseURL || process.env.apiUrl || ''
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// Back-end needs to distinguish between ajax and non-ajax calls because of V1 front-end
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axios.defaults.withCredentials = true

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(USER_TOKEN_STORAGE_KEY)
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    }
    // Do something before request is sent
    if (store.state.maintenance.maintenanceModeEnabled) {
      // Only status is allowed here because it's the call to check the maintenance status
      if (config.url !== 'status') {
        return {
          ...config,
          signal: AbortSignal.abort(),
        }
      }
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    const renewedToken = response.headers['x-closd-token-renewed']
    if (renewedToken) {
      localStorage.setItem(USER_TOKEN_STORAGE_KEY, renewedToken)
      axios.defaults.headers.common.Authorization = `Bearer ${renewedToken}`
    }
    // Do something with response data
    return response
  },
  function (error) {
    if (error.request) {
      if ((error.request.status === 401) && (error.response?.data?.error === 'Two factor auth required')) {
        router.push({ name: TWO_FACTOR_ROUTE_NAME, params: { returnRoute: router.currentRoute, locale: i18n.locale } })
      } else if (error.request.status === 401 && localStorage.getItem(USER_TOKEN_STORAGE_KEY)) {
        const otherSessionStarted = !!(error?.response.data?.errorCode === 'ERR_OTHER_SESSION_STARTED')
        if (router.currentRoute.name !== LOGOUT_ROUTE_NAME) {
          router.push({
            name: LOGOUT_ROUTE_NAME,
            params: {
              redirectProps: {
                returnRoute: router.currentRoute,
                isLogOut: !otherSessionStarted,
                otherSessionStarted,
              },
            },
          })
        }
      }
      if (error.request.status === 429) {
        store.commit(ENQUEUE_SNACKBAR, {
          message: i18n.t('common.rateLimited'),
          color: 'error',
        })
      }
      // If we're getting the wrong user connected, the not found page handles it. Otherwise, 403s are handled locally
      if ((error.request.status === 403) &&
        (
          error.response?.data?.errorCode === 'ERR_WRONG_USER_LOGGED_IN' ||
          error.response?.data?.errorCode === 'ERR_WRONG_SIGNER'
        )) {
        router.push({ name: NOT_FOUND_ROUTE_NAME, props: { customMessage: 'ERR_WRONG_USER_LOGGED_IN' } })
      }
      // Interceptor for maintenance mode
      if (error.request.status === 503) {
        store.commit(`maintenance/${SET_MAINTENANCE_MODE_ENABLED}`, true)
        if (error.response.headers['x-retry-at']) {
          store.commit(`maintenance/${SET_RETRY_AT}`, error.response.headers['x-retry-at'])
        }
      }
    }
    return Promise.reject(error)
  },
)

Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      },
    },
    $axios: {
      get () {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
